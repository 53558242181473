import React, { useEffect, useState } from 'react';
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import {
  Dimmer,
  Divider,
  Image,
  Label,
  Loader,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import {
  IMAGES_URL,
  currencyDecimalPlaces,
  fulFillPurchaseOrder,
  humanize,
  updatePurchaseOrderStatus,
} from '../../../config/functions';
import UserProfilePlaceholder from '../../../components/common/userProfilePlaceholder';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  CloseIcon,
  ContactInfoIcon,
  DownloadPdfIcon,
  HomeIcon,
  ItemsIcon,
  SendVendorIcon,
} from '../../../../Assets/Icons/svg';
import Moment from 'react-moment';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { toast } from 'react-toastify';
import FullfillForm from './fulfillForm';
import { cloneDeep, countBy } from 'lodash';
import useNewCancelModal from '../../../../hooks/Messages/useNewCncelModal';
import { Spinner } from 'reactstrap';
const Details = ({
  targetedData,
  createdByUserData,
  attachmentData,
  setRefresh,
  refresh,
  untoggle,
  setMessage,
  message,
  setSearchTitle,
  setFilterOptions,
  setSelectedDates,
  setDueDates,
}) => {
  const t = useSelector(selectTranslations);
  const history = useHistory();

  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [editSetData, setEditData] = useState();

  const [openModal, setModal] = useState(false);
  const [openFuifillModal, setOpenFuifillModal] = useState(false);
  const [declineFlag, setDeclineFlag] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [buttonStatusApprove, setButtonStatusApprove] = useState(0);
  const [openVendorEmailModal, setOpenVendorEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);

  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();

  const statusOptions = [
    {
      value: 'requested',
      color: 'yellow',
      label: t.po.status.requested,
    },
    {
      value: 'approved',
      color: 'green',
      label: t.po.status.approved,
    },
    {
      value: 'declined',
      color: 'red',
      label: t.po.status.declined,
    },
    {
      value: 'fulfilled',
      color: 'black',
      label: t.po.status.fulfilled,
    },
    {
      value: 'partiallyfulfilled',
      color: 'blue',
      label: t.po.status.partially_fulfilled,
    },
    {
      value: 'cancelled',
      color: 'darkgrey',
      label: t.po.status.cancelled,
    },
  ];

/**
 * The `confirmPurchaseOrder` function displays different approval buttons based on the status of the targeted data.
 */
  const confirmPurchaseOrder = () => {
    if (
      targetedData?.attributes?.status == 'requested'
      //  &&
      // userPermissions['purchase-order']?.assign
    ) {
      return (
        <>
          <div className=" d-flex justify-content-between approve-container align-items-center">
            <div></div>
            <div className="approval-button-container ">
              <button
                className="modal-form-action-button decline "
                onClick={() => {
                  setModal(true);
                }}
              >
                {t.workOrders.details_view.decline}
              </button>

              <button
                onClick={() => {
                  setOpenApproveModal(true);
                  // setToggleAsideModal(true);
                }}
                className="modal-form-action-button blue"
              >
                {t.workOrders.details_view.approve}
              </button>
            </div>
          </div>
        </>
      );
    } else if (targetedData?.attributes?.status === 'approved') {
      return (
        <div className=" d-flex justify-content-between approve-container align-items-center">
          <div></div>
          <div className="approval-button-container ">
            <button
              className="modal-form-action-button decline "
              onClick={() => {
                setModal(true);
                setDeclineFlag(true);
              }}
            >
              {t.workOrders.details_view.decline}
            </button>

            <button
              onClick={() => {
                setOpenFuifillModal(true);
                // setToggleAsideModal(true);
              }}
              className="modal-form-action-button blue"
            >
              {t.po.form.fulfill}
            </button>
          </div>
        </div>
      );
    } else if (targetedData?.attributes?.status === 'partiallyfulfilled') {
      return (
        <div className=" d-flex justify-content-between approve-container align-items-center">
          <div></div>
          <div className="approval-button-container ">
            <button
              className="modal-form-action-button decline "
              onClick={() => {
                setNewCancelModal(true);
                // setDeclineFlag(true);
              }}
            >
              {t.common.close}
            </button>

            <button
              onClick={() => {
                setOpenFuifillModal(true);
                // setToggleAsideModal(true);
              }}
              className="modal-form-action-button blue"
            >
              {t.po.form.fulfill}
            </button>
          </div>
        </div>
      );
    } else if (targetedData?.attributes?.status === 'declined') {
      return (
        <div className="comment decline-container decline-border d-flex justify-content-between approve-container align-items-center">
          <div className="">
            <span className="user-img">
              {t.po.form.decline_purchase_order}{' '}
              <div className="">
                <label className="">
                  {t.common.reason}
                  {':'}
                </label>
                <span className="ml-2 mr-2">
                  {targetedData?.attributes?.declinedReason}
                </span>
              </div>
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (openFuifillModal) {
      setLoader(true);
      let formattedParts;

      if (targetedData?.attributes?.orderItems?.items.length > 0) {
        formattedParts = targetedData?.attributes?.orderItems?.items?.map(
          (part) => {
            console.log('part: ss', part);
            // Determine if the part is selected by checking if its id exists in selectedPartsData

            // Constructing the formatted part object
            return {
              id: part?.id,
              fillToMax: part?.fillMax,
              part: part.part.data.id,
              countId: part.part.data.attributes.countId,
              name: part.part.data.attributes.name,
              totalCost:
                targetedData?.attributes?.status == 'partiallyfulfilled'
                  ? part.totalCost
                  : 0,
              quantity: part.qtyToOrder,
              unitType: part.part.data.attributes.measuringUnit,
              unitCost:
                targetedData?.attributes?.status == 'partiallyfulfilled'
                  ? part.unitCost
                  : part.unitCost,
              receivedQty: part?.receivedQty ? part?.receivedQty : 0,
            };
          }
        );
      } else {
        formattedParts = targetedData?.attributes?.orderItems?.items?.map(
          (part) => {
            console.log('part: ss', part);
            // Determine if the part is selected by checking if its id exists in selectedPartsData

            // Constructing the formatted part object
            return {
              part: part.part.data.id,
              name: part.part.data.attributes.name,
              totalCost: 0,
              quantity: part.qtyToOrder,
              unitType: part.part.data.attributes.measuringUnit,
              unitCost: 0,
              receivedQty: 0,
            };
          }
        );
      }

      const orderItemsData = {
        // id: targetedData?.attributes?.orderItems?.id,
        items: formattedParts,
        taxes:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.taxes
            : targetedData?.attributes?.orderItems?.taxes,
        taxAmt:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.taxAmt
            : targetedData?.attributes?.orderItems?.taxAmt,
        shipAmt:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.shipAmt
            : targetedData?.attributes?.orderItems?.shipAmt,
        otherAmt:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.otherAmt
            : targetedData?.attributes?.orderItems?.otherAmt,
        shippingCost:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.shippingCost
            : targetedData?.attributes?.orderItems?.shippingCost,
        otherCost:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.otherCost
            : targetedData?.attributes?.orderItems?.otherCost,
        subTotal:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.subTotal
            : 0,
        totalCost:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.totalCost
            : 0,
        shippingCostInPercentage:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.shippingCostInPercentage
            : targetedData?.attributes?.orderItems?.shippingCostInPercentage,
        taxesInPercentage:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.taxesInPercentage
            : targetedData?.attributes?.orderItems?.taxesInPercentage,
        otherCostInPercentage:
          targetedData?.attributes?.status == 'partiallyfulfilled'
            ? targetedData?.attributes?.orderItems?.otherCostInPercentage
            : targetedData?.attributes?.orderItems?.otherCostInPercentage,
      };

      setEditData({ orderItems: orderItemsData });

      setLoader(false);
    }
  }, [openFuifillModal]);

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const [fetching, setFetchLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

/*
 * The function `handleSave` is an asynchronous function that updates the status of a purchase order, handles different scenarios based on the status, and displays appropriate toast messages.
 */
  const handleSave = async (status, sendVendor = false) => {
    

    if(sendVendor){
      setButtonStatusApprove(1)
    }else{
      setButtonStatus(1)
    } 
    try {
      if (!message && (status === 'declined' || status === 'cancelled')) {
        toast.error(t.workOrders.submit_error);
        return;
      }
      setLoading(true);
      let res = await updatePurchaseOrderStatus(
        data.id,
        status,
        message,
        sendVendor
      );

      setModal(false);
      setOpenApproveModal(false);
      setMessage('');
      setDeclineFlag(false);
      setLoading(false);
      if (res && res.status == 200) {
        if (status === 'approved' && !sendVendor) {
          const buffer = new Uint8Array(res.data);

          const blob = new Blob([buffer], { type: 'application/pdf' }); // Use response content type

          const fileUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute(
            'download',
            `PO-${targetedData?.attributes?.details?.poNumber}`
          ); // Use filename from header or default
          document.body.appendChild(link);
          link.click();
        }

        // const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
        // saveAs(pdfBlob, 'downloaded_file.pdf');
        // console.log('pdfBlob: ', pdfBlob);
        untoggle();

        setSearchTitle('');
        setSelectedDates([]);
        setDueDates([]);
        toast.success(
          status === 'declined'
            ? t.po.form.decline_success
            : status === 'close'
            ? t.po.form.close_success
            : status === 'cancelled'
            ? t.po.form.cancelled_success
            : t.po.form.approve_success
        );
      } else if (res.status === 400) {
        toast.error(t.po.form.update_status_error);
      } else if (res.status === 404) {
        if (status === 'approved') {
          setOpenVendorEmailModal(true);
        } else {
          toast.error(t.common.something_wrong);
        }
      } else {
        toast.error(t.common.something_wrong);
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.message);
      setModal(false);
      setMessage('');
      setLoading(false);
    }
    if(sendVendor){
      setButtonStatusApprove(0)
    }else{
      setButtonStatus(0)
    }
  };

/*
 * The function `handleDecimalPoints` formats a numerical value to a specified number of decimal places and removes trailing zeros after the decimal point.
 */
  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf('.') !== -1) {
      return formattedValue.replace(/\.?0+$/, '');
    }

    return Number(formattedValue);
  };

/**
 * The function `handleAddSet` processes and fulfills a purchase order with error handling and success notifications.
 */
  const handleAddSet = async (values, resetForm) => {
    let addedSetsData = cloneDeep(values);
   

    if (
      addedSetsData.orderItems.subTotal == 0 ||
      addedSetsData.orderItems.subTotal == null
    ) {
      return toast.error(t.po.form.subTotal_error);
    }

    const transformedItemsArray = addedSetsData?.orderItems?.items.map(
      (item) => ({
        part: item.part,
        qtyToOrder: Number(item.quantity),
        unitCost: Number(item.unitCost),
        totalCost: Number(item.totalCost),
        receivedQty: Number(item.receivedQty),
        fillToMax: item?.fillMax ? item?.fillMax : false,
        // totalCost: item.totalCost,
      })
    );

    addedSetsData.orderItems.items = transformedItemsArray;
    setButtonStatus(1)
    const response = await fulFillPurchaseOrder(
      addedSetsData,
      'yes',
      organisationId,
      targetedData?.id,
      'fulfilled'
    );

    if (response.status == 200) {
      setSelectedDates([]);
      setSearchTitle('');
      setDueDates([]);
      untoggle();
      toast.success(
        response?.data?.status == 'fulfilled'
          ? t.po.form.fulFill_success
          : t.po.form.pfulFill_success
      );
      resetForm();
      // toggleSuccessModal(true);
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
      }
    }
    setButtonStatus(0)
  };

  const workOrderStatus = statusOptions.find(
    (status) => status.value === data?.attributes?.status
  );

  let allQtyOptions = [
    {
      key: 0,
      value: 'pieces',
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: 'items',
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: 'units',
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: 'liters (L)',
      text: selectedLanguage == 'en' ? 'l' : 'لتر',
    },
    {
      key: 4,
      value: 'gallons (gal)',
      text: selectedLanguage == 'en' ? 'gal' : 'جالون',
    },
    {
      key: 5,
      value: 'cubic meters (m³)',
      text: selectedLanguage == 'en' ? 'm³' : 'م³',
    },
    {
      key: 6,
      value: 'cubic feet (ft³)',
      text: selectedLanguage == 'en' ? 'ft³' : 'ق³',
    },
    {
      key: 7,
      value: 'barrels',
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: 'kilograms (kg)',
      text: selectedLanguage == 'en' ? 'kg' : 'كجم',
    },
    {
      key: 9,
      value: 'pounds (lb)',
      text: selectedLanguage == 'en' ? 'lb' : 'رطل',
    },

    {
      key: 10,
      value: 'tons',
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: 'metric tonnes',
      text: t.parts.mass_unit.m_tons,
    },
  ];

/*
 * The function `renderAssigneePeople` takes an array of assignee people objects, checks if they have a
 * profile image URL, and renders their names and profile images with optional link permissions.
 */
  const renderAssigneePeople = (assigneePeople) => {
    if (assigneePeople?.length > 0) {
      return assigneePeople.map((people, index) => {
        if (people?.attributes?.profileImage?.data?.attributes?.url) {
          return (
            <p>
              <Image
                key={index}
                avatar
                title={`${people.attributes.firstName} ${people.attributes.lastName}`}
                src={`${people?.attributes?.profileImage?.data?.attributes?.url}`}
                style={
                  index !== 0
                    ? selectedLanguage == "ar" ?  { right: '-6px', width: '34px', height: '34px' } :{ left: '-6px', width: '34px', height: '34px' }
                    : { width: '34px', height: '34px' }
                }
              />
              {checkForLinkPermission('people') ? (
                <span
                  onClick={() =>
                    history.push(`/people?id=${people.attributes?.countId}`)
                  }
                  className="detail-intro-link ml-1 mr-1"
                >
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className=" ml-1 mr-1">
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        } else {
          return (
            <p>
              <UserProfilePlaceholder
                key={index}
                name={`${
                  people?.attributes?.firstName
                    ? people?.attributes?.firstName
                    : ''
                } ${
                  people?.attributes?.lastName
                    ? people?.attributes?.lastName
                    : ''
                }`}
                width={'34px'}
                height={'34px'}
                right={selectedLanguage == "en" ?  '6px' : "-7px"}
              />
              {checkForLinkPermission('people') ? (
                <span
                  onClick={() =>
                    history.push(`/people?id=${people?.attributes?.countId}`)
                  }
                  className="detail-intro-link"
                >
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className="">
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        }
      });
    } else {
      return t.common.na;
    }
  };

/**
 * The function `HandleUnitType` takes in data and returns the corresponding text value from `allQtyOptions` based on the data provided.
 */
  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

/**
 * The function `checkValidUser` checks if the current user ID exists in the list of people data within the targeted data attributes for approve/decline PO.
 */
  const checkValidUser = () => {
    if (targetedData?.attributes?.details?.people?.data?.length > 0) {
      return targetedData?.attributes?.details?.people?.data?.some(
        (item) => item.id === currentUserId
      );
    }

    return false;
  };

  return (
    <div className="preview-details-wrapper">
      <div className="intro">
        {userPermissions?.['purchase-order']?.restock && checkValidUser() ? (
          <div style={{ fontSize: '15px' }}>{confirmPurchaseOrder()}</div>
        ) : null}
      </div>
      <div className="data-header mt-5">
        <div className="header-title d-flex justify-content-between align-items-start">
          <div className="header-pill-container d-flex align-items-baseline">
            <p className="detail-title">{data?.attributes?.details?.title}</p>
          </div>
        </div>
      </div>

      <div className="align-items-center justify-content-start d-flex mb-4">
        <label className="label-item mt-5">
          {''}
          <label className="workorder-intro-label po-label">
            {t.workOrders.filter.status}
            {':'}
          </label>
        </label>
        <p className="workorder-intro-value mt-2 d-flex gap-3">
          <div
            className={`item-value mt-3 ${
              selectedLanguage == 'en' ? '' : 'ml-2'
            }`}
          >
            <Label
              circular
              empty
              color={workOrderStatus?.color}
              style={{ marginRight: '5px', width: '12px', height: '12px' }}
              size="medium"
            />
          </div>
          <span className="mt-3 ">
            {data?.attributes.status ? workOrderStatus?.label : t.common.na}
          </span>
        </p>
      </div>

      <div className="d-flex align-items-start mt-5">
        <label className="workorder-intro-label  po-label">
          {t.po.form.ponumber}
          {':'}
        </label>
        <p className="workorder-intro-value">
          {data?.attributes?.details?.poNumber
            ? data?.attributes?.details?.poNumber
            : t.common.na}
        </p>
      </div>

      <div className="d-flex align-items-start mt-5">
        <label className="workorder-intro-label  po-label">
          {t.po.table.vendor}
          {':'}
        </label>
        <p className="workorder-intro-value">
          {data?.attributes?.details?.vendor?.data ? (
            <React.Fragment>
              {checkForLinkPermission('vendor') ? (
                <span
                  className={`detail-intro-link`}
                  basic
                  onClick={() =>
                    history.push(
                      `/vendors?id=${data?.attributes?.details?.vendor?.data?.attributes?.countId}`
                    )
                  }
                >
                  {data?.attributes?.details?.vendor?.data?.attributes?.name}
                </span>
              ) : (
                <span className=" ml-1 mr-1">
                  {data?.attributes?.details?.vendor?.data?.attributes?.name}
                </span>
              )}
            </React.Fragment>
          ) : (
            t.common.na
          )}
        </p>
      </div>

      <div className="d-flex align-items-start mt-5">
        <label className="workorder-intro-label  po-label">
          {t.po.form.vendor_address}

          {':'}
        </label>
        <p className="workorder-intro-value">
          {data?.attributes?.details?.address
            ? data?.attributes?.details?.address
            : t.common.na}
        </p>
      </div>

      <div className="d-flex align-items-start mt-5">
        <label className="workorder-intro-label  po-label">
          {t.workRequests.form.people}
          {':'}
        </label>
        <p
          className="workorder-intro-value"
          style={
            data?.attributes?.people?.data.length > 0
              ? { marginTop: '-6px' }
              : {}
          }
        >
          {renderAssigneePeople(data?.attributes?.details?.people?.data)}
        </p>
      </div>

      <div className="d-flex align-items-start mt-5">
        <label className="workorder-intro-label po-label">
          {t.workOrders.table.dueDate}
          {':'}
        </label>
        <p className="workorder-intro-value">
          {data?.attributes?.details?.dueDate ? (
            <Moment
              format="DD/MM/YYYY"
              date={data?.attributes?.details?.dueDate}
            />
          ) : (
            <span>{t.common.na}</span>
          )}
        </p>
      </div>

      <div className="d-flex align-items-start mt-5">
        <label className="workorder-intro-label po-label">
          {t.po.form.additionalInformation}
          {':'}
        </label>
        <p className="workorder-intro-value">
          {data?.attributes?.details?.additionalInformation
            ? data?.attributes?.details?.additionalInformation
            : t.common.na}
        </p>
      </div>

      <>
        <Divider />

        <div className="billing-info  d-flex mt-7">
          <ItemsIcon />
          <p
            style={{ fontSize: '16px' }}
            className={` required-text mt-1 ${
              selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
            }`}
          >
            {t.po.form.order_items}
          </p>
        </div>

        <div className="order-items-section order-item-header-change mt-7">
          <div class="kanbar-header mb-1">
            <div class="grid-container-element set2">
              <div class="grid-child-element set2">{t.po.form.item_name}</div>
              <div class="grid-child-element set2">{t.po.form.part_no}</div>
              <div class="grid-child-element set2">{t.po.form.order_qty}</div>
              {data?.attributes?.status == 'fulfilled' ||
              data?.attributes?.status == 'partiallyfulfilled' ? (
                <div class="grid-child-element set2">
                  {t.po.form.receive_qty}
                </div>
              ) : null}
              <div class="grid-child-element set2">
                {t.parts.form.unit_cost}
              </div>
              <div class="grid-child-element set2">
                {t.parts.form.total_cost}
              </div>
            </div>
          </div>
          {data?.attributes?.orderItems?.items &&
          data?.attributes?.orderItems?.items?.length > 0 ? (
            <div className="detail-order-section">
              {data.attributes.orderItems.items.map((item, index) => (
                <>
                  <div key={index} className="grid-container-element set2">
                    {checkForLinkPermission('part') ? (
                      <div
                        onClick={() =>
                          history.push(
                            `/parts?id=${item?.part?.data?.attributes?.countId}`
                          )
                        }
                        className="grid-child-element set2 blue-item c-pointer"
                      >
                        {item?.part?.data?.attributes?.name}
                      </div>
                    ) : (
                      <div className="grid-child-element set2 blue-item c-pointer">
                        {item?.part?.data?.attributes?.name}
                      </div>
                    )}
                    <div className="grid-child-element set2">
                      {item?.part?.data?.attributes?.countId}
                    </div>
                    <div className="grid-child-element set2">
                      {item?.qtyToOrder}{' '}
                      {HandleUnitType(
                        item?.part?.data?.attributes?.measuringUnit
                      )}
                    </div>
                    {data?.attributes?.status == 'fulfilled' ||
                    data?.attributes?.status == 'partiallyfulfilled' ? (
                      <div className="grid-child-element set2">
                        {item?.receivedQty ? item?.receivedQty : 0}{' '}
                        {HandleUnitType(
                          item?.part?.data?.attributes?.measuringUnit
                        )}
                      </div>
                    ) : null}
                    <div className="grid-child-element set2">
                      {handleDecimalPoints(item?.unitCost)}{' '}
                      {organisationCurrency}s
                    </div>
                    <div className="grid-child-element set2">
                      {handleDecimalPoints(item?.totalCost)}{' '}
                      {organisationCurrency}s
                    </div>
                  </div>
                  <Divider className="mt-1 mb-1" />
                </>
              ))}
            </div>
          ) : null}

          <div className="detail-of-sub-section-order">
            <div className="sub-header-oredr-section">
              <div className="title-sub-order tri-grid-section-or mt-4">
                <p>{t.po.form.subTotal}</p>
                <p>
                  {handleDecimalPoints(data?.attributes?.orderItems?.subTotal)}
                </p>
                <p>{organisationCurrency}s</p>
              </div>
              <Divider />
              <div className="detail-sub-order tri-grid-section-or">
                <p className='d-flex justify-content-between'>
                  <div> {t.po.form.taxes}</div>
                 
                  {data?.attributes?.orderItems?.taxesInPercentage ? (
                    <div>({data?.attributes?.orderItems?.taxes}%)</div>
                  ) : null}
                </p>

                <p>
                  {data?.attributes?.orderItems?.taxAmt
                    ? handleDecimalPoints(data?.attributes?.orderItems?.taxAmt)
                    : 0}
                </p>
                <p>{organisationCurrency}s</p>
              </div>
              <div className="detail-sub-order tri-grid-section-or">
                
              <p className='d-flex justify-content-between'><div> {t.po.form.shipping_cost} </div> {data?.attributes?.orderItems?.shippingCostInPercentage ? (
                    <div  className=''>({data?.attributes?.orderItems?.shippingCost}%)</div>
                  ) : null}</p>
                <p>
                  {data?.attributes?.orderItems?.shipAmt
                    ? handleDecimalPoints(data?.attributes?.orderItems?.shipAmt)
                    : 0}
                </p>
                <p>{organisationCurrency}s</p>
              </div>
              <div className="detail-sub-order tri-grid-section-or">
                <p  className='d-flex justify-content-between'><div>{t.po.form.other_cost}</div> {data?.attributes?.orderItems?.otherCostInPercentage ? (
                    <div className=''>({data?.attributes?.orderItems?.otherCost}%)</div>
                  ) : null}</p>
                <p>
                  {data?.attributes?.orderItems?.otherAmt
                    ? handleDecimalPoints(
                        data?.attributes?.orderItems?.otherAmt
                      )
                    : 0}
                </p>
                <p>{organisationCurrency}s</p>
              </div>
              <Divider className="mt-0" />
              <div className="title-sub-order tri-grid-section-or">
                <p>{t.po.form.total}</p>
                <p>
                  {handleDecimalPoints(data?.attributes?.orderItems?.totalCost)}
                </p>
                <p>{organisationCurrency}s</p>
              </div>
            </div>
          </div>
        </div>

        <div className="details-wrapper">
          <Divider className="mt-7" />

          <div
            className={`billing-info billing-info2 mt-5 ${
              selectedLanguage == 'en' ? '' : ''
            }`}
          >
            <HomeIcon />
            <span
              style={{ fontSize: '16px' }}
              className={` required-text ${
                selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
              }`}
            >
              {t.po.form.shipping_address}
            </span>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.po.form.company_name}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.shippingAddress?.companyName?.data
                ?.attributes?.name
                ? targetedData?.attributes?.shippingAddress?.companyName?.data
                    ?.attributes?.name
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.parts.filter.area}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.shippingAddress?.area?.data?.attributes
                ?.name
                ? targetedData?.attributes?.shippingAddress?.area?.data
                    ?.attributes?.name
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.common.phone_number}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.shippingAddress?.contactPhoneNumber
                ? `${selectedLanguage == 'en' ? '+' : ''}${
                    targetedData?.attributes?.shippingAddress
                      ?.contactPhoneNumber
                  }${selectedLanguage == 'ar' ? '+' : ''}`
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.people.personal_info_form.address}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.shippingAddress?.address
                ? targetedData?.attributes?.shippingAddress?.address
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.locations.form.city}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.shippingAddress?.city
                ? targetedData?.attributes?.shippingAddress?.city
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.po.form.state}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.shippingAddress?.state
                ? targetedData?.attributes?.shippingAddress?.state
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.po.form.zip_code}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.shippingAddress?.zipcode
                ? targetedData?.attributes?.shippingAddress?.zipcode
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <Divider />

          <div
            className={`billing-info billing-info2 mt-5 ${
              selectedLanguage == 'en' ? '' : ''
            }`}
          >
            <HomeIcon />
            <span
              style={{ fontSize: '16px' }}
              className={` required-text ${
                selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
              }`}
            >
              {t.po.form.billing_address}
            </span>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.po.form.company_name}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.billingAddress?.sameAsShippingAddress
                ? targetedData?.attributes?.shippingAddress?.companyName?.data
                    ?.attributes?.name
                  ? targetedData?.attributes?.shippingAddress?.companyName?.data
                      ?.attributes?.name
                  : t.common.na
                : targetedData?.attributes?.billingAddress?.companyName
                ? targetedData?.attributes?.billingAddress?.companyName
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.people.personal_info_form.address}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.billingAddress?.sameAsShippingAddress
                ? targetedData?.attributes?.shippingAddress?.address ? targetedData?.attributes?.shippingAddress?.address : t.common.na
                : targetedData?.attributes?.billingAddress?.address
                ? targetedData?.attributes?.billingAddress?.address
                : t.common.na}
              {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-start mt-4">
            <label className="workorder-intro-label po-label">
              {t.common.phone_number}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.billingAddress?.sameAsShippingAddress
                ? targetedData?.attributes?.shippingAddress?.contactPhoneNumber
                  ? `${selectedLanguage == 'en' ? '+' : ''}${
                      targetedData?.attributes?.shippingAddress
                        ?.contactPhoneNumber
                    }${selectedLanguage == 'ar' ? '+' : ''}`
                  : t.common.na
                : targetedData?.attributes?.billingAddress?.contactPhoneNumber
                ? `${selectedLanguage == 'en' ? '+' : ''}${
                    targetedData?.attributes?.billingAddress?.contactPhoneNumber
                  }${selectedLanguage == 'ar' ? '+' : ''}`
                : t.common.na}
            </p>
          </div>

          <Divider />

          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label po-label">
              {t.workOrders.form.file}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {data?.attributes?.attachments?.data?.length
                ? data.attributes.attachments.data.map((attachment, index) => (
                    <React.Fragment key={index}>
                      <p
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == 'en' ? 'text-left' : 'text-right'
                        }`}
                        basic
                        onClick={() => {
                          window.open(attachment?.attributes?.url, '_blank');
                        }}
                      >
                        {attachment?.attributes?.name}
                      </p>
                    </React.Fragment>
                  ))
                : t.common.na}
            </p>
          </div>

          <Divider />

          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label po-label grey">
              {t.workOrders.table.createdByUser}
              {':'}
            </label>
            <p className="workorder-intro-value grey">
              {targetedData?.attributes?.createdByUser?.data?.attributes
                ?.firstName &&
              targetedData?.attributes?.createdByUser?.data?.attributes
                ?.lastName
                ? targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.firstName +
                  ' ' +
                  targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.lastName
                : t.common.na}
              {', '}
              <Moment
                format="DD-MM-YYYY LT"
                date={targetedData?.attributes?.createdAt}
              />
            </p>
          </div>

          <div className="d-flex align-items-start mt-3">
            <label className="workorder-intro-label po-label grey">
              {t.common.update_by}
              {':'}
            </label>
            <p className="workorder-intro-value grey">
              {targetedData?.attributes?.updatedByUser?.data
                ? targetedData?.attributes?.updatedByUser?.data?.attributes
                    ?.firstName +
                  ' ' +
                  targetedData?.attributes?.updatedByUser?.data?.attributes
                    ?.lastName +
                  ', ' +
                  moment(targetedData?.attributes?.updatedAt).format(
                    'DD-MM-YYYY LT'
                  )
                : t.common.na}
            </p>
          </div>
        </div>

        <Modal
          className="assets-modal"
          size="tiny"
          open={openModal}
          onClose={() => {
            setModal(false);
            setMessage('');
            setDeclineFlag(false);
          }}
        >
          <div className={'header-container red'}>
            <span className="assets-header">{t.po.form.decline_po}</span>
            <div>
              <span
                className="c-pointer text-right"
                onClick={() => {
                  setModal(false);
                  setMessage('');
                  setDeclineFlag(false);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>

          <Modal.Content>
            <div className="comment-container" style={{ marginLeft: '3px' }}>
              <label className="comment-label">
                {' '}
                {/* {t.workOrders.are_you_sure}{' '} */}
                {t.po.form.decline_po_statement} {/* {t.workOrders.this_WO} */}
              </label>
              <div className="add-comment">
                <TextArea
                  className="comment-textarea"
                  rows={5}
                  placeholder={t.workOrders.required}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div
              className={`modal-button-container ${
                selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
              }`}
            >
              <button
                className="modal-form-action-button"
                onClick={() => {
                  setModal(false);
                  setMessage('');
                  setDeclineFlag(false);
                }}
                disabled={loading}
                style={{ color: '#172B4D', fontWeight: '500' }}
              >
                {t.assets.form.cancel}
              </button>

              <button
                onClick={() => {
                  handleSave(declineFlag ? 'cancelled' : 'declined');
                }}
                disabled={loading}
                style={{ fontWeight: '500',minWidth:"72px" }}
                className={`modal-form-action-button light-blue`}
              >
                {buttonStatus == 1 ?   <Spinner color="light" size="sm">
                    <div>{null}</div>
                  </Spinner> : t.workOrders.details_view.decline}
              </button>
            </div>
          </Modal.Actions>
        </Modal>

        <Modal
          className="assets-modal"
          style={{ width: '408px' }}
          size="tiny"
          open={openApproveModal}
          onClose={() => {
            setOpenApproveModal(false);
          }}
        >
          <div className={'header-container   '}>
            <span className="assets-header">{t.po.form.approve_po}</span>
            <div>
              <span
                className="c-pointer text-right"
                onClick={() => {
                  setOpenApproveModal(false);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>

          <Modal.Content>
            <div className="comment-container" style={{ marginLeft: '3px' }}>
              <label className="comment-label">
                {' '}
                {/* {t.workOrders.are_you_sure}{' '} */}
                {t.po.form.approve_po_statement} {/* {t.workOrders.this_WO} */}
              </label>
            </div>
          </Modal.Content>

          <div
            className={`modal-button-container  modal-n-fufill-btn justify-content-center
             ${selectedLanguage == 'en' ? 'ml-2' : 'ml-3'}
            `}
            style={{ gap: '22px' }}
          >
            <button
              className="modal-form-action-button light-blue purchase-order"
              onClick={() => {
                // setOpenApproveModal(false);
                handleSave('approved');
                // setMessage('');
              }}
              disabled={loading}
              style={{ fontWeight: '500' }}
            >
              {buttonStatus == 0 ?<>{t.po.form.approve_download_pdf } <DownloadPdfIcon /></>   :  <Spinner color="light" size="sm">
                    <div>{null}</div>
                  </Spinner>}
              <span className="">
                {' '}
               
              </span>
            </button>

            <button
              onClick={() => {
                handleSave('approved', true);
              }}
              disabled={loading}
              style={{ fontWeight: '500' }}
              className={`modal-form-action-button light-blue  purchase-order`}
            >
              {buttonStatusApprove == 1 ? <Spinner color="light" size="sm">
                    <div>{null}</div>
                  </Spinner>  :<> {t.po.form.send_to_vendor} <SendVendorIcon />{' '} </>}
              <span className={selectedLanguage == 'en' ? 'ml-2' : ''}>
                {' '}
               
              </span>
            </button>
          </div>
        </Modal>

        <Modal
          size="tiny"
          className="assets-modal"
          open={openVendorEmailModal}
          onClose={() => {
            setOpenVendorEmailModal(false);
          }}
          style={{ minHeight: '191px' }}
        >
          <div className="header-container red">
            <span className="assets-header">{t.po.form.vendor_email}</span>
            <div>
              <span
                className="c-pointer text-right"
                onClick={() => {
                  setOpenVendorEmailModal(false);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>

          <Modal.Content>
            <p className="assets-label ml-1">
              {t.po.form.vendor_email_not_found}
            </p>
          </Modal.Content>
          <div
            className={`modal-button-container mt-4 ${
              selectedLanguage == 'en' ? 'mr-8' : 'ml-10'
            }`}
          >
            <button
              onClick={() => {
                setOpenVendorEmailModal(false);
              }}
              style={{ fontWeight: '500' }}
              className="modal-form-action-button black"
            >
              {t.common.cancel}
            </button>
          </div>
        </Modal>

        <NewCancelModal
          yesCallback={() => {
            handleSave('close');
          }}
          po={true}
          title={t.po.form.close_po}
        >
          <span>{t.po.form.close_statement}</span>
        </NewCancelModal>

        {!loader && (
          <Modal
            className="assets-modal"
            open={openFuifillModal}
            onClose={() => {
              setOpenFuifillModal(false);
            }}
          >
            <div className={'header-container   '}>
              <span className="assets-header">{t.po.form.fulfill_title}</span>
              <div>
                <span
                  className="c-pointer text-right"
                  onClick={() => {
                    setOpenFuifillModal(false);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            </div>

            <Modal.Content className="pb-0">
              <div className="comment-container" style={{ marginLeft: '3px' }}>
                <label className="comment-label wrapper-new-comment-container po">
                  {' '}
                  {/* {t.workOrders.are_you_sure}{' '} */}
                  {t.po.form.fulfill_statement} {/* {t.workOrders.this_WO} */}
                  <FullfillForm
                    initialFormValues={editSetData}
                    buttonStatus={buttonStatus}
                    setButtonStatus={setButtonStatus}
                    setOpenFuifillModal={setOpenFuifillModal}
                    handleAddSet={handleAddSet}
                  />
                </label>
              </div>
            </Modal.Content>

            {/* <div
            className={`modal-button-container justify-content-center
             ${selectedLanguage == 'en' ? 'ml-2' : 'ml-3'}
            `}
            style={{ gap: '22px' }}
          >
            <button
              className="modal-form-action-button light-blue purchase-order"
              onClick={() => {
                // setOpenApproveModal(false);
                handleSave('approved');
                // setMessage('');
              }}
              disabled={loading}
              style={{ fontWeight: '500' }}
            >
              {t.common.download_pdf}
              <span className="ml-2">
                {' '}
                <DownloadPdfIcon />{' '}
              </span>
            </button>

            <button
              onClick={() => {
                handleSave('approved');
              }}
              disabled={loading}
              style={{ fontWeight: '500' }}
              className={`modal-form-action-button light-blue  purchase-order`}
            >
              {t.po.form.send_to_vendor}
              <span className="ml-2">
                {' '}
                <SendVendorIcon />{' '}
              </span>
            </button>
          </div> */}
          </Modal>
        )}
      </>
    </div>
  );
};

export default Details;
