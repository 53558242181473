import React, { useState } from 'react';
import useDeletePermissionModal from '../../../../hooks/Messages/useDeletePermissionModal';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import {
    Icon,
    Button,
    Label,
    Table,
    Loader,
    Dimmer,
    TextArea,
    Form,
    Header,
    Modal,
    Tab,
    Divider,
  } from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';
import moment from 'moment';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import useDisplayFormattedText from '../../../../hooks/useDisplayFormattedText';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import { currencyDecimalPlaces, getAllWorkOrdersBySetId, humanize } from '../../../config/functions';
import Moment from 'react-moment';
const Details = ({ data }) => {

    const [fetching, setFetchLoading] = useState(true);
    const t = useSelector(selectTranslations);
    const selectedLanguage = useSelector((state) => {
      if (state?.i18n?.lang) {
        return state.i18n.lang;
      } else {
        return 'en';
      }
    });
  
    const organisationId = useSelector((state) => {
      if (state?.auth?.user?.organisation) {
        return state.auth.user.organisation.id;
      } else {
        return null;
      }
    });
  
    let allQtyOptions = [
      {
        key: 0,
        value: 'pieces',
        text: t.parts.qty_unit.piece,
      },
      {
        key: 1,
        value: 'items',
        text: t.parts.qty_unit.items,
      },
      {
        key: 2,
        value: 'units',
        text: t.parts.qty_unit.unit,
      },
      {
        key: 3,
        value: 'liters (L)',
        text: selectedLanguage == 'en' ? 'l' : 'لتر',
      },
      {
        key: 4,
        value: 'gallons (gal)',
        text: selectedLanguage == 'en' ? 'gal' : 'جالون',
      },
      {
        key: 5,
        value: 'cubic meters (m³)',
        text: selectedLanguage == 'en' ? 'm³' : 'م³',
      },
      {
        key: 6,
        value: 'cubic feet (ft³)',
        text: selectedLanguage == 'en' ? 'ft³' : 'ق³',
      },
      {
        key: 7,
        value: 'barrels',
        text: t.parts.vol_unit.barrels,
      },
      {
        key: 8,
        value: 'kilograms (kg)',
        text: selectedLanguage == 'en' ? 'kg' : 'كجم',
      },
      {
        key: 9,
        value: 'pounds (lb)',
        text: selectedLanguage == 'en' ? 'lb' : 'رطل',
      },
  
      {
        key: 10,
        value: 'tons',
        text: t.parts.mass_unit.tons,
      },
      {
        key: 11,
        value: 'metric tonnes',
        text: t.parts.mass_unit.m_tons,
      },
    ];
  
    const [message, setMessage] = useState('');
    // const [data, setData] = useState(null);
    const [WOdata, setWOData] = useState(null);
    const [toggleDeclineModal, setToggleDeclineModal] = useState(false);
    const [declinedReason, setDeclinedReason] = useState('');
    const [showExclTooltip, setShowExclTooltip] = useState(false);
    const [toggleWOModal, setToggleWOModal] = useState(false);
  
    const [showPdfTooltip, setShowPdfTooltip] = useState(false);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [showShareTooltip, setShareTooltip] = useState(false);
    const [showEditTooltip, setEditTooltip] = useState(false);
    const [showDeleteTooltip, setDeleteTooltip] = useState(false);
    const [showCancleTooltip, setCancleTooltip] = useState(false);
  
    const [toggleAsideModal, setToggleAsideModal] = useState(false);
  
    const { userPermissions, checkForPermission } = usePermissionsSimplified();
  
    const [deleteModal, setDeleteModal] = useState(false);
  
    const history = useHistory();
  
    const {
      setDeletePermissionModal,
      DeletePermissionModal,
    } = useDeletePermissionModal();
  
    const { DisplayFormattedText } = useDisplayFormattedText();
  
    const headers = [
      { label: t.workOrders.table.ID, key: 'id' },
      { label: t.workOrders.table.title, key: 'title' },
      { label: t.assets.form.description, key: 'description' },
      { label: t.workRequests.table.wr_status, key: 'status' },
      { label: t.workRequests.table.wo_status, key: 'wo_status' },
      { label: t.workOrders.table.priority, key: 'priority' },
      { label: t.workOrders.table.location, key: 'location' },
      { label: t.workRequests.table.asset, key: 'asset' },
      { label: t.workRequests.form.people, key: 'people' },
      { label: t.workRequests.form.teams, key: 'teams' },
      { label: t.workRequests.table.wo_id, key: 'wo_id' },
      { label: t.workRequests.table.sub_date, key: 'sub_date' },
      { label: t.workRequests.table.sub_by, key: 'sub_by' },
    ];
  
    const HandleUnitType = (data) => {
      let unitType;
      if (data) {
        unitType = allQtyOptions.find((el) => el.value == data);
      }
      return data ? unitType?.text : null;
    };
  
    const organisationCurrency = useSelector((state) => {
      if (state?.auth?.user?.organisation) {
        return state.auth.user.organisation.currency;
      } else {
        return 'JOD';
      }
    });
  
    const generatePDF = () => {
      let csvData = [];
  
      let wr_data = [];
  
      wr_data.id = data.id;
      wr_data.title = data.attributes.title || t.common.na;
  
      wr_data.description = data?.attributes?.description
        ? data.attributes.description
        : t.common.na;
  
      // const workRequestStatus = WRstatusOptions.find(
      //   (status) => status?.value === data?.attributes?.status
      // );
  
      // wr_data.status = workRequestStatus?.label || t.common.na;
  
      // const PriorityStatus = priorityOptions.find(
      //   (status) => status.value === data.attributes.priority
      // );
  
      wr_data.location = data.attributes?.location?.data?.attributes?.name
        ? data.attributes.location.data.attributes.name
        : t.common.na;
  
      let assets = data.attributes?.assets?.data;
      let assetsString = '';
  
      if (assets?.length > 0) {
        assetsString = assets.map((asset) => asset?.attributes?.name).join(', ');
      } else {
        assetsString = t.common.na;
      }
      wr_data.asset = assetsString;
  
      let people = data?.attributes?.people?.data;
      let peopleString = '';
  
      if (people?.length > 0) {
        peopleString = people
          .map(
            (people) =>
              people?.attributes?.firstName + ' ' + people?.attributes?.lastName
          )
          .join(', ');
      } else {
        peopleString = t.common.na;
      }
      wr_data.people = peopleString;
  
      let teams = data?.attributes?.teams?.data;
      let teamsString = '';
  
      if (teams?.length > 0) {
        teamsString = teams.map((team) => team?.attributes?.name).join(', ');
      } else {
        teamsString = t.common.na;
      }
      wr_data.team = teamsString;
  
      wr_data.wo_id = data.attributes?.workOrder?.data?.id
        ? data.attributes?.workOrder?.data?.id
        : '';
  
      wr_data.sub_date = data?.attributes?.createdAt
        ? moment(data.attributes?.createdAt).format('YYYY-MM-DD')
        : t.common.na;
  
      wr_data.sub_by =
        data.attributes?.submittedBy?.data?.attributes?.firstName ||
        data?.attributes?.submittedBy?.data?.attributes?.lastName
          ? data.attributes?.submittedBy?.data?.attributes?.firstName +
            ' ' +
            data.attributes.submittedBy.data.attributes.lastName
          : t.common.na;
  
      csvData.push([
        wr_data.id,
        wr_data.title,
        wr_data.description,
        wr_data.status,
        wr_data.wo_status,
        wr_data.priority,
        wr_data.location,
        wr_data.asset,
        wr_data.people,
        wr_data.team,
        wr_data.wo_id,
        wr_data.sub_date,
        wr_data.sub_by,
      ]);
  
      const columnWidths = [10, 22, 22, 22, 23, 20, 23, 25, 25, 23, 16, 26, 25];
      DownloadPdfFileWithData(
        headers,
        csvData,
        data?.attributes?.title || t.workRequests.title,
        columnWidths,
        'A3'
      );
    };
  
    const handleDecimalPoints = (value) => {
      const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
      const factor = Math.pow(10, decimalPlaces);
      const truncatedValue = Math.trunc(value * factor) / factor;
      const formattedValue = truncatedValue.toFixed(decimalPlaces);
      
      // Remove trailing zeros after the decimal point
      if (formattedValue.indexOf('.') !== -1) {
        return formattedValue.replace(/\.?0+$/, '');
      }
    
      return formattedValue;
    };
  
    /* The above code is defining an array called `panes` which contains objects with `menuItem` and `pane`
  properties. Each object represents a tab pane with a menu item and content to be displayed when the
  tab is selected. */
    //   const panes = [
    //     {
    //       menuItem: t.common.details,
    //       pane: {
    //         key: 'details',
    //         content: (
    //           <div className="pane-body">
    //             <Details
    //               untoggle={untoggle}
    //               setToggleAsideModal={setToggleAsideModal}
  
    //               toggleAsideModal={toggleAsideModal}
    //               data={data}
    //               setMessage={setMessage}
    //               message={message}
    //               setData={setData}
    //               setFilterOptions={setFilterOptions}
    //               setSearchTitle={setSearchTitle}
    //               setSelectedDates={setSelectedDates}
    //               targetedData={targetedData}
    //             />
    //           </div>
    //         ),
    //       },
    //     },
    //     {
    //       menuItem: t.workOrders.details_view.log,
    //       pane: {
    //         key: 'logs',
    //         content: (
    //           <div className="pane-body">
    //             <Logs targetedData={data} />
    //           </div>
    //         ),
    //       },
    //     },
    //   ];



  return (
    <>
      <div className="preview-details-wrapper">
        <div className="data-header mt-5">
          <div className="header-title">
            <div className="header-pill-container">
              <p
                className="detail-title"
                style={{ width: '-webkit-fill-available' }}
              >
                {data?.attributes?.name}
              </p>
            </div>
          </div>

          <div className="d-flex align-items-start mt-2">
            <div
              style={{
                backgroundColor: '#E4E6EA',
                borderRadius: '3px',
              }}
            >
              <div className="d-flex p-1">
                <label className="workorder-intro-value ml-2 mb-0 mr-0 font-weight-bold">
                  {t.sets.table.total_cost}
                </label>
                <p
                  className={`workorder-intro-value font-weight-bold ml-7 mr-2`}
                >
                  {handleDecimalPoints(data?.attributes?.totalCost) ||
                    t.common.na}{' '}
                  {`${organisationCurrency}s`}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="part-section-preview">

        <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label set-label">
              {t.assets.form.location}
              {":"}
            </label>
            <p className="workorder-intro-value">
              {data?.attributes?.location?.data?.id ? (
                ("location") ? (
                  <span
                    className="detail-intro-link"
                    basic
                    onClick={() =>
                      history.push(
                        `/locations?id=${data?.attributes?.location?.data?.attributes?.countId}`
                      )
                    }
                  >
                    {data?.attributes?.location?.data?.attributes?.name}
                  </span>
                ) : (
                  <span className=" ml-1 mr-1" basic>
                    {data?.attributes?.location?.data?.attributes?.name}
                  </span>
                )
              ) : (
                t.common.na
              )}
            </p>
          </div>
          {data?.attributes?.parts?.length > 0 ? (
            <>
              <label
                style={{ fontWeight: '500' }}
                className="label-item my-3 mt-6"
              >
                {t.parts.tab.first}
              </label>
              <div className="grid-container-element set">
                <div className="grid-child-element set"> {t.parts.table.name}</div>
                <div className="grid-child-element set">{t.sets.form.qty}</div>
                <div className="grid-child-element set">
                  {' '}
                  {t.parts.form.total_cost}
                </div>
                <div className="grid-child-element set"></div>
              </div>

        
              {data?.attributes?.parts?.map((part) => (
                <>
                  <div className="kanbar-header "></div>
                  <div className="mt-3 grid-container-element set set-section-test">
                    <div
                    // style={{ width: '204px' }}
                    className='grid-child-element set'
                    >
                      {' '}
                      <span
                        style={{
                          color: '#0C66E4',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          history.push(`/parts?id=${part?.part?.data?.attributes?.countId}`)
                        }
                      >
                        {part?.part?.data?.attributes?.name
                          ? DisplayFormattedText(
                              humanize(part?.part?.data?.attributes?.name),
                              18
                            )
                          : t.common.na}
                      </span>
                    </div>
                    <div
                      className="number-part-set grid-child-element set"
                      // style={{ width: '102px' }}
                    >
                      {' '}
                      <span>
                        {' '}
                        {part?.quantity}{' '}
                        {HandleUnitType(
                          part?.part?.data?.attributes?.measuringUnit
                        )}
                      </span>
                    </div>
                    <div className="  grid-child-element set">
                      <span>
                        {handleDecimalPoints(part?.totalCost)}{' '}
                        {organisationCurrency}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : null}

          {data?.attributes?.sets?.length > 0 ? (
            <>
              <label style={{ fontWeight: '500' }} className="label-item mt-7">
                {t.parts.tab.second}
              </label>
              <div className="grid-container-element set">
                <div className="grid-child-element set"> {t.parts.table.name}</div>
                <div className="grid-child-element set">{t.sets.form.qty}</div>
                <div className="grid-child-element set">
                  {' '}
                  {t.parts.form.total_cost}
                </div>
                <div className="grid-child-element set"></div>
              </div>
              {data?.attributes?.sets?.map((part) => (
                <>
                  <div className="kanbar-header"></div>
                  <div className="mt-3 grid-container-element set set-section-test">
                    <div className=" grid-child-element set">
                      {' '}
                      <span
                        style={{
                          color: '#0C66E4',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          history.push(`/sets?id=${part?.set?.data?.attributes?.countId}`)
                        }
                      >
                        {part?.set?.data?.attributes?.name
                          ? DisplayFormattedText(
                              humanize(part?.set?.data?.attributes?.name),
                              18
                            )
                          : t.common.na}
                      </span>
                    </div>
                    <div
                      className="number-part-set grid-child-element set"
                      style={{ width: '102px' }}
                    >
                      {' '}
                      <span> {part?.quantity} </span>
                    </div>
                    <div className=" grid-child-element set">
                      <span>
                        {handleDecimalPoints(part?.totalCost)}{' '}
                        {organisationCurrency}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : null}

          <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label set-label grey">
              {t.workOrders.table.createdByUser}
              {':'}
            </label>
            <p className="workorder-intro-value grey">
              {data?.attributes?.createdByUser?.data?.attributes?.firstName &&
              data?.attributes?.createdByUser?.data?.attributes?.lastName
                ? data?.attributes?.createdByUser?.data?.attributes?.firstName +
                  ' ' +
                  data?.attributes?.createdByUser?.data?.attributes?.lastName
                : t.common.na}
              {', '}
              <Moment
                format="DD-MM-YYYY LT"
                date={data?.attributes?.createdAt}
              />
            </p>
          </div>

          <div className="d-flex align-items-center mt-3">
            <label className="workorder-intro-label set-label grey">
              {t.common.update_by}
              {':'}
            </label>
            <p className="workorder-intro-value grey">
              {data?.attributes?.updatedByUser?.data
                ? data?.attributes?.updatedByUser?.data?.attributes?.firstName +
                  ' ' +
                  data?.attributes?.updatedByUser?.data?.attributes?.lastName +
                  ', ' +
                  moment(data?.attributes?.updatedAt).format('DD-MM-YYYY LT')
                : t.common.na}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
