import React, { useState } from 'react';
import Moment from 'react-moment';
import {
  Divider,
  Grid,
  Label,
  Image,
  Image as SemanticImage,
  Segment,
  Icon,
  Button,
  Modal,
  Header,
  Form,
  Input,
  TextArea,
  Dropdown,
} from 'semantic-ui-react';

import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { toast } from 'react-toastify';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import UserProfilePlaceholder from '../../../components/common/userProfilePlaceholder';
import Add from '../../WorkOrders/add';
import { CloseIcon, FlagWhiteIcon } from '../../../../Assets/Icons/svg';
import { updateWorkRequestStatus, urlToFile } from '../../../config/functions';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

const Details = ({
  data,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  untoggle,
  toggleAsideModal,
  setToggleAsideModal,
  targetedData,
  setData,
  setMessage,
  message,
  setmodalWidth,
  modalWidth,
  allIds,
  roleData
}) => {
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const userId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  })
  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const currentUserId = [];
  currentUserLocations?.length &&  currentUserLocations.forEach(location => {
    if (!currentUserId.some((inv) => inv == location?.id)) {
      currentUserId.push(location?.id);
    }
   
  location.subLocations.forEach(subLocation => {
    if(!currentUserId.some((inv) => inv == subLocation?.id)){
      currentUserId.push(subLocation?.id);
    }
  });
});

  const t = useSelector(selectTranslations);
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const history = useHistory();

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [refresh, setRefresh] = useState(false);
  const [loader, setloader] = useState(false);

  const [openModal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const confirmWorkRequest = () => {
    // if (data?.declined && !data?.confirmed) {
    //   return (
    //     <Label size="medium" basic color="red" style={{ height: 'auto' }}>
    //       <Icon name="dont" color="red" /> Declined
    //     </Label>
    //   );
    // }

    if (
      data.attributes.status === 'pending' &&
      userPermissions['work-request']?.assign
    ) {
      return (
        <>
          <div className="comment d-flex justify-content-between p-5 approve-container align-items-center">
            <div className="">
              <span className="user-img font-weight-bold">
                {data?.attributes?.submittedBy?.data?.attributes?.firstName ||
                data?.attributes?.submittedBy?.data?.attributes?.lastName
                  ? data?.attributes?.submittedBy?.data?.attributes?.firstName +
                    ' ' +
                    data?.attributes?.submittedBy?.data?.attributes?.lastName
                  : t.workRequests.form.unknown}{' '}
                <span className="font-weight-normal">
                  {t.workRequests.form.submitted}
                </span>
                <p className="item-label font-weight-normal">
                  {t.workRequests.form.this_wr}
                </p>
              </span>
            </div>
            <div className="approval-button-container ">
              <button
                className="modal-form-action-button decline "
                onClick={() => {
                  setModal(true);
                }}
              >
                {t.workOrders.details_view.decline}
              </button>

              <button
                onClick={() => {
                  untoggle();
                  setToggleAsideModal(true);
                }}
                className="modal-form-action-button blue"
              >
                {t.workOrders.details_view.approve}
              </button>
            </div>
          </div>
        </>
      );
    } else if (data.attributes.status === 'declined') {
      return (
        <div className="comment decline-container decline-border d-flex justify-content-between approve-container align-items-center">
          <div className="">
            <span className="user-img">
              {t.workRequests.form.declined_statement}{' '}
              <div className="">
                <label className="">
                  {t.common.reason}
                  {':'}
                </label>
                <span className="ml-2 mr-2">{data?.attributes?.declinedReason}</span>
              </div>
            </span>
          </div>
        </div>
      );
    } else if (data.attributes.status === 'approved') {
      return (
        <div className="comment approved-container approve-border">
          <div className="">
            <span className="user-img" style={{ fontSize: '14px' }}>
              {t.workRequests.form.approved_statement}
              {checkForLinkPermission('work-order') ? (
                <p
                  style={{ fontSize: '14px' }}
                  onClick={() =>
                    history.push(
                      `/work-orders?id=${data?.attributes?.workOrder?.data?.attributes?.countId}`
                    )
                  }
                  className={`id-text-style work-request ${
                    selectedLanguage == 'en' ? 'text-left' : 'text-right'
                  }`}
                >{`${t.workOrders.details_view.work_order_no} #${data?.attributes?.workOrder?.data?.attributes?.countId }`}</p>
              ) : (
                <p
                  style={{ fontSize: '14px' }}
                  className={`id-text-style ${
                    selectedLanguage == 'en' ? 'text-left' : 'text-right'
                  }`}
                >{`${t.workOrders.details_view.work_order_no} #${data?.attributes?.workOrder?.data?.attributes?.countId }`}</p>
              )}{' '}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSave = async () => {
    try {
      if (!message) {
        toast.error(t.workOrders.submit_error);
        return;
      }
      setLoading(true);
      let res = await updateWorkRequestStatus(data.id, 'declined', message);
      setModal(false);
      setMessage('');
      setLoading(false);
      if (res && res.status == 200) {
        untoggle();
        setFilterOptions({
          requestedBy: [],
          locations: [],
          assets: [],
          priority: [],
          status: [],
          dueDate: {
            value: 'any_day',
            text: 'Any Date',
          },
          priority: [],
          status: [],
        });

        setSearchTitle('');
        setSelectedDates([]);
        toast.error(
          t.workRequests.work_request +
            // ' ' +
            // `#${res?.data?.data?.id}` +
            ' ' +
            t.workRequests.form.decline_success
        );
        // toggleDeleteSuccessModal();
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.message);
      setModal(false);
      setMessage('');
      setLoading(false);
    }
  };

  const renderAssigneePeople = (assigneePeople) => {
    if (assigneePeople?.length > 0) {
      return assigneePeople.map((people, index) => {
        if (people?.attributes?.profileImage?.data?.attributes?.url) {
          return (
            <p>
              <Image
                key={index}
                avatar
                title={`${people.attributes.firstName} ${people.attributes.lastName}`}
                src={`${people?.attributes?.profileImage?.data?.attributes?.url}`}
                style={
                  index !== 0
                    ? selectedLanguage == "ar" ?  { right: '-6px', width: '34px', height: '34px' } :{ left: '-6px', width: '34px', height: '34px' }
                    : { width: '34px', height: '34px' }
                }
              />
              {checkForLinkPermission('people') ? (
                <span
                  onClick={() => history.push(`/people?id=${people.attributes?.countId}`)}
                  className="detail-intro-link ml-1 mr-1"
                >
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className=" ml-1 mr-1">
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        } else {
          return (
            <p>
              <UserProfilePlaceholder
                key={index}
                name={`${
                  people?.attributes?.firstName
                    ? people?.attributes?.firstName
                    : ''
                } ${
                  people?.attributes?.lastName
                    ? people?.attributes?.lastName
                    : ''
                }`}
                width={'34px'}
                height={'34px'}
                right={selectedLanguage == "en" ?  '6px' : "-5px"}
              />
              {checkForLinkPermission('people') ? (
                <span
                  onClick={() => history.push(`/people?id=${people.attributes?.countId}`)}
                  className="detail-intro-link"
                >
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className="">
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        }
      });
    } else {
      return t.common.na;
    }
  };

  const priorityOptions = [
    {
      value: 'none',
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: 'low',
      label: t.workOrders.priority_status.low,

      color: 'yellow',
    },
    {
      value: 'medium',
      label: t.workOrders.priority_status.medium,

      color: 'orange',
    },
    {
      value: 'high',
      label: t.workOrders.priority_status.high,

      color: 'red',
    },
  ];

  const WRstatusOptions = [
    {
      value: 'approved',
      color: 'green',
      label: t.workRequests.status.approved,
    },
    {
      value: 'pending',
      color: 'orange',
      label: t.workRequests.status.pending,
    },
    {
      value: 'declined',
      color: 'red',
      label: t.workRequests.status.declined,
    },
  ];

  const workOrderPriorityColor = priorityOptions.find(
    (pro) => pro.value == data?.attributes.priority
  );

  const workOrderStatus = WRstatusOptions.find(
    (status) => status.value == data?.attributes.status
  );

  return (
    <div className="preview-details-wrapper">
      <div className="intro">
        <div style={{ fontSize: '15px' }}>{confirmWorkRequest()}</div>
      </div>
      <div className="data-header mt-5">
        <div className="header-title d-flex justify-content-between align-items-start">
          <div className="header-pill-container d-flex align-items-baseline">
            <p className="detail-title" style={{ width: '350px' }}>
              {data?.attributes?.title}
            </p>
          </div>
          <div className="header-id d-flex flex-column mb-5">
            <p className={`workorder-intro-value mr-0 ml-0 d-flex gap-3`}>
              <div
                className={`item-value mt-3 ${
                  selectedLanguage == 'en' ? '' : 'ml-2'
                }`}
              >
                <Label
                  circular
                  empty
                  color={workOrderStatus?.color}
                  style={{ marginRight: '5px', width: '12px', height: '12px' }}
                  size="medium"
                />
              </div>
              <span
                style={selectedLanguage == 'en' ? {} : { fontSize: '13px' }}
                className="mt-3 "
              >
                {data?.attributes.status ? workOrderStatus?.label : t.common.na}
              </span>
            </p>
          </div>
        </div>

        <div>
          <SemanticImage
            src={
              data?.attributes.logo?.data?.attributes
                ? data.attributes.logo.data.attributes.url
                : toAbsoluteUrl('/media/default-img.png')
            }
            size="medium"
            className="data-header-image"
          />
        </div>

        <div className="header-title d-flex justify-content-between align-items-start pb-4">
          <div className="header-pill-container d-flex align-items-baseline">
            <label className="workorder-intro-label work-request-label">
              {t.workOrders.form.description}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {data?.attributes?.description
                ? data?.attributes?.description
                : t.common.na}
            </p>
          </div>
          <div className="header-id d-flex flex-column">
            <div className="">
              <p
                style={
                  selectedLanguage == 'en'
                    ? { fontSize: '12px' }
                    : { fontSize: '11px' }
                }
                className={`pill text-nowrap gap-0 ${data.attributes.priority}`}
              >
                <FlagWhiteIcon />
                {workOrderPriorityColor.label +
                  ' ' +
                  t.workOrders.table.priority}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="details-wrapper work-request mt-0">
        <div className="d-flex align-items-start">
          <label className="workorder-intro-label work-request-label">
            {t.workRequests.form.location}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.location?.data ? (
              <React.Fragment>
                {checkForLinkPermission('location') ? (
                  <span
                    className={`detail-intro-link`}
                    basic
                    onClick={() =>
                      history.push(
                        `/locations?id=${data?.attributes?.location?.data?.attributes?.countId}`
                      )
                    }
                  >
                    {data?.attributes?.location?.data?.attributes?.name}
                  </span>
                ) : (
                  <span className=" ml-1 mr-1">
                    {data?.attributes?.location?.data?.attributes?.name}
                  </span>
                )}
              </React.Fragment>
            ) : (
              t.common.na
            )}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-request-label">
            {t.workRequests.form.assets}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.assets?.data?.length
              ? data.attributes.assets.data.map((asset, index) => (
                  <React.Fragment key={index}>
                    {checkForLinkPermission('asset') ? (
                      <p
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == 'en' ? 'text-left' : 'text-right'
                        }`}
                        basic
                        onClick={() => history.push(`/assets?id=${asset?.attributes?.countId}`)}
                      >
                        {asset.attributes.name}
                      </p>
                    ) : (
                      <p className={`ml-1 mr-1`} basic>
                        {asset.attributes.name}
                      </p>
                    )}
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-request-label">
            {t.workRequests.form.people}
            {':'}
          </label>
          <p
            className="workorder-intro-value"
            style={
              data?.attributes?.people?.data.length > 0
                ? { marginTop: '-6px' }
                : {}
            }
          >
            {renderAssigneePeople(data?.attributes?.people?.data)}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-request-label">
            {t.workRequests.form.teams}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.teams?.data?.length
              ? data.attributes.teams.data.map((team, index) => (
                  <React.Fragment key={index}>
                    {checkForLinkPermission('team') ? (
                      <p
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == 'en' ? 'text-left' : 'text-right'
                        }`}
                        basic
                        onClick={() => history.push(`/teams?id=${team.attributes?.countId}`)}
                      >
                        {team.attributes.name}
                      </p>
                    ) : (
                      <p className={'ml-1 mr-1'} basic>
                        {team.attributes.name}
                      </p>
                    )}
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-request-label">
            {t.workOrders.form.file}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.attachments?.data?.length
              ? data.attributes.attachments.data.map((attachment, index) => (
                  <React.Fragment key={index}>
                    <p
                      className={`detail-intro-link mb-1 ${
                        selectedLanguage == 'en' ? 'text-left' : 'text-right'
                      }`}
                      basic
                      onClick={() => {
                        window.open(attachment?.attributes?.url, '_blank');
                      }}
                    >
                      {attachment?.attributes?.name}
                    </p>
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
        </div>

        <Divider />

        <div className="d-flex align-items-center mt-5">
          <label className="workorder-intro-label work-request-label grey">
            {t.workOrders.table.createdByUser}
            {':'}
          </label>
          <p className="workorder-intro-value set-width grey">
            {data?.attributes?.submittedBy?.data?.attributes?.firstName &&
            data?.attributes?.submittedBy?.data?.attributes?.lastName
              ? data?.attributes?.submittedBy?.data?.attributes?.firstName +
                ' ' +
                data?.attributes?.submittedBy?.data?.attributes?.lastName
              : t.common.na}
            {', '}
            <Moment format="DD-MM-YYYY LT" date={data?.attributes?.createdAt} />
          </p>
        </div>

        <div className="d-flex align-items-center mt-3">
          <label className="workorder-intro-label work-request-label grey">
            {t.common.update_by}
            {':'}
          </label>
          <p className="workorder-intro-value  set-width  grey">
            {data?.attributes?.updatedByUser?.data
              ? data?.attributes?.updatedByUser?.data?.attributes?.firstName +
                ' ' +
                data?.attributes?.updatedByUser?.data?.attributes?.lastName +
                ', ' +
                moment(data?.attributes?.updatedAt).format('DD-MM-YYYY LT')
              : t.common.na}
          </p>
        </div>
      </div>
      {data?.attributes?.status === 'pending' && toggleAsideModal ? (
        <Add
          toggled={toggleAsideModal}
          untoggle={() => {
            setToggleAsideModal(false);
          }}
          allIds = {  currentUserId}
          roleData={role}
          currentUserId={userId}
          setmodalPrevWidth = {setmodalWidth}
          modalPrevWidth = {modalWidth}
          fromWR={true}
          WRFile={
            data?.attributes?.logo?.data
              ? {
                  id: data?.attributes?.logo?.data?.id,
                  file: urlToFile(
                    data?.attributes?.logo?.data?.attributes?.url,
                    data?.attributes?.logo?.data?.attributes?.name
                  ),
                  name: data?.attributes?.logo?.data?.attributes?.name,
                  type: data?.attributes?.logo?.data?.attributes?.mime,
                  cancelPopup: false,
                  isChanged: true,
                }
              : null
          }
          setRefresh={setRefresh}
          WRstatus={data?.attributes?.status}
          refresh={refresh}
          setloader={setloader}
          loader={loader}
          WRtitle={data?.attributes?.title}
          WRdescription={data?.attributes?.description}
          WRpriority={
            data?.attributes?.priority == 'none'
              ? ''
              : data?.attributes?.priority
          }
          WRasset={
            data?.attributes?.assets?.data?.length > 0
              ? data.attributes.assets.data.map((el_asset) => el_asset.id)
              : []
          }
          WRlocation={
            data?.attributes?.location?.data?.id
              ? data?.attributes?.location?.data?.id
              : ''
          }
          setWRdata={setData}
          WRordertype="reactive"
          WRID={data?.id}
          handleSuccess={(res) => {
            toast.success(
              t.workRequests.work_request +
                // ' ' +
                // `#${data?.id}` +
                ' ' +
                t.workRequests.form.approved_success
            );
            setFilterOptions({
              requestedBy: [],
              locations: [],
              assets: [],
              priority: [],
              status: [],
              dueDate: {
                value: 'any_day',
                text: 'Any Date',
              },
              priority: [],
              status: [],
            });

            setSearchTitle('');
            setSelectedDates([]);

            history.push(`/work-requests?id=${data?.attributes?.countId}`);
          }}
          // WRlocation={data?.attributes?.description}
        />
      ) : null}

      <Modal
        className="assets-modal"
        size="tiny"
        open={openModal}
        onClose={() => {
          setModal(false);
          setMessage('');
        }}
      >
        <div className={'header-container red'}>
          <span className="assets-header">
            {t.workRequests.form.decline_wr}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setModal(false);
                setMessage('');
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          {/* <div>
            <p className="status-modal-popup-message">
              Are you sure want to{' '}
              {inProgressFlag
                ? 'put in progress'
                : !onHoldFlag
                ? approveModal
                  ? 'appove'
                  : 'decline'
                : 'put on hold'}{' '}
              this work order?
            </p>
            <div>
              <Form.Field>
                <TextArea
                  className="modal-text-area"
                  // labelPosition="right"
                  // type="textarea"
                  // fluid
                  rows={5}
                  placeholder={`Add reason ${onHoldFlag ? '(required)' : ''}`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></TextArea>
              </Form.Field>
            </div>
          </div> */}

          <div className="comment-container" style={{ marginLeft: '3px' }}>
            <label className="comment-label">
              {' '}
              {/* {t.workOrders.are_you_sure}{' '} */}
              {t.workRequests.form.decline_wr_statement}{' '}
              {/* {t.workOrders.this_WO} */}
            </label>
            <div className="add-comment">
              <TextArea
                className="comment-textarea"
                rows={5}
                placeholder={t.workOrders.required}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div
            className={`modal-button-container ${
              selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
            }`}
          >
            <button
              className="modal-form-action-button"
              onClick={() => {
                setModal(false);
                setMessage('');
              }}
              disabled={loading}
              style={{ color: '#172B4D', fontWeight: '500' }}
            >
              {t.assets.form.cancel}
            </button>

            <button
              onClick={() => {
                handleSave();
                // disabled = { loading };
              }}
              disabled={loading}
              style={{ fontWeight: '500' }}
              className={`modal-form-action-button light-blue`}
            >
              {t.workOrders.details_view.decline}
            </button>
          </div>
          {/* <Button
            color={approveModal ? 'green' : 'red'}
            onClick={() => {
              handleSave();
            }}
            disabled={loading}
          >
            {inProgressFlag
              ? 'In-Progress'
              : !onHoldFlag
              ? approveModal
                ? 'Approve'
                : 'Decline'
              : 'On-Hold'}
          </Button> */}

          {/* <Button
            color="black"
            onClick={() => setModal(false)}
            disabled={loading}
          >
            Cancel
          </Button> */}
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default Details;
