import { filter } from 'lodash';
import React, { useEffect, useState, createRef } from 'react';
import _ from 'lodash';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
  Image,
} from 'semantic-ui-react';

import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import DatePicker from 'react-multi-date-picker';
import calendar_icon from '../../../Assets/Icons/calender-icon.svg';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import {
  CalenderIcon,
  DownChevron,
  DownloadIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  UploadIcon,
  WhiteDownChevron,
  CategoryDamage,
  CategoryElectrical,
  CategoryInspection,
  CategoryMechanical,
  CategoryOperation,
  CategoryProject,
  CategoryRefrigeration,
  CategorySafety,
  CatgoryPreventive,
  Damage,
  Project,
  OperationProcedure,
  Preventive,
  CorrectiveIcon,
  PlannedIcon,
  UnPlannedIcon,
} from '../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import moment from 'moment';
import {
  getActiveUsersByRole,
  IMAGES_URL,
  toggle,
} from '../../config/functions';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import SearchDropdown from '../../components/common/SearchDropdown';
import {
  fetchAllprocedureData,
  fetchProcedureWithFilter,
} from '../../../redux/reducers/procedureReducer';
import { toast } from 'react-toastify';

const Filters = ({
  data,
  setDataByFilter,
  hasStatus,
  fileData = [],
  setFilterOptions,
  filterOptions,
  setSearchTitle,
  searchTitle,
  debounceValue,
  selectedDates,
  setSelectedDates,
  setSortData,
  sortData,
  setloader,
  loader
}) => {
  const t = useSelector(selectTranslations);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const dispatch = useDispatch();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [openCreatedBy, setOpenCreatedBy] = useState(false);
  const [filterSearch, setFilterSearch] = useState({
    location: '',
    assignee: '',
    createdByUser: '',
  });

  const [openCategory, setOpenCategory] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState('');
  const [csvFileData, setCsvFileData] = useState([]);
  const [loadData, setLoadData] = useState(false);


  const headers = [
    { label: t.procedures.table.ID, key: 'id' },
    { label: t.procedures.table.title, key: 'title' },
    { label: t.procedures.form.description, key: 'description' },
    { label: t.procedures.details_view.category, key: 'category' },
    { label: t.procedures.form.procedure_items, key: 'fields' },
    { label: t.procedures.details_view.assets, key: 'assets' },
    { label: t.procedures.details_view.locations, key: 'locations' },
    { label: t.procedures.details_view.parts, key: 'parts' },
    { label: t.procedures.table.createdByUser, key: 'createdByUser' },
    { label: t.procedures.table.createdAt, key: 'createdAt' },
    { label: t.procedures.table.updatedAt, key: 'updatedAt' },
  ];

  const handleCategoryDropdown = () => {
    setCategoryDropdown(!categoryDropdown);
  };

  const [filtersTrigger, setFiltersTrigger] = useState({
    createdByUser: { options: [] },
    category: {
      options: [
        {
          key: 0,
          value: 'damage',
          text: t.workOrders.form.category_options[0],
          icon:    <Damage />
        },
        {
          key: 2,
          value: 'corrective action Downtime',
          text: t.workOrders.form.category_options[1],
          icon:   <CorrectiveIcon/>
        },
        {
          key: 4,
          value: 'planned Downtime',
          text: t.workOrders.form.category_options[2],
          icon:   <PlannedIcon/>
        },
        {
          key: 10,
          value: 'unplanned Downtime',
          text: t.workOrders.form.category_options[3],
          icon:  <UnPlannedIcon/>
        },
        {
          key: 11,
          value: 'inspection',
          text: t.workOrders.form.category_options[4],
          icon: <CategoryInspection />,
        },
        {
          key: 1,
          value: 'electrical',
          text: t.workOrders.form.category_options[5],
          icon: <CategoryElectrical />,
        },

        {
          key: 3,
          value: 'mechanical',
          text: t.workOrders.form.category_options[6],
          icon: <CategoryMechanical />,
        },

        {
          key: 6,
          value: 'HVAC',
          text: t.workOrders.work_order_category.HVAC,
          icon: <CategoryRefrigeration />,
        },
        {
          key: 16,
          value: 'preventive maintenance',
          text: t.workOrders.form.category_options[8],
          icon:  <CatgoryPreventive />,
        },
        {
          key: 17,
          value: 'project',
          text: t.workOrders.form.category_options[9],
          icon:    <Project />,
        },
        {
          key: 7,
          value: 'safety',
          text: t.workOrders.form.category_options[10],
          icon: <CategorySafety />,
        },
        {
          key: 27,
          value: 'sop',
          text: t.workOrders.form.category_options[11],
          icon: <OperationProcedure />,
        },
      ],
    },
  });

  const [newData, setNewData] = useState(data);

  const closeAllFilters = () => {
    setOpenCategory(false);
  };

  let _newOrders = newData;
  useEffect(() => {
    // if (filterOptions.category.length)
    //   _newOrders = _newOrders.filter((el) => {
    //     return filterOptions.category.includes(el.attributes.category);
    //   });
    // if (filterOptions.createdByUser.length > 0)
    //   _newOrders = _newOrders.filter((el) => {
    //     return filterOptions.createdByUser.includes(
    //       el.attributes.createdByUser?.data?.id
    //     );
    //   });

    // if (selectedDates.length == 2) {
    //   if (selectedDates[0] == selectedDates[1]) {
    //     _newOrders = _newOrders.filter((el) => {
    //       var toDate = moment(selectedDates[1]).format('YYYY-MM-DD') || '';
    //       var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';
    //       var createdAt =
    //         moment(el.attributes.createdAt).format('YYYY-MM-DD') || '';

    //       return createdAt >= fromDate && createdAt <= toDate;
    //     });

    //   } else {
    //     _newOrders = _newOrders.filter((el) => {
    //       var toDate =
    //         moment(selectedDates[1])
    //           .add(1, 'days')
    //           .format('YYYY-MM-DD') || '';
    //       var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';
    //       var createdAt =
    //         moment(el.attributes.createdAt).format('YYYY-MM-DD') || '';

    //       return createdAt >= fromDate && createdAt <= toDate;
    //     });

    //   }
    // } else if (selectedDates.length == 1) {
    //   _newOrders = _newOrders.filter((el) => {
    //     var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';

    //     return el.attributes.createdAt >= fromDate;
    //   });
    // }

    // if (searchTitle.length > 0) {
    //   const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
    //   const isMatch = (result) => {
    //     if (
    //       re.test(result.attributes.title) ||
    //       re.test(result.id) ||
    //       re.test(result.attributes.description) ||
    //       re.test(result.attributes.category)
    //     ) {
    //       return true;
    //     } else if (result.attributes?.customer?.data?.attributes?.name) {
    //       return re.test(result.attributes?.customer?.data?.attributes?.name);
    //     } else if (result.attributes?.createdByUser?.data?.attributes?.name) {
    //       return re.test(
    //         result.attributes?.createdByUser?.data?.attributes?.name
    //       );
    //     }
    //   };

    //   _newOrders =
    //     searchTitle.length > 0 ? _.filter(_newOrders, isMatch) : _newOrders;
    // }

    (async function() {
      setloader(true);

      await  dispatch(
        fetchProcedureWithFilter({
          isGlobalTemplate: false,
          organisationId,
          searchName: debounceValue,
          page: '1',
          pageSize: '25',
          category: filterOptions.category,
          createdby: filterOptions.createdByUser,
          createdat:
            selectedDates.length == 1 ? [selectedDates[0]] : selectedDates,
            sort:sortData
        })
      );

      setloader(false);
    })();

  

    document.body.addEventListener('click', closeAllFilters);
  }, [debounceValue, filterOptions, selectedDates]);

  useEffect(() => {
    fetchPeopleOptions();
  }, []);

  const downloadXLS = async () => {

    const arraysNotEmpty = Object.values(filterOptions).some(
      (option) => Array.isArray(option) && option.length > 0
    );

    if ( !arraysNotEmpty && searchTitle == '' && selectedDates.length == 0) {
       let mydata = await dispatch(fetchAllprocedureData(organisationId));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }

    let csvData = [];

    // let mydata = await dispatch(fetchAllprocedureData(organisationId));
    // if (mydata.payload.response) {
    //   fileData = mydata.payload.response;
    // } else {
    //   toast.error(t.common.something_wrong);
    // }

    const sortedCsvData = fileData.sort((a, b) => {
      const startDate = moment(a.attributes.createdAt).format('DD-MM-YYYY LT');
      const endDate = moment(b.attributes.createdAt).format('DD-MM-YYYY LT');

      const start = moment(startDate, 'DD-MM-YYYY h:mm A').startOf('day');
      const end = moment(endDate, 'DD-MM-YYYY h:mm A').startOf('day');

      const dateDifferenceInDays = end.diff(start, 'days');
      if (dateDifferenceInDays == 0) {
        return a.attributes.category.localeCompare(b.attributes.category);
      }
      return dateDifferenceInDays;
    });

    for (let i = 0; i < sortedCsvData.length; i++) {
      let procedure_data = [];

      procedure_data.id = sortedCsvData[i].id;
      procedure_data.title = sortedCsvData[i].attributes.title || t.common.na;
      procedure_data.description =
        sortedCsvData[i].attributes.description || t.common.na;

      const selectedCategory = filtersTrigger.category.options.find(
        (one) => one.value === sortedCsvData[i].attributes.category
      );

      procedure_data.category = selectedCategory.text || t.common.na;
      procedure_data.fields = sortedCsvData[i].attributes.fields.length || 0;

      let assetsData = sortedCsvData[i].attributes?.assets?.data;
      let assetsString = '';

      if (assetsData?.length > 0) {
        assetsString = assetsData
          .map((people) => people.attributes?.name)
          .join(', ');
      } else {
        assetsString = t.common.na;
      }

      procedure_data.assets = assetsString;

      let locationData = sortedCsvData[i].attributes?.locations?.data;
      let locationString = '';

      if (locationData?.length > 0) {
        locationString = locationData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        locationString = t.common.na;
      }

      procedure_data.locations = locationString;

      let partsData = sortedCsvData[i].attributes?.parts?.data;
      let partsString = '';

      if (partsData?.length > 0) {
        partsString = partsData.map((each) => each.attributes?.name).join(', ');
      } else {
        partsString = t.common.na;
      }

      procedure_data.parts = partsString;

      procedure_data.createdByUser =
        sortedCsvData[i]?.attributes?.createdByUser?.data?.attributes
          ?.firstName || t.common.na;
      procedure_data.createdAt =
        moment(sortedCsvData[i].attributes.createdAt).format('DD-MM-YYYY LT') ||
        t.common.na;
      procedure_data.updatedAt =
        moment(sortedCsvData[i].attributes.updatedAt).format('DD-MM-YYYY LT') ||
        t.common.na;

      csvData.push([
        procedure_data.id,
        procedure_data.title,
        procedure_data.description,
        procedure_data.category,
        procedure_data.fields,
        procedure_data.assets,
        procedure_data.locations,
        procedure_data.parts,
        procedure_data.createdByUser,
        procedure_data.createdAt,
        procedure_data.updatedAt,
      ]);
    }

    // const sortedCsvData = csvData.sort((a, b) => {
    //   const startDate = moment(a.createdAt, 'DD-MM-YYYY h:mm A');
    //   const endDate = moment(b.createdAt, 'DD-MM-YYYY h:mm A');

    //   const dateDifferenceInMinutes = endDate.diff(startDate, 'days');
    //   if (dateDifferenceInMinutes == 0) {
    //     return a.category.localeCompare(b.category);
    //   }
    //   return dateDifferenceInMinutes;
    // });

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 20 }, // Created At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(headers, csvData, csvColumnWidths, t.procedures.procedures);
  };

  const generatePDF = async () => {
    let mydata = await dispatch(fetchAllprocedureData(organisationId));
    let csvData = [];
    if (mydata.payload.response) {
      fileData = mydata.payload.response;
    } else {
      toast.error('something went wrong');
    }
    for (let i = 0; i < fileData.length; i++) {
      let procedure_data = {};

      procedure_data.id = fileData[i].id;
      procedure_data.title = fileData[i].attributes.title || t.common.na;
      procedure_data.description =
        fileData[i].attributes.description || t.common.na;

      const selectedCategory = filtersTrigger.category.options.find(
        (one) => one.value === fileData[i].attributes.category
      );

      procedure_data.category = selectedCategory.text || t.common.na;
      procedure_data.fields = fileData[i].attributes.fields.length || 0;

      let assetsData = fileData[i].attributes?.assets?.data;
      let assetsString = '';

      if (assetsData?.length > 0) {
        assetsString = assetsData
          .map((people) => people.attributes?.name)
          .join(', ');
      } else {
        assetsString = t.common.na;
      }

      procedure_data.assets = assetsString;

      let locationData = fileData[i].attributes?.locations?.data;
      let locationString = '';

      if (locationData?.length > 0) {
        locationString = locationData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        locationString = t.common.na;
      }

      procedure_data.locations = locationString;

      let partsData = fileData[i].attributes?.parts?.data;
      let partsString = '';

      if (partsData?.length > 0) {
        partsString = partsData.map((each) => each.attributes?.name).join(', ');
      } else {
        partsString = t.common.na;
      }

      procedure_data.parts = partsString;

      procedure_data.createdByUser =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;
      procedure_data.createdAt =
        moment(fileData[i].attributes.createdAt).format('DD-MM-YYYY LT') ||
        t.common.na;
      procedure_data.updatedAt =
        moment(fileData[i].attributes.updatedAt).format('DD-MM-YYYY LT') ||
        t.common.na;

      csvData.push(procedure_data);
    }

    const sortedCsvData = csvData.sort((a, b) => {
      const startDate = moment(a.createdAt, 'DD-MM-YYYY h:mm A').startOf('day');
      const endDate = moment(b.createdAt, 'DD-MM-YYYY h:mm A').startOf('day');

      const dateDifferenceInDays = endDate.diff(startDate, 'days');
      if (dateDifferenceInDays == 0) {
        return a.category.localeCompare(b.category);
      }
      return dateDifferenceInDays;
    });

    const columnWidths = [, , , , , , , , , 22, 22];

    DownloadPdfFileWithData(
      headers,
      sortedCsvData,
      t.procedures.procedures,
      columnWidths,
      'A3'
    );
  };

  // Use sortedCsvData in your further processing

  const handleSearchCreatedByChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      createdByUser: e.target.value,
    });
  };

  const handleDeselect = () => {
    // Deselect by setting an empty array
    setSelectedDates([]);
  };

  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ''
      );
      setSelectedDates(isoDates);
    } else {
      setSelectedDates([]);
    }
  };

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.createdByUser), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      createdByUser: {
        ...filtersTrigger.createdByUser,
        options:
          filterSearch.createdByUser.length > 0
            ? _.filter(filtersTrigger.createdByUser.initOptions, isMatch)
            : filtersTrigger.createdByUser.initOptions,
      },
    });
  }, [filterSearch.createdByUser]);

  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ['user', 'supervisor', 'view_only', 'admin', 'requester', 'super_admin'],
      organisationId
    );

    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName + ' ' + el.lastName,
          image: {
            avatar: true,
            // src: `http://gravatar.com/avatar/${md5(
            //   el.firstName + ' ' + el.lastName
            // )}?d=identicon`,
            src: el?.profileImage?.url
              ? `${IMAGES_URL}${el?.profileImage?.url}`
              : LetteredAvatarImageUrl(`${el.firstName} ${el.lastName}`),
            style: { width: '20px', height: '20px' },
          },
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        assignees: {
          teams: {
            initOptions: [],
            options: [],
          },
          people: {
            initOptions: the_options,
            options: the_options,
          },
        },
        createdByUser: {
          initOptions: the_options,
          options: the_options,
        },
      });
    }
  };

  return (
    <>
      <div className="filters">
        <div className="text-right mb-5 pb-5 d-flex justify-content-end align-items-center views">
          {/* <div className="list-view-option">
            <p className="single-view">
              <NavLink to="/procedures">{t.procedures.add}</NavLink>
            </p>
            <p className="single-view">
            {t.procedures.global}
              <NavLink to="/procedures">Global View</NavLink>
            </p>
          </div> */}

          <div className="table-searchbar ">
            <SearchIcon />
            <Input
              icon={{ name: 'search' }}
              placeholder={t.procedures.filter.search}
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="filters-wrapper">
        {/* <div className="table-searchbar">
          <SearchIcon />
          <Input
            icon={{ name: 'search' }}
            placeholder={t.procedures.filter.search}
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
          />
        </div> */}

        <div className="flexbox table-right-dropdown">
          <CategoryDropdown
            title={t.procedures.details_view.category}
            options={filtersTrigger.category.options}
            selectedValues={filterOptions.category}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  category: [...filterOptions.category, e.target.value],
                });
              } else {
                let filteredCategory = filterOptions.category.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  category: filteredCategory,
                });
              }
            }}
          />
          <SearchDropdown
            title={t.procedures.table.createdByUser}
            options={filtersTrigger.createdByUser.options}
            selectedValues={filterOptions.createdByUser}
            searchValue={filterSearch.createdByUser}
            handleSearchValue={handleSearchCreatedByChange}
            onClick={(value) => {
              const newArray = toggle(filterOptions.createdByUser, value.value);

              setFilterOptions({
                ...filterOptions,
                createdByUser: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                createdByUser: '',
              });
            }}
          />

          <div className="wo-date-picker">
            <DatePicker
              style={
                selectedDates.length <= 1
                  ? { width: '100px' }
                  : { width: '160px' }
              }
              value={selectedDates}
              onChange={handleChange}
              range
              showMonthDropdown
              showYearDropdown
              placeholder={t.procedures.table.createdAt}
              className={'active'}
              // locale={lang == 'ar' ? arabic_ar : ''}
              weekDays={[
                t.workOrders.details_view.sun,
                t.workOrders.details_view.mon,
                t.workOrders.details_view.tue,
                t.workOrders.details_view.wed,
                t.workOrders.details_view.thu,
                t.workOrders.details_view.fri,
                t.workOrders.details_view.sat,
              ]}
              months={[
                t.common.january,
                t.common.february,
                t.common.march,
                t.common.april,
                t.common.may,
                t.common.june,
                t.common.july,
                t.common.august,
                t.common.september,
                t.common.october,
                t.common.november,
                t.common.december,
              ]}
              format="DD/MM/YY"
            >
              <button onClick={handleDeselect} className="btn-datepicker blue">
                {t.common.reset}
              </button>
            </DatePicker>
            {/* <img className="date-picker-image procedure" src={calendar_icon} /> */}
          </div>
        </div>
        <div className="custom-menu-dropdown">
          <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle color="primary" className="option-toggle">
              {t.common.page_action}
              <WhiteDownChevron />{' '}
            </DropdownToggle>
            <DropdownMenu className={selectedLanguage == 'ar' ? 'rtl' : ''}>
              {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon />
                    {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}

              <DropdownItem className="divider" />
              <DropdownItem onClick={() => downloadXLS()}>
                <DownloadIcon /> {t.common.download_sheet}
              </DropdownItem>
              {/* <DropdownItem className="divider" />
                <DropdownItem onClick={() => generatePDF()}>
                  <PdfIcon /> {t.common.download_pdf}
                </DropdownItem> */}
              {/* <DropdownItem>
                <UploadIcon /> Upload
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </>
  );
};

export default Filters;
