import React, { useEffect, useState, createRef } from 'react';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Icon,
  Placeholder,
} from 'semantic-ui-react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

import { getParentBySubLoc, GMAPAPIKEY, IMAGES_URL } from '../../config/functions';
import CustomIcon from '../../../Assets/Icons/marker.svg';

import { NavLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';

import { getAllParentLocations } from '../../config/functions';

import Add from './add';
import Edit from './edit';

import Preview from './preview/index';

import Filters from './filters';
import DateConvert from '../../../utilFunctions/DateFormat';
import moment from 'moment';
import useGetCurrentTimeAccZone from '../../../hooks/useGetCurrentTimeAccZone';
import { LocationIcon, ProcedurePlusIcon } from '../../../Assets/Icons/svg';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import { fetchLocationsInitData } from '../../../redux/reducers/locationReducer';
import { useDebounce } from 'use-debounce';
import Animation from '../../components/common/CustomLoader';

const MapViewLocations = ({ google }) => {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 32.0503808,
    lng: 35.8776832,
  });
  const t = useSelector(selectTranslations);
  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const [parentIds, setParentIds] = useState([]);
  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      location.subLocations.forEach((subLocation) => {
        if (!allIds.some((inv) => inv == subLocation?.id)) {
          allIds.push(subLocation?.id);
        }
      });
    });
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();
  const { userPermissions } = usePermissionsSimplified();
  const [fetching, setFetchLoading] = useState(true);
  const dispatch = useDispatch();

  // const [initData, setInitData] = useState([]);
  // const [data, setData] = useState([]);
  const [targetedData, setTargetedData] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.location.allLocationData,
    isLoading: state.location.isLoading,
    error: state.location.error,
    initData: state.location.locationInitData,
    page: state.location.page,
    pageSize: state.location.pageSize,
    total: state.location.total,
    initLoading: state.location.initLoading,
  }));

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );


  const [filterOptions, setFilterOptions] = useState({
    customers: [],
    countries: [],
    cities: [],
    createdByUser: [],
    createdAt: {
      value: 0,
      text: t.locations.filter.createdAt,
    },
    priority: [],
    status: [],
  });

  const [searchTitle, setSearchTitle] = useState('');
  const [loader, setloader] = useState(false);
  const [debounceValue] = useDebounce(searchTitle, 500);

  useEffect(() => {
    const fetchData = async () => {
      if (!(role === "super_admin" || role === "admin")) {
       
        // const userData = await getUserData(user.id);
        // dispatch(props.fulfillUser(userData));

        const Ids= await getParentBySubLoc();
        setParentIds(Ids)
      }
      const fetchData = await dispatch(fetchLocationsInitData({organisationId,role,allIds:parentIds,currentUserId}));
      // console.log('DASSDASD', fetchData);
      if (fetchData) {
        // setData(fetchData.response);
        // setInitData(fetchData.response);
        setFetchLoading(false);
      }
    };

    fetchData();
  }, [refresh]);

  const style = {
    width: '100%',
    height: '100%',
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '800px',
  };

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };

  return (
    <>
    <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {' '}
            <LocationIcon />
            {t.locations.title}
          </Header>
          <div></div>

          {userPermissions?.procedure?.create ? (
            <Button
              icon="add"
              primary
              className={selectedLanguage == "en" ? "mr-7" : "ml-7"}
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.locations.create_location}
              {/* <ProcedurePlusIcon />  */}
            </Button>
          ) : null}
        </div>
        {!initLoading ? (
          <div className="new-listing-filter wo-filter sticky-filter mt-8">
             <Filters
                  data={initData}
                  setDataByFilter={handleSetDataByFilter}
                  hasStatus={false}
                  fileData={data}
                  setFilterOptions={setFilterOptions}
                  filterOptions={filterOptions}
                  setloader={setloader}
                  loader={loader}
                  role={role}
                  allIds={allIds}
                  parentIds={parentIds}
                  currentUserId={currentUserId}
                 
                 
                  setSearchTitle={setSearchTitle}
                  searchTitle={searchTitle}
                  debounceValue={debounceValue}
              />
          </div>
        ) : null}
      </div>
    <div className="new-listing-container">
    
      {/* <Header as="h2">{t.locations.location_map_view}</Header>
      <div className="header-actions">
        <Button.Group basic>
          <NavLink to="/locations">
            <Button>
              <Icon name="list ul" color="black" />
              {t.locations.tabs.first}
            </Button>
          </NavLink>

          <NavLink to="/locations-map-view">
            <Button>
              <Icon name="map outline" color="black" />
              {t.locations.tabs.second}
            </Button>
          </NavLink>
        </Button.Group>
        <Button
          icon="add"
          content={t.locations.add}
          primary
          onClick={() => {
            setToggleAsideModal(true);
          }}
        />
      </div> */}
     { initLoading? (
       <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
       <Animation/>
       {/* <Placeholder fluid>
         <Placeholder.Line />
         <Placeholder.Line />
         <Placeholder.Line />
         <Placeholder.Line />
         <Placeholder.Line />
       </Placeholder> */}
     </div>
        // <div>
        // <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
      ) : (
        <div>
          <div className="new-listing-filter">
            <>
            
              <Map
                google={google}
                style={style}
                containerStyle={containerStyle}
                center={defaultCenter}
                initialCenter={defaultCenter}
                zoom={12}
              >
                {data.map((el) => {
                  return (
                    <Marker
                      onClick={() => {
                        setTargetedData(el);
                        setTogglePreviw(true);
                      }}
                      position={{
                        lat: el?.attributes?.mainLocation?.lat,
                        lng: el?.attributes?.mainLocation?.lng,
                      }}
                      draggable={false}
                      name={el.attributes.customer?.data?.attributes.name}
                      // icon={{
                      //   url: CustomIcon,
                      //   scaledSize: new google.maps.Size(50, 50),
                      // }}
                      icon={
                        el.attributes.customer?.data?.attributes.logo.data
                          ? {
                              url:
                                IMAGES_URL +
                                el.attributes.customer?.data?.attributes.logo
                                  .data.attributes.url,
                              //   anchor: new google.maps.Point(5, 100),
                              scaledSize: new google.maps.Size(50, 50),
                            }
                          : {
                              url: CustomIcon,
                              scaledSize: new google.maps.Size(50, 50),
                            }
                      }
                    />
                  );
                })}
              </Map>
            </>
          </div>
        </div>
      )}

      <Add
        toggled={toggleAsideModal}
        untoggle={() => {
          setToggleAsideModal(false);
        }}
        setRefresh={setRefresh}
        refresh={refresh}
        allIds={allIds}
        parentIds={parentIds}
        role={role}
        currentUserId={currentUserId}
      />

      <Edit
        targetedData={targetedData}
        toggled={toggleEditModal}
        refresh={refresh}
        setRefresh={setRefresh}
        allIds={allIds}
        parentIds={parentIds}
        role={role}
        currentUserId={currentUserId}
        untoggle={() => {
          
          setToggleEditModal(false);
        }}
      />

      <Preview
        targetedData={targetedData}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        toggled={togglePreview}
        refresh = {refresh}
        setRefresh = {setRefresh}
        edit={() => {
          setTogglePreviw(false);
          setToggleEditModal(true);
        }}
        untoggle={() => {
          setTargetedData();
          setTogglePreviw(false);
        }}
      />
    </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: GMAPAPIKEY,
})(MapViewLocations);
