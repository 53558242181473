import React, { createRef, useState } from "react";
import Moment from "react-moment";

import {
  Divider,
  Grid,
  Label,
  Image,
  Image as SemanticImage,
  Segment,
  Icon,
  Button,
  Modal,
  Header,
  Form,
  Input,
  TextArea,
  Dropdown,
  GridColumn,
} from "semantic-ui-react";

import {
  getActiveUsersByRole,
  getAllTeams,
  humanize,
  IMAGES_URL,
  updateWorkOrderAssign,
} from "../../../config/functions";
import { updateWorkOrderStatus } from "../../../config/functions";
import md5 from "md5";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../../components/common/userProfilePlaceholder";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import {
  Close,
  CloseIcon,
  Fail,
  FileDownloadIcon,
  FlagWhiteIcon,
  Printer,
  Success,
} from "../../../../Assets/Icons/svg";

import ReactToPrint from "react-to-print";
import QRComponentToPrint from "../../Locations/preview/qrCodeComponent";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Tooltip from "react-power-tooltip";
import Asterisk from "../../../components/common/Asterisk";
import { scrollToError } from "../../../../utilFunctions/scrollToError";
import moment from "moment";
import { format } from "date-fns";

const Details = ({
  data,
  setData,
  negative,
  untoggle,
  setRefresh,
  refresh,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  setModalWidth,
}) => {
  const t = useSelector(selectTranslations);
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const [options, setOptions] = useState({
    people: [],
    teams: [],
  });

  const history = useHistory();
  const [openModal, setModal] = useState(false);
  const qrRef = createRef();
  const [approveModal, setApproveModal] = useState(false);
  const [onHoldFlag, setOnHoldFlag] = useState(false);
  const [inProgressFlag, setInProgressFlag] = useState(false);
  const [inCancleFlag, setInCancleFlag] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [showPrintTooltip, setPrintTooltip] = useState(false);
  const [showDownloadTooltip, setDownloadTooltip] = useState(false);
  const [assignData, setAssignData] = useState({
    people: [],
    teams: [],
  });

  const [validation, setValidation] = useState({
    // customer: {
    //   error: false,
    //   pattern: (customer) => customer != '',
    //   skipped: false,
    //   message: 'Field is required',
    // },

    people: {
      error: false,
      pattern: (code) => code != "",
      skipped: false,
      message: t.common.required_field,
    },
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(roleData === "super_admin" || roleData === "admin")
        ? data?.attributes?.location?.data?.id
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };

  const onValuesChange = (name, actualValue) => (event, value) => {
    let currentFieldValue = "";
    const dropdown_fields = ["people", "teams"];

    if (dropdown_fields.includes(name)) {
      currentFieldValue = actualValue || value.value;
      let resulteNewData = {
        ...assignData,
        [name]: actualValue || value.value,
      };

      setAssignData(resulteNewData);
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  function formatMillisecondsToHHMM(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000); // Convert to seconds
    const hours = Math.floor(totalSeconds / 3600); // Calculate hours
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate remaining minutes
    const seconds = totalSeconds % 60; // Calculate remaining seconds

    // Format with leading zeros
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} Hr`;
  }

  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(roleData === "super_admin" || roleData === "admin")
        ? data?.attributes?.location?.data?.id
        : []
    );
    // console.log("THE FETCH USERS", fetchData)
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // text: el.firstName + ' ' + el.lastName + ' (' + el.role.name + ')',
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  const handleAssign = async () => {
    let currentValidation = {
      ...validation,
      people: {
        ...validation.people,
        error: validation.people.pattern(assignData.people) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);

    if (!errorsInData) {
      const response = await updateWorkOrderAssign(
        data.id,
        assignData.teams,
        assignData.people,
        data.attributes.orderType
      );
      if (response && response.status == 200) {
        toast.success(t.workOrders.WO_assign_success);
        untoggle();
        setModalWidth(Math.max(window.innerWidth / 2, 514));
        setAssignData({
          teams: [],
          people: [],
        });
        setRefresh(!refresh);

        const currentPathname = window.location.pathname;

        if (currentPathname == "/work-orders") {
          setSearchTitle("");
          setSelectedDates([]);
          setFilterOptions({
            serialNumber: "",
            assignees: {
              people: [],
              teams: [],
            },
            people: [],
            teams: [],
            customers: [],
            locations: [],
            createdByUser: [],
            dueDate: {
              value: 0,
              text: "Any Date",
            },
            status: [],
            priority: [],
            categories: [],
          });
        }
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
        } else {
          toast.error(t.common.something_wrong);
        }
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
        setModalWidth(Math.max(window.innerWidth / 2, 514));
      }
    } else {
      scrollToError();
    }
  };

  const statusOptions = [
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: "inReview",
      color: "black",
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: "completed",
      color: "green",
      label: t.workOrders.work_order_status.completed,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.workOrders.work_order_status.cancel,
    },
  ];
  const priorityOptions = [
    {
      value: "none",
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: "low",
      label: t.workOrders.priority_status.low,

      color: "yellow",
    },
    {
      value: "medium",
      label: t.workOrders.priority_status.medium,

      color: "orange",
    },
    {
      value: "high",
      label: t.workOrders.priority_status.high,

      color: "red",
    },
  ];

  const workOrderPriorityColor = priorityOptions.find(
    (pro) => pro.value == data?.attributes.priority
  );

  const workOrderStatus = statusOptions.find(
    (status) => status.value == data?.attributes.status
  );

  const codeOptions = [
    {
      value: "sop",
      label: t.workOrders.work_order_category.Sop,
    },

    {
      value: "project",
      label: t.workOrders.work_order_category.Project,
    },
    {
      value: "preventive",
      label: t.workOrders.work_order_category.Preventive,
    },
    {
      value: "inspection",
      label: t.workOrders.work_order_category.Inspection,
    },
    {
      value: "damage",
      label: t.workOrders.work_order_category.Damage,
    },
  ];

  const categoryOptions = [
    {
      value: "damage",
      label: t.workOrders.form.category_options[0],
    },
    {
      value: "corrective action Downtime",
      label: t.workOrders.form.category_options[1],
    },
    {
      value: "planned Downtime",
      label: t.workOrders.form.category_options[2],
    },
    {
      value: "unplanned Downtime",
      label: t.workOrders.form.category_options[3],
    },
    {
      value: "inspection",
      label: t.workOrders.form.category_options[4],
    },
    {
      value: "electrical",
      label: t.workOrders.form.category_options[5],
    },
    {
      value: "mechanical",
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: "HVAC",
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: "preventive maintenance",
      label: t.workOrders.form.category_options[8],
    },
    {
      value: "project",
      label: t.workOrders.form.category_options[9],
    },
    {
      value: "safety",
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: "sop",
      label: t.workOrders.form.category_options[11],
    },
  ];

  const renderAssigneePeople = (assigneePeople) => {
    if (assigneePeople?.length > 0) {
      return assigneePeople.map((people, index) => {
        if (people?.attributes?.profileImage?.data?.attributes?.url) {
          return (
            <p>
              <Image
                key={index}
                avatar
                title={`${people.attributes.firstName} ${people.attributes.lastName}`}
                src={`${people?.attributes?.profileImage?.data?.attributes?.url}`}
                style={
                  index !== 0
                    ? selectedLanguage == "ar"
                      ? { right: "-6px", width: "34px", height: "34px" }
                      : { left: "-6px", width: "34px", height: "34px" }
                    : { width: "34px", height: "34px" }
                }
              />
              {checkForLinkPermission("people") ? (
                <span
                  onClick={() =>
                    history.push(`/people?id=${people.attributes?.countId}`)
                  }
                  className="detail-intro-link ml-1 mr-1"
                >
                  {people.attributes.firstName +
                    " " +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className=" ml-1 mr-1">
                  {people.attributes.firstName +
                    " " +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        } else {
          return (
            <p>
              <UserProfilePlaceholder
                key={index}
                name={`${
                  people?.attributes?.firstName
                    ? people?.attributes?.firstName
                    : ""
                } ${
                  people?.attributes?.lastName
                    ? people?.attributes?.lastName
                    : ""
                }`}
                width={"34px"}
                height={"34px"}
                right={selectedLanguage == "en" ? "6px" : "-5px"}
              />
              {checkForLinkPermission("people") ? (
                <span
                  onClick={() =>
                    history.push(`/people?id=${people.attributes?.countId}`)
                  }
                  className="detail-intro-link"
                >
                  {people.attributes.firstName +
                    " " +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className="">
                  {people.attributes.firstName +
                    " " +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        }
      });
    } else {
      return (
        <span
          style={
            selectedLanguage == "en"
              ? { marginLeft: "8px" }
              : { }
          }
        >
          {t.common.na}
        </span>
      );
    }
  };

  const categoryStatus = categoryOptions.find(
    (status) => status.value == data?.attributes?.category
  );

  const codeStatus = codeOptions.find(
    (status) => status.value == data?.attributes?.code
  );

  let customerName = t.common.na;

  if (data?.attributes?.customer?.data) {
    customerName = (
      <div
        className="d-flex"
        onClick={() =>
          history.push(
            `/customers?id=${data?.attributes?.customer?.data?.attributes?.countId}`
          )
        }
      >
        <Image
          avatar
          title={data?.attributes.customer?.data?.attributes.name}
          src={LetteredAvatarImageUrl(
            data?.attributes.customer?.data?.attributes?.name
          )}
        />
        <div
          className="work-order-link mt-1"
          basic
          style={{ marginLeft: "4px" }}
        >
          {data?.attributes.customer?.data?.attributes.name}
          {selectedLanguage == "en" ? (
            <Icon name="angle right" />
          ) : (
            <Icon name="angle left" />
          )}
        </div>
      </div>
    );
  } else {
    customerName = t.common.na;
  }

  let createdByName = data?.attributes.createdByUser.data ? (
    <div>
      <Image
        avatar
        title={
          data?.attributes.createdByUser?.data?.attributes?.firstName +
          " " +
          data?.attributes.createdByUser?.data?.attributes?.lastName
        }
        src={
          data?.attributes?.createdByUser?.data?.attributes?.profileImage?.data
            ?.attributes?.url
            ? `${IMAGES_URL}${data?.attributes?.createdByUser?.data?.attributes?.profileImage?.data?.attributes?.url}`
            : LetteredAvatarImageUrl(
                data?.attributes.createdByUser?.data?.attributes?.firstName +
                  " " +
                  data?.attributes.createdByUser?.data?.attributes?.lastName
              )
        }
      />
      <span style={{ marginLeft: "4px" }}>
        {data?.attributes.createdByUser?.data?.attributes?.firstName +
          " " +
          data?.attributes.createdByUser?.data?.attributes?.lastName}
      </span>
    </div>
  ) : (
    t.common.na
  );

  const generateDays = (
    sun = false,
    mon = false,
    tue = false,
    wed = false,
    thu = false,
    fri = false,
    sat = false
  ) => {
    let returnDays = [];
    if (sun)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.sun}
        />
      );
    if (mon)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.mon}
        />
      );
    if (tue)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.tue}
        />
      );
    if (wed)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.wed}
        />
      );
    if (thu)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.thu}
        />
      );
    if (fri)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.fri}
        />
      );
    if (sat)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.sat}
        />
      );

    return returnDays;
  };

  const monthDay = () => {
    let monthsDays = [];

    for (let i = 1; i < 32; i++) {
      let day_th = "th";
      if (i == 1) day_th = "st";
      if (i == 2) day_th = "nd";
      if (i == 3) day_th = "rd";

      const obj = {
        key: i,
        value: "d" + i,
        text: i > 28 ? i + day_th + " (or last)" : i + day_th,
      };

      monthsDays.push(obj);
    }

    return monthsDays.filter(
      (month) => month.value == data?.attributes.repeatMonthDay
    )[0].text;
  };

  const plannedValue = () => {
    let returnData = [];
    returnData.push(
      <span>{t.workOrders.details_view[data?.attributes.repeatEvery]}</span>
    );

    switch (data?.attributes.repeatEvery) {
      case "weekly":
        returnData.push(
          <span>
            , {t.workOrders.details_view.every}{" "}
            {data?.attributes.repeatEveryWeek}{" "}
            {` ${
              data?.attributes.repeatEveryWeek > 0
                ? t.workOrders.details_view.weeks
                : t.workOrders.details_view.week
            }`}
          </span>
        );
        returnData.push(
          <div style={{ marginTop: "5px" }}>
            On{" "}
            {generateDays(
              data?.attributes.daysRepeat?.sun,
              data?.attributes.daysRepeat?.mon,
              data?.attributes.daysRepeat?.tue,
              data?.attributes.daysRepeat?.wed,
              data?.attributes.daysRepeat?.thu,
              data?.attributes.daysRepeat?.fri,
              data?.attributes.daysRepeat?.sat
            )}
          </div>
        );

        returnData.push(
          <div style={{ marginTop: "5px" }}>
            <span style={{ marginRight: "2px" }}>
              {t.workOrders.details_view.expire_after}
            </span>
            {data?.attributes.endRepeat}
            <span style={{ marginLeft: "2px" }}>
              {" "}
              {t.workOrders.details_view.orders}
            </span>
          </div>
        );
        break;

      case "monthly":
        returnData.push(
          <span>
            , {t.workOrders.details_view.every}{" "}
            {data?.attributes.repeatEveryMonth}{" "}
            {` ${
              data?.attributes.repeatEveryMonth > 0
                ? t.workOrders.details_view.months
                : t.workOrders.details_view.month
            }`}
          </span>
        );

        returnData.push(
          <div style={{ marginTop: "5px" }}>
            {` ${t.workOrders.details_view.on} `} {monthDay()}
          </div>
        );

        returnData.push(
          <div style={{ marginTop: "5px" }}>
            <span style={{ marginRight: "2px" }}>
              {" "}
              {t.workOrders.details_view.expire_after}
            </span>
            {data?.attributes.endRepeat}
            <span style={{ marginLeft: "2px" }}>
              {" "}
              {t.workOrders.details_view.orders}
            </span>
          </div>
        );
        break;

      case "yearly":
        returnData.push(
          <span>
            , {t.workOrders.details_view.every}{" "}
            {data?.attributes.repeatEveryYear}{" "}
            {` ${
              data?.attributes.repeatEveryYear > 0
                ? t.workOrders.details_view.years
                : t.workOrders.details_view.year
            }`}
          </span>
        );

        returnData.push(
          <div style={{ marginTop: "5px" }}>
            <span style={{ marginRight: "2px" }}>
              {" "}
              {t.workOrders.details_view.expire_after}
            </span>
            {data?.attributes.endRepeat}
            <span style={{ marginLeft: "2px" }}>
              {" "}
              {t.workOrders.details_view.orders}
            </span>
          </div>
        );
        break;

      default:
        return null;
    }

    return returnData;
  };

  const confirmDeclineUser = () => {
    // if (data?.declined && !data?.confirmed) {
    //   return (
    //     <Label size="medium" basic color="red" style={{ height: 'auto' }}>
    //       <Icon name="dont" color="red" /> Declined
    //     </Label>
    //   );
    // }

    if (data.attributes.status === "inReview") {
      return (
        <>
          <div className="comment d-flex justify-content-between align-items-center p-5 approve-container">
            <div className="">
              <span className="user-img">
                {t.workOrders.table.createdByUser}{" "}
                {data?.attributes?.createdByUser?.data?.attributes?.firstName ||
                data?.attributes?.createdByUser?.data?.attributes?.lastName
                  ? data?.attributes?.createdByUser?.data?.attributes
                      ?.firstName +
                    " " +
                    data?.attributes?.createdByUser?.data?.attributes?.lastName
                  : t.common.na}
                <p className="item-label font-weight-bold">
                  {/* <span >{`${one?.user?.data?.attributes?.firstName ||
                      ''} ${one?.user?.data?.attributes?.lastName ||
                      ''}`}</span> */}
                  <Moment
                    format="DD-MM-YYYY LT"
                    date={data?.attributes?.createdAt}
                  />
                </p>
              </span>
              {/* <div className="d-flex">
                <Label
                  circular
                  empty
                  color={workOrderStatus?.color || 'black'}
                  style={{ marginRight: '5px' }}
                  size="big"
                />
                {workOrderStatus?.label || one.status}
              </div> */}
            </div>
            <div className="approval-button-container ">
              <button
                className="modal-form-action-button decline "
                onClick={() => {
                  setApproveModal(false);
                  setOnHoldFlag(false);
                  setInCancleFlag(false);
                  setInProgressFlag(false);
                  setModal(true);
                }}
              >
                {t.workOrders.details_view.decline}
              </button>

              <button
                onClick={() => {
                  setApproveModal(true);
                  setOnHoldFlag(false);
                  setInCancleFlag(false);
                  setInProgressFlag(false);
                  setModal(true);
                }}
                className="modal-form-action-button blue"
              >
                {t.workOrders.details_view.approve}
              </button>
            </div>

            {/* {one?.image?.data && one?.image?.data.length
                ? one?.image?.data.map((el) => {
                    return (
                      <List divided relaxed>
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            window.open(
                              IMAGES_URL + el?.attributes.url,
                              '_blank'
                            );
                          }}
                        >
                          <List.Icon
                            name={
                              el?.attributes.mime.match('image.*')
                                ? 'image'
                                : el?.attributes.mime.match('pdf')
                                ? 'file pdf'
                                : 'file'
                            }
                            size="large"
                            color="black"
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <p className="font-weight-bold ml-2 mr-2">
                              {el?.attributes.name}
                            </p>
                          </List.Content>
                        </List.Item>
                      </List>
                    );
                  })
                : null} */}
          </div>
        </>
        // <div>
        //   <Button
        //     color="green"
        // onClick={() => {
        //   setApproveModal(true);
        //   setOnHoldFlag(false);
        //   setInProgressFlag(false);
        //   setModal(true);
        // }}
        //     // loading={approveButton > 0}
        //   >
        //     {t.workOrders.details_view.approve}
        //   </Button>
        //   <Button
        //     negative
        //     onClick={() => {
        //       setApproveModal(false);
        //       setOnHoldFlag(false);
        //       setInProgressFlag(false);
        //       setModal(true);
        //     }}
        //   >
        //     {t.workOrders.details_view.decline}
        //   </Button>
        // </div>
      );
    } else if (data.attributes.status === "inProgress") {
      return (
        <div className="hold-button-container">
          <button
            // content={t.procedures.form.submit.add}
            // primary
            onClick={() => {
              setApproveModal(false);
              setOnHoldFlag(true);
              setInProgressFlag(false);
              setInCancleFlag(false);
              setModal(true);
            }}
            // loading={buttonStatus == 1}
            style={{ marginTop: "-10px" }}
            className="form-action-button  blue"
          >
            {t.workOrders.work_order_status.hold}
          </button>
        </div>
        // <div>
        //   <Button
        //     negative
        //     onClick={() => {
        //       setApproveModal(false);
        //       setOnHoldFlag(true);
        //       setInProgressFlag(false);
        //       setModal(true);
        //     }}
        //   >
        //     {t.workOrders.work_order_status.onHold}
        //   </Button>
        // </div>
      );
    } else if (data.attributes.status === "onHold") {
      return (
        <div className="hold-button-container">
          <button
            // content={t.procedures.form.submit.add}
            // primary
            onClick={() => {
              setApproveModal(false);
              setOnHoldFlag(false);
              setInCancleFlag(false);
              setInProgressFlag(true);
              setModal(true);
            }}
            // loading={buttonStatus == 1}
            style={{
              marginTop: "-10px",
              marginRight: "5px",
            }}
            className="form-action-button blue"
          >
            {t.workOrders.work_order_status.inProgress}
          </button>
        </div>
        // <div>
        //   <Button
        //     primary
        //     onClick={() => {
        //       setApproveModal(false);
        //       setOnHoldFlag(false);
        //       setInProgressFlag(true);
        //       setModal(true);
        //     }}
        //   >
        //     {t.workOrders.work_order_status.inProgress}
        //   </Button>
        // </div>
      );
    } else if (data.attributes.status === "open") {
      return (
        <div className="hold-button-container">
          {!data?.attributes?.people?.data.length > 0 ? (
            <button
              // content={t.procedures.form.submit.add}
              // primary
              onClick={() => {
                setAssignModal(true);
              }}
              // loading={buttonStatus == 1}
              style={{
                marginTop: "-10px",
                // marginRight: '-15px',
              }}
              className="form-action-button blue"
            >
              {t.workOrders.assign}
            </button>
          ) : null}

          <button
            // content={t.procedures.form.submit.add}
            // primary
            onClick={() => {
              setApproveModal(false);
              setOnHoldFlag(false);
              setInProgressFlag(false);
              setInCancleFlag(true);
              setModal(true);
            }}
            // loading={buttonStatus == 1}
            style={{
              marginTop: "-10px",
              // marginRight: '-15px',
            }}
            className="form-action-button grey"
          >
            {t.common.cancel}
          </button>
        </div>
        // <div>
        //   <Button
        //     primary
        //     onClick={() => {
        //       setApproveModal(false);
        //       setOnHoldFlag(false);
        //       setInProgressFlag(true);
        //       setModal(true);
        //     }}
        //   >
        //     {t.workOrders.work_order_status.inProgress}
        //   </Button>
        // </div>
      );
    }
  };

  const handleSave = async () => {
    try {
      if ((onHoldFlag || inCancleFlag) && !message) {
        toast.error(t.workOrders.submit_error);
        return;
      }
      setLoading(true);
      let res = await updateWorkOrderStatus(
        data.id,
        inCancleFlag
          ? "cancelled"
          : inProgressFlag
          ? "inProgress"
          : !onHoldFlag
          ? approveModal
            ? "completed"
            : "open"
          : "onHold",
        message,
        data?.attributes?.orderType
      );
      setModal(false);
      setMessage("");
      setLoading(false);
      if (res && res.status == 200) {
        toast.success(
          inCancleFlag
            ? t.workOrders.success_cancel
            : inProgressFlag
            ? t.workOrders.success_progress
            : !onHoldFlag
            ? approveModal
              ? t.workOrders.success_approve
              : t.workOrders.success_decline
            : t.workOrders.success_hold
        );

        toggleDeleteSuccessModal("close");
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.message);
      setModal(false);
      setMessage("");
      setLoading(false);
    }
  };
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    const currentPathname = window.location.pathname;
    if (data) {
      untoggle();
      setModalWidth(Math.max(window.innerWidth / 2, 514));
      setRefresh(!refresh);

      if (currentPathname == "/work-orders") {
        setSearchTitle("");
        setSelectedDates([]);
        setFilterOptions({
          serialNumber: "",
          assignees: {
            people: [],
            teams: [],
          },
          people: [],
          teams: [],
          customers: [],
          locations: [],
          createdByUser: [],
          dueDate: {
            value: 0,
            text: "Any Date",
          },
          status: [],
          priority: [],
          categories: [],
        });
      }
      setOnHoldFlag(false);
      setInCancleFlag(false);
      setApproveModal(false);
    }
  };

  let teamName = data?.attributes?.teams?.data;

  if (teamName?.length > 0) {
    teamName = teamName.map((team, index) =>
      checkForLinkPermission("team") ? (
        <div className="d-flex mb-5">
          <Image
            avatar
            title={team?.attributes.name}
            src={LetteredAvatarImageUrl(team?.attributes.name)}
            height="34px"
            width="34px"
          />

          <div
            style={{ marginLeft: "4px" }}
            onClick={() =>
              history.push(`/teams?id=${team?.attributes?.countId}`)
            }
            className="work-order-link mt-1 "
          >
            {team?.attributes?.name ? team.attributes.name : t.common.na}
          </div>
        </div>
      ) : (
        <div className="d-flex mb-5">
          <Image
            avatar
            title={team?.attributes.name}
            src={LetteredAvatarImageUrl(team?.attributes.name)}
            height="34px"
            width="34px"
          />

          <div style={{ marginLeft: "4px" }} className="mt-1 ">
            {team?.attributes?.name ? team.attributes.name : t.common.na}
          </div>
        </div>
      )
    );
  } else {
    teamName = "";
  }

  let assetData = data.attributes?.assets?.data;
  let assetString = "";

  if (assetData?.length > 0) {
    assetString = assetData.map((each) => each.attributes?.name).join(", ");
  } else {
    assetString = t.common.na;
  }
  let addedPartsData = data?.attributes?.report?.data?.attributes?.parts;
  let addedcostData = "";
  let addedpartData = "";

  if (addedPartsData?.length > 0) {
    addedcostData = addedPartsData.map((each, index) => {
      return (
        <div>
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.form.added_cost + (index + 1)}
              {":"}
            </label>
            <p className="workorder-intro-value set-width">
              {each?.amount ? `${each.amount}` : 0} {organisationCurrency}
            </p>
          </div>
        </div>
      );
    });
  } else {
    addedcostData = (
      <div>
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.added_cost}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">{t.common.na}</p>
        </div>
      </div>
    );
  }

  if (addedPartsData?.length > 0) {
    addedpartData = addedPartsData?.map((each, index) => {
      return (
        <div>
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.form.added_parts + (index + 1)}
              {":"}
            </label>
            <p className="workorder-intro-value set-width">
              {each?.part?.data?.attributes?.name
                ? each?.part?.data?.attributes?.name
                : t.common.na}
            </p>
          </div>
        </div>
      );
    });
  } else {
    addedpartData = (
      <div>
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.added_parts}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">{t.common.na}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="preview-details-wrapper">
      <div className="intro">
        <div
          className={
            data?.attributes?.status === "completed" ||
            data?.attributes?.status === "inProgress" ||
            data?.attributes?.status === "onHold"
              ? "text-right"
              : ""
          }
          style={{ fontSize: "15px" }}
        >
          {userPermissions["work-order"]?.edit ? confirmDeclineUser() : null}
        </div>
      </div>

      <div className="data-header mt-5">
        <div className="header-title d-flex mb-4 justify-content-between align-items-center">
          <div className="header-pill-container d-flex align-items-baseline">
            <p className="detail-title" style={{ width: "350px" }}>
              {data?.attributes?.title}
            </p>
          </div>
          <div className="header-id d-flex flex-column">
            <div className="">
              <p className="workorder-intro-value mt-2 d-flex gap-3">
                <div
                  className={`item-value mt-3 ${
                    selectedLanguage == "en" ? "" : "ml-2"
                  }`}
                >
                  <Label
                    circular
                    empty
                    color={workOrderStatus?.color}
                    style={{
                      marginRight: "5px",
                      width: "12px",
                      height: "12px",
                    }}
                    size="medium"
                  />
                </div>
                <span className="mt-3 ">
                  {data?.attributes.status
                    ? workOrderStatus?.label
                    : t.common.na}
                </span>
              </p>
            </div>
            {/* <div className='mt-2 text-right'>
            <label className={`${selectedLanguage == 'en' ? '' : 'ml-2'}`}>
              {t.assets.table.ID}
            </label>
            {':'}
            <span>{data?.id}</span>
          </div> */}
          </div>
        </div>
        <div>
          <SemanticImage
            src={
              data?.attributes.image?.data?.attributes
                ? IMAGES_URL + data.attributes.image.data.attributes.url
                : toAbsoluteUrl("/media/default-img.png")
            }
            size="medium"
            className="data-header-image"
          />
        </div>

        {/* <div className="wo-desc">
          <p className="wo-desc-value">
            {data?.attributes?.description || t.common.na}
          </p>
          <p className="detail-updated-title">
            {t.assets.table.updatedAt}
            {':'}{' '}
            <span>
              {
                <Moment
                  format="DD-MM-YYYY LT"
                  date={data?.attributes.updatedAt}
                />
              }
            </span>
          </p>
          {/* {userPermissions?.asset?.statusToggle ? (
              <div>
                <div className="d-flex align-items-start">
                  <div className="ml-3 mr-3">
                    {data?.attributes?.status
                      ? t.assets.active
                      : t.assets.inActive}
                  </div>
                  <Toggle
                    checked={toggleStatus}
                    defaultChecked={data?.attributes?.status}
                    onChange={(e) => handleAssetStatus(data?.id, e)}
                  />
                </div>
              </div>
            ) : null} */}
        {/* </div> */}
      </div>

      <div className="details-wrapper">
        {/* <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.filter.assignees}
            </label>
           <p className="workorder-intro-value">
    {renderAssigneePeople(data?.attributes?.people?.data)}

</p>
          </div> */}

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.description}
            {":"}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.description
              ? data?.attributes?.description
              : t.common.na}
          </p>
        </div>

        <Divider />

        <div className="d-flex align-items-start">
          <label className="label-item mt-0">
            {""}
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.table.priority}
              {":"}
            </label>
          </label>
          <p
            style={
              selectedLanguage == "en"
                ? { fontSize: "12px" }
                : { fontSize: "11px" }
            }
            className={`pill gap-0 mt-0 workorder-intro-value ${data.attributes.priority}`}
          >
            <FlagWhiteIcon />
            <span className="ml-2 mr-2">{workOrderPriorityColor.label} </span>
          </p>
          {/* <p className="workorder-intro-value mt-2 d-flex gap-3">
            <div
              className={`item-value mt-3 ${
                selectedLanguage == 'en' ? '' : 'ml-2'
              }`}
            >
              <Label
                circular
                empty
                color={workOrderStatus?.color}
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
                size="medium"
              />
            </div>
            <span className="mt-3 ">
              {data?.attributes.status ? workOrderStatus?.label : t.common.na}
            </span>
          </p> */}
        </div>
        {/* {userPermissions?.procedure?.view ?  */}
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.procedure}
            {":"}
          </label>
          <p className="workorder-intro-value">
            {data.attributes?.procedure?.data?.attributes?.title || t.common.na}
          </p>
        </div>
        {/* : null} */}

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.table.dueDate}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">
            {data?.attributes?.dueDate ? (
              <Moment format="MMMM DD, YYYY" date={data?.attributes?.dueDate} />
            ) : (
              <span>{t.common.na}</span>
            )}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.table.category}
            {":"}
          </label>
          <p className="workorder-intro-value">
            {categoryStatus?.label || t.common.na}
          </p>
        </div>

        <Divider />

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.table.location}
            {":"}
          </label>

          <p className="workorder-intro-value">
            {data?.attributes?.location?.data ? (
              <React.Fragment>
                {checkForLinkPermission("location") ? (
                  <span
                    className={`detail-intro-link`}
                    basic
                    onClick={() =>
                      history.push(
                        `/locations?id=${data?.attributes?.location?.data?.attributes?.countId}`
                      )
                    }
                  >
                    {data?.attributes?.location?.data?.attributes?.name}
                  </span>
                ) : (
                  <span className=" ml-1 mr-1">
                    {data?.attributes?.location?.data?.attributes?.name}
                  </span>
                )}
              </React.Fragment>
            ) : (
              t.common.na
            )}
          </p>

          {/* <p className="workorder-intro-value">
            {data?.attributes?.location?.data?.attributes.name || t.common.na}
          </p> */}
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.parts.filter.area}
            {":"}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.area?.data?.attributes.name || t.common.na}
          </p>
        </div>
        {/* {userPermissions?.customer?.view ?  */}
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.table.customer}
            {":"}
          </label>
          <p className="workorder-intro-value">{customerName}</p>
        </div>
        {/* : null} */}
        {/* {userPermissions?.asset?.view ?  */}
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.assets}
            {":"}
          </label>

          <p className="workorder-intro-value">
            {data?.attributes?.assets?.data?.length
              ? data.attributes.assets.data.map((asset, index) => (
                  <React.Fragment key={index}>
                    {checkForLinkPermission("asset") ? (
                      <p
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == "en" ? "text-left" : "text-right"
                        }`}
                        basic
                        onClick={() =>
                          history.push(
                            `/assets?id=${asset?.attributes?.countId}`
                          )
                        }
                      >
                        {asset.attributes.name}
                      </p>
                    ) : (
                      <p className={`ml-1 mr-1`} basic>
                        {asset.attributes.name}
                      </p>
                    )}
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
          {/* <p className="workorder-intro-value">
            {assetString.length > 0 ? assetString : t.common.na}
          </p> */}
        </div>
        {/* : null } */}
        {/* {userPermissions?.part?.view ?  */}
        <>
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.parts.tab.first}
              {":"}
            </label>
            <p className="workorder-intro-value">
              {data?.attributes?.parts?.data?.length
                ? data.attributes.parts.data.map((asset, index) => (
                    <React.Fragment key={index}>
                      <p
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == "en" ? "text-left" : "text-right"
                        }`}
                        basic
                        onClick={() =>
                          history.push(`/parts?id=${asset.attributes?.countId}`)
                        }
                      >
                        {asset.attributes.name}
                      </p>
                    </React.Fragment>
                  ))
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.parts.tab.second}
              {":"}
            </label>
            <p className="workorder-intro-value">
              {data?.attributes?.sets?.data?.length
                ? data.attributes.sets.data.map((asset, index) => (
                    <React.Fragment key={index}>
                      <p
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == "en" ? "text-left" : "text-right"
                        }`}
                        basic
                        onClick={() =>
                          history.push(`/sets?id=${asset.attributes?.countId}`)
                        }
                      >
                        {asset.attributes.name}
                      </p>
                    </React.Fragment>
                  ))
                : t.common.na}
            </p>
          </div>
        </>
        {/* :null} */}
        <Divider />
        {/* <Dropdown
            placeholder={t.workOrders.details_view.status}
            className='status-dropdown'
            style={{width:"200px"}}
            fluid
            selection
            options={statusOptions}
            value={data?.attributes?.status}
            onChange={(e, value) => {
              console.log(value);
              // onValuesChange('customer')
              // console.log(value, e);
              let findPriority = value.options.find(
                (one) => one.value == value.value
              );
              console.log(findPriority, 'hii');
              if (findPriority.value == 'onHold') {
                setModal(true);
                // let mydata = data.attributes.status;
                // mydata = 'inProgress';
                // setData({...data,mydata: 'inProgress'});
                setApproveModal(false);
                setOnHoldFlag(true);
                setInProgressFlag(false);
                setModal(true);
              } else if (findPriority.value == 'inProgress') {
                setApproveModal(false);
                setOnHoldFlag(false);
                setInProgressFlag(true);
                setModal(true);
              }
              // }else if (findPriority.value == 'inReview') {
              //   setApproveModal(false);
              // setOnHoldFlag(false);
              // setInProgressFlag(true);
              // setModal(true);
              // }
            }}
          /> */}

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workRequests.form.people}
            {":"}
          </label>
          <p
            className="workorder-intro-value"
            style={
              data?.attributes?.people?.data.length > 0
                ? { marginTop: "-6px", marginLeft: "8px" }
                : { marginLeft: "8px" }
            }
          >
            {renderAssigneePeople(data?.attributes?.people?.data)}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.teams}
            {":"}
          </label>
          <p className="workorder-intro-value" style={{ marginLeft: "8px" }}>
            {teamName.length > 0 ? (
              teamName
            ) : (
              <span
                style={
                  selectedLanguage == "en"
                    ? { marginLeft: "8px" }
                    : { }
                }
              >
                {t.common.na}
              </span>
            )}
          </p>
        </div>

        <Divider />

        <Grid columns={2}>
          <GridColumn className="pr-0">
            {" "}
            <div className="d-flex align-items-start">
              <label
                // style={{ minWidth: "106px" }}
                className={`workorder-intro-label work-order-label ${selectedLanguage == "en" ? "" : "mr-4"}`}
              >
                {t.workOrders.form.order_type}
                {":"}
              </label>
              <p
                className="workorder-intro-value"
                style={{ minWidth: "152px" }}
              >
                {data?.attributes?.orderType == "reactive"
                  ? t.workOrders.form.order_type_1
                  : t.workOrders.form.order_type_2}
              </p>
            </div>
          </GridColumn>
          <GridColumn>
            {" "}
            <div className="d-flex align-items-start">
              <label style={{minWidth:"71px"}} className="workorder-intro-label">
                {t.workOrders.details_view.repeat}
                {":"}
              </label>
              {/* <p className="workorder-intro-value">
            {data?.attributes?.orderType == "reactive" ? t.common.na : `${t.workOrders.details_view[data?.attributes?.repeatEvery]}``${
            data?.attributes?.daysRepeat
              ? ' ' + Object.keys(data?.attributes?.daysRepeat)
                .filter(day => data?.attributes?.daysRepeat[day])
                .map(day => day.charAt(0).toUpperCase() + day.slice(1))
                .join(', ')
              : ''
          }`}
          </p> */}

              <p className="workorder-intro-value">
                {data?.attributes?.orderType === "reactive"
                  ? t.common.na
                  : `${
                      t.workOrders.details_view[data?.attributes?.repeatEvery]
                    }; ${
                      data?.attributes?.daysRepeat
                        ? " " +
                          Object.keys(data.attributes.daysRepeat)
                            .filter(
                              (day) =>
                                day !== "id" && data.attributes.daysRepeat[day]
                            )
                            .map((day) => {
                              const formattedDay = day.charAt(0).toUpperCase() + day.slice(1);

                              return t.common.days[formattedDay];
                            })
                            .join(", ")
                        : ""
                    }`}
              </p>
            </div>
          </GridColumn>
        </Grid>

        <Grid columns={2}>
          <GridColumn>
            {" "}
            <div className="d-flex align-items-start">
              <label className="workorder-intro-label work-order-label">
                {t.workOrders.form.start_time_date}
                {":"}
              </label>
              <p className="workorder-intro-value">
              
                {data?.attributes?.dueTime ? format(
                  new Date(
                    data?.attributes?.dueDate +
                      "T" +
                      `${data?.attributes?.dueTime || "08:00:00"}`
                  ),
                  "hh:mm a" // This will display the time in 12-hour format with AM/PM
                ): t.common.na}
              </p>
            </div>
          </GridColumn>
          <GridColumn>
            {" "}
            <div className="d-flex align-items-start">
              <label className="workorder-intro-label">
                {t.workOrders.details_view.expire_after}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {data?.attributes?.orderType == "reactive"
                  ? t.common.na
                  : data?.attributes?.endType == "after"
                  ? `${data?.attributes?.endRepeat} ${t.workOrders.title}`
                  : t.common.na}
              </p>
            </div>
          </GridColumn>
        </Grid>

        <Divider />

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.filter.estimation_time_by}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">
            {data?.attributes?.estimationTime
              ? `${data?.attributes?.estimationTime} Hrs`
              : t.common.na}
          </p>
        </div>

        {['admin', 'super_admin', 'limited_admin']?.includes(roleData) ? (
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.actual_times}
              {":"}
            </label>
            <p className="workorder-intro-value set-width">
              {data?.attributes["work-order-log"]?.data?.length > 0
                ? (() => {
                    // Sum up all workOrderTime values
                    const totalWorkOrderTime = data?.attributes[
                      "work-order-log"
                    ]?.data?.reduce((total, item) => {
                      return total + (item?.attributes?.workOrderTime || 0);
                    }, 0);

                    // Format the total time
                    return (
                      formatMillisecondsToHHMM(totalWorkOrderTime) ||
                      "00:00:00 Hr"
                    );
                  })()
                : t.common.na}
            </p>
          </div>
        ) : null}

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.labor_cost}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">
            {data?.attributes?.laborCost
              ? `${data?.attributes?.laborCost}` + " " + organisationCurrency
              : t.common.na}
          </p>
        </div>

        {['admin', 'super_admin', 'limited_admin']?.includes(roleData) ? (
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.actual_cost}
              {":"}
            </label>
            <p className="workorder-intro-value set-width">
            {data?.attributes?.actualCost ? `${data?.attributes?.actualCost} ${organisationCurrency}` : t.common.na}
            </p>
          </div>
        ) : null}

        {addedcostData}

        {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.labor_cost}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.category || t.common.na}
          </p>
        </div> */}

        {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.parts}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.parts && data?.attributes?.parts?.data.length
              ? data?.attributes?.parts?.data.map((each) => {
                  return (
                    <span
                      className="detail-intro-link ml-4 mr-4"
                      basic
                      onClick={() => history.push(`/parts?id=${each?.id}`)}
                    >
                      {each?.attributes?.name}
                    </span>
                  );
                })
              : t.common.na}
          </p>
        </div> */}

        {addedpartData}
       

        {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.table.createdAt}
            {':'}
          </label>
          <p className="workorder-intro-value">
            <div>
              
            </div>
          </p>
        </div> */}

        {data?.attributes?.work_request?.data?.attributes ? (
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workRequests.work_request}
              {":"}
            </label>
            <p
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push(
                  `/work-requests?id=${data?.attributes?.work_request?.data?.attributes?.countId}`
                )
              }
              className={`workorder-intro-value set-width d-flex  flex-column  ${
                selectedLanguage == "en" ? "text-left" : "text-right"
              }`}
            >
              {data?.attributes?.work_request?.data?.attributes ? (
                <div
                  className={`work-order-link  ${
                    selectedLanguage == "en" ? "text-left" : "text-right"
                  }`}
                  basic

                  // onClick={() =>
                  //   history.push(`/procedures?id=${each?.id}`)
                  // }
                >
                  <span className="">
                    {data?.attributes?.work_request?.data?.attributes?.title}
                  </span>
                </div>
              ) : (
                t.common.na
              )}
            </p>
          </div>
        ) : null}

        <Divider />

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label ">
            {t.workOrders.form.file}
            {":"}
          </label>
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                IMAGES_URL + data?.attributes?.file?.data?.attributes?.url,
                "_blank"
              );
            }}
            className={`workorder-intro-value d-flex  flex-column  ${
              selectedLanguage == "en" ? "text-left" : "text-right"
            }`}
          >
            {data?.attributes?.file?.data?.attributes ? (
              <div
                className={`work-order-link  ${
                  selectedLanguage == "en" ? "text-left" : "text-right"
                }`}
                basic

                // onClick={() =>
                //   history.push(`/procedures?id=${each?.id}`)
                // }
              >
                <span className="">
                  {data?.attributes?.file?.data?.attributes?.name}
                </span>
              </div>
            ) : (
              t.common.na
            )}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label ">
            {t.assets.filter.qrcode}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">
            <div className="d-flex">
              <div>
                <QRComponentToPrint
                  titleData={t.assets.table.ID}
                  titleName={t.assets.table.name}
                  fromWO={true}
                  ref={qrRef}
                  data={{
                    ...data,
                    attributes: {
                      name: data?.attributes?.title,
                      count: data?.attributes?.countId,
                      serialNumber: data?.id,
                    },
                  }}
                  titleString={"WO-"}
                />
              </div>
              <div className="m-3">
                <ReactToPrint
                  trigger={() => (
                    <span
                      className="c-pointer"
                      style={{ position: "relative" }}
                      onMouseOver={() => setPrintTooltip(true)}
                      onMouseLeave={() => setPrintTooltip(false)}
                    >
                      <Printer />
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="bottom center"
                        show={showPrintTooltip}
                        textBoxWidth="35px"
                        arrowAlign="center"
                        fontSize="9px"
                        fontWeight="400"
                        fontFamily="Roboto"
                        padding="5px 5px 5px 8px"
                        borderRadius="6px"
                        static
                        moveDown="10px"
                      >
                        <span className="work-order-preview-tooltip">
                          {t.common.print}
                        </span>
                      </Tooltip>
                    </span>
                  )}
                  content={() => {
                    return qrRef.current;
                  }}
                />
                <span
                  className="ml-2 mr-2 c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setDownloadTooltip(true)}
                  onMouseLeave={() => setDownloadTooltip(false)}
                  onClick={() => {
                    qrRef.current.exposeDownloadFunction();
                  }}
                >
                  <FileDownloadIcon />
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="bottom center"
                    show={showDownloadTooltip}
                    textBoxWidth="92px"
                    arrowAlign="center"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="5px 5px 5px 8px"
                    borderRadius="6px"
                    static
                    moveDown="10px"
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.download_QR}
                    </span>
                  </Tooltip>
                </span>
              </div>
            </div>
          </p>
        </div>
        {/* <label className="qr-code-container label-item mt-5">
          {t.assets.filter.qrcode}
        </label> */}

        <Divider />

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label grey">
            {t.workOrders.table.createdByUser}
            {":"}
          </label>
          <p
            style={{ marginTop: "-1px" }}
            className="workorder-intro-value set-width grey"
          >
            {data?.attributes?.createdByUser?.data?.attributes?.firstName &&
            data?.attributes?.createdByUser?.data?.attributes?.lastName
              ? data?.attributes?.createdByUser?.data?.attributes?.firstName +
                " " +
                data?.attributes?.createdByUser?.data?.attributes?.lastName
              : t.common.na}
            {", "}
            <Moment format="DD-MM-YYYY LT" date={data?.attributes?.createdAt} />
          </p>
        </div>

        <div className="d-flex align-items-start mt-3">
          <label className="workorder-intro-label work-order-label grey">
            {t.common.update_by}
            {":"}
          </label>
          <p
            style={{ marginTop: "-1px" }}
            className="workorder-intro-value set-width grey"
          >
            {data?.attributes?.updatedByUser?.data
              ? data?.attributes?.updatedByUser?.data?.attributes?.firstName +
                " " +
                data?.attributes?.updatedByUser?.data?.attributes?.lastName +
                ", " +
                moment(data?.attributes?.updatedAt).format("DD-MM-YYYY LT")
              : t.common.na}
          </p>
        </div>
      </div>
      <Modal
        className="assets-modal"
        size="tiny"
        open={openModal}
        onClose={() => {
          setModal(false);
        }}
      >
        <div
          className={
            approveModal || inProgressFlag
              ? "header-container green"
              : inCancleFlag
              ? "header-container grey"
              : "header-container red"
          }
        >
          <span className="assets-header">
            {" "}
            {`${
              inCancleFlag
                ? t.common.cancel
                : inProgressFlag
                ? t.workOrders.work_order_status.inProgress
                : !onHoldFlag
                ? approveModal
                  ? t.workOrders.details_view.approve
                  : t.workOrders.details_view.decline
                : t.workOrders.work_order_status.hold
            } `}
            {t.workOrders.details_view.work_order}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => setModal(false)}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          {/* <div>
            <p className="status-modal-popup-message">
              Are you sure want to{' '}
              {inProgressFlag
                ? 'put in progress'
                : !onHoldFlag
                ? approveModal
                  ? 'appove'
                  : 'decline'
                : 'put on hold'}{' '}
              this work order?
            </p>
            <div>
              <Form.Field>
                <TextArea
                  className="modal-text-area"
                  // labelPosition="right"
                  // type="textarea"
                  // fluid
                  rows={5}
                  placeholder={`Add reason ${onHoldFlag ? '(required)' : ''}`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></TextArea>
              </Form.Field>
            </div>
          </div> */}

          <div className="comment-container" style={{ marginLeft: "3px" }}>
            <label className="comment-label">
              {" "}
              {/* {t.workOrders.are_you_sure}{' '} */}
              {inCancleFlag
                ? t.workOrders.cancel_WO
                : inProgressFlag
                ? t.workOrders.progress_WO
                : !onHoldFlag
                ? approveModal
                  ? t.workOrders.approve_WO
                  : t.workOrders.decline_WO
                : t.workOrders.hold_WO}{" "}
              {/* {t.workOrders.this_WO} */}
            </label>
            <div className="add-comment">
              <TextArea
                className="comment-textarea"
                rows={5}
                placeholder={`${
                  !onHoldFlag && !inCancleFlag
                    ? t.workOrders.add_reason
                    : `${t.workOrders.required}`
                }`}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          {/* <div className="mt-2 mb-2">
            <button
              onClick={() => {
                handleSave();
              }}
              disabled={loading}
              className="send-action-button red"
            >
              {' '}
              {inProgressFlag
                ? 'In-Progress'
                : !onHoldFlag
                ? approveModal
                  ? 'Approve'
                  : 'Decline'
                : 'On-Hold'}
            </button>
          </div> */}

          <div
            className={`modal-button-container ${
              selectedLanguage == "en" ? "mr-6" : "ml-10"
            }`}
          >
            <button
              className="modal-form-action-button"
              onClick={() => setModal(false)}
              disabled={loading}
              style={{ color: "#172B4D", fontWeight: "500" }}
            >
              {t.assets.form.cancel}
            </button>

            <button
              onClick={() => {
                handleSave();
                // disabled = { loading };
              }}
              style={{ fontWeight: "500" }}
              className={`modal-form-action-button ${
                approveModal || inProgressFlag
                  ? "blue"
                  : inCancleFlag
                  ? "blue"
                  : "blue"
              }`}
            >
              {inCancleFlag
                ? t.workOrders.details_view.approve
                : inProgressFlag
                ? t.workOrders.work_order_status.inProgress
                : !onHoldFlag
                ? approveModal
                  ? t.workOrders.details_view.approve
                  : t.workOrders.details_view.decline
                : t.workOrders.work_order_status.hold}
            </button>
          </div>
          {/* <Button
            color={approveModal ? 'green' : 'red'}
            onClick={() => {
              handleSave();
            }}
            disabled={loading}
          >
            {inProgressFlag
              ? 'In-Progress'
              : !onHoldFlag
              ? approveModal
                ? 'Approve'
                : 'Decline'
              : 'On-Hold'}
          </Button> */}

          {/* <Button
            color="black"
            onClick={() => setModal(false)}
            disabled={loading}
          >
            Cancel
          </Button> */}
        </Modal.Actions>
      </Modal>
      <Modal
        size="tiny"
        open={DeleteSuccessModal}
        onClose={() => {
          toggleDeleteSuccessModal("close");
        }}
        style={{ minHeight: "195px" }}
        className={`delete-result-modal success`}
      >
        <div className="text-right mt-5 mr-5 ml-5">
          <span
            className="c-pointer"
            onClick={() => {
              toggleDeleteSuccessModal("close");
            }}
          >
            <CloseIcon />
          </span>
        </div>
        <Modal.Content>
          <div style={{ textAlign: "center", marginTop: "-9px" }}>
            <div className="mb-5">
              {inProgressFlag ? (
                <Success />
              ) : !onHoldFlag ? (
                approveModal ? (
                  <Success />
                ) : (
                  <Success />
                )
              ) : (
                <Success />
              )}
            </div>
            <p className="status-modal-popup-message">
              {/* {t.workOrders.details_view.work_order_no}{' '} */}
              {inCancleFlag
                ? t.workOrders.success_cancel
                : inProgressFlag
                ? t.workOrders.success_progress
                : !onHoldFlag
                ? approveModal
                  ? t.workOrders.success_approve
                  : t.workOrders.success_decline
                : t.workOrders.success_hold}{" "}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>

      <Modal
        className="assets-modal"
        size="tiny"
        open={assignModal}
        onClose={() => {
          setAssignModal(false);
          setValidation({
            people: {
              error: false,
              pattern: (code) => code != "",
              skipped: false,
              message: t.common.required_field,
            },
          });
          setAssignData({
            people: [],
            teams: [],
          });
        }}
      >
        <div className="header-container">
          <span className="assets-header">{t.workOrders.assign_people}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setAssignModal(false);
                setValidation({
                  people: {
                    error: false,
                    pattern: (code) => code != "",
                    skipped: false,
                    message: t.common.required_field,
                  },
                });
                setAssignData({
                  people: [],
                  teams: [],
                });
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          {/* <label>{t.categories.import_categories}</label> */}

          <div className="assignee-data">
            <Form.Field>
              <label className="label-item mt-5">
                {t.workOrders.form.people}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.workOrders.form.people_placeholder}
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                search
                error={validation.people.error && validation.people.skipped}
                // ////allowAdditions
                multiple
                maxHeight={50}
                className="custom-dropdown-menu"
                clearable
                options={options.people}
                value={assignData.people}
                onChange={onValuesChange("people")}
                onOpen={fetchPeopleOptions}
                onBlur={(e) => {
                  if (validation.people.pattern(assignData.people)) {
                    setValidation({
                      ...validation,
                      people: {
                        ...validation.people,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      people: {
                        ...validation.people,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />

              {validation.people.error && validation.people.skipped ? (
                <div className="label-error" style={{ marginTop: "-15px" }}>
                  {validation.people.message}
                </div>
              ) : (
                ""
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item mt-4">
                {t.workOrders.form.teams}
              </label>
              <Dropdown
                placeholder={t.workOrders.form.teams_placeholder}
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                search
                multiple
                clearable
                upward
                className="custom-dropdown-menu"
                // ////allowAdditions
                // onAddItem={handleAddTeam}
                options={options.teams}
                value={assignData.teams}
                onChange={onValuesChange("teams")}
                onOpen={fetchTeamsOptions}
              />
            </Form.Field>
          </div>

          {/* <Input
          id="barcodePopup"
          type="file"
          fluid
          //   value={selectedFile}
          onChange={(e) => {
            if (e.target.files && e.target.files.length) {
              setSelectedFile(e.target.files[0]);
            }
          }}
        />
        <div className="buttun-type-link mt-4" basic onClick={handleDownload}>
          {t.categories.download_sample}
        </div> */}
        </Modal.Content>

        <div
          className={`modal-button-container ${
            selectedLanguage == "en" ? "mr-6" : "ml-10"
          }`}
        >
          <button
            onClick={() => {
              setAssignModal(false);
              setValidation({
                people: {
                  error: false,
                  pattern: (code) => code != "",
                  skipped: false,
                  message: t.common.required_field,
                },
              });
              setAssignData({
                people: [],
                teams: [],
              });
            }}
            className="modal-form-action-button"
          >
            {t.assets.form.cancel}
          </button>

          <button
            onClick={() => handleAssign()}
            className="modal-form-action-button light-blue"
          >
            {t.common.add}
          </button>
        </div>
        {/* <Modal.Actions>
        <Button color="black" onClick={() => handleCategoryCreate()}>
          {t.categories.import}
        </Button>
        <Button negative onClick={() => setModalOpenState(false)}>
          {t.common.cancel}
        </Button>
      </Modal.Actions> */}
      </Modal>
    </div>
  );
};

export default Details;
