import React, { useState, useEffect } from 'react';
import {
  Loader,
  Dimmer,
  Grid,
  Image,
  Divider,
  Modal,
  Button,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import Toggle from 'react-toggle';

import md5 from 'md5';

import Moment from 'react-moment';
import { Country } from 'country-state-city';
import { toast } from 'react-toastify';

import {
  humanize,
  getUserProfileByID,
  IMAGES_URL,
  DeactivatePeople,
  toggle,
  getAssigneWorkOrderForPeople,
  getActiveUsersByRole,
} from '../../../config/functions';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../../components/common/userProfilePlaceholder';
import { selectTranslations } from '../../../config/i18n/slice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CloseIcon } from '../../../../Assets/Icons/svg';
import { fetchPeople } from '../../../../redux/reducers/peopleReducer';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';

function Personal({
  targetedData,
  createdByUserData,
  updatedByUserData,
  attachmentData,
  setRefresh,
  refresh,
  untoggle,
  setTargetData,
  setFilterOptions,
  setSearchTitle,
  setSortData,
  setTogglePreviw
}) {
  const dispatch = useDispatch();
  const t = useSelector(selectTranslations);
  const { userPermissions } = usePermissionsSimplified();
  const educationLevelOptions = [
    {
      key: 0,
      value: 'highSchoolDegree',
      text: t.people.personal_info_form.education_options.high_school,
    },
    {
      key: 1,
      value: 'diplomaDegree',
      text: t.people.personal_info_form.education_options.diploma,
    },
    {
      key: 2,
      value: 'bachelorsDegree',
      text: t.people.personal_info_form.education_options.bachelors,
    },
    {
      key: 3,
      value: 'mastersDegree',
      text: t.people.personal_info_form.education_options.masters,
    },
    {
      key: 4,
      value: 'doctorateDegree',
      text: t.people.personal_info_form.education_options.doctorate,
    },
    {
      key: 5,
      value: 'other',
      text: t.people.personal_info_form.education_options.other,
    },
  ];
  const genderOptions = [
    {
      key: 0,
      value: 'male',
      text: t.people.personal_info_form.gender_options.gender_male,
    },

    {
      key: 1,
      value: 'female',
      text: t.people.personal_info_form.gender_options.gender_female,
    },
  ];
  const martialStatusOptions = [
    {
      key: 0,
      value: 'single',
      text: t.people.personal_info_form.martial_option.single,
    },

    {
      key: 1,
      value: 'married',
      text: t.people.personal_info_form.martial_option.married,
    },
  ];
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  useEffect(() => {
    (async function() {
      if (targetedData?.id) {
        let res = await getAssigneWorkOrderForPeople(
          organisationId,
          targetedData?.id
        );
        if (res && res?.status == 200) {
          setSelectedPeopleWorkOrder(res.data.data || []);
        }
        let userRes = await getActiveUsersByRole(
          ['user', 'admin', 'limited_admin', 'supervisor'],
          organisationId
        );
        if (userRes?.data && Array.isArray(userRes.data)) {
          let list = userRes.data
            .filter((one) => one.id !== targetedData?.id)
            .map((el) => {
              if (el.id !== targetedData?.id)
                return {
                  key: el.id,
                  value: el.id,
                  text: el.firstName + ' ' + el.lastName,
                  image: {
                    avatar: true,
                    // src: `http://gravatar.com/avatar/${md5(
                    //   el.firstName + ' ' + el.lastName
                    // )}?d=identicon`,
                    src: el?.profileImage?.url
                      ? `${IMAGES_URL}${el?.profileImage?.url}`
                      : LetteredAvatarImageUrl(
                          `${el.firstName} ${el.lastName}`
                        ),
                    style: { width: '20px', height: '20px' },
                  },
                };
            });
          setActiveUsersList(list || []);
        }
      }
    })();
  }, [targetedData?.id]);


  const [fetchLoading, setFetchLoading] = useState(false);
  const [deactiveModal, toggleDeactiveModal] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(!targetedData?.blocked);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedPeopleWorkOrder, setSelectedPeopleWorkOrder] = useState([]);
  const [activeUsersList, setActiveUsersList] = useState([]);
  const [selectedUserToReAssign, setSelectedUserToReAssign] = useState('');
  const [data, setData] = useState({
    profile: {},
    attendence: {},
  });

  const [blocked, setBlocked] = useState(targetedData.blocked);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });


  const handleUnassignAndDeactivate = async () => {
    setLoading(true);
    let response = await DeactivatePeople('deactive', targetedData?.id, null);
    if (response && response.status == 200) {
      toast.success(t.people.security_form.user_deactivated);
      setTargetData({
        ...targetedData,
        blocked: true,
      });
      setUpdateModal(false);
      setBlocked(true);
      // setToggleStatus(false);
      // await dispatch(fetchPeople({  role: [
      //   'user',
      //   'supervisor',
      //   'view_only',
      //   'admin',
      //   'requester',
      //   'limited_admin',
      // ],organisationId}))
      setSortData([]);
      setFilterOptions({
        role: [],
        supervisors: [],
        jobTitle: [],
        teams: [],
        status: [],
        joiningDate: {
          value: 'any_day',
          text: 'Any Date',
        },
        rate: {
          from: null,
          to: null,
        },

        contractEnd: {
          from: null,
          to: null,
        },
      });
      setSearchTitle('');
      untoggle();
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
      }
      setToggleStatus(true);
    }
    setLoading(false);
  };

  const handleActivate = async () => {
    setLoading(true);
    let response = await DeactivatePeople('active', targetedData?.id, null);
    if (response && response.status == 200) {
      toast.success(t.people.security_form.user_activated);
      setTargetData({
        ...targetedData,
        blocked: false,
      });
      setActiveModal(false);
      setBlocked(false);
      setTogglePreviw(false)
      // await dispatch(fetchPeople({  role: [
      //   'user',
      //   'supervisor',
      //   'view_only',
      //   'admin',
      //   'requester',
      //   'limited_admin',
      // ],organisationId}))
      setSortData([]);
      setFilterOptions({
        role: [],
        supervisors: [],
        jobTitle: [],
        teams: [],
        status: [],
        joiningDate: {
          value: 'any_day',
          text: 'Any Date',
        },
        rate: {
          from: null,
          to: null,
        },

        contractEnd: {
          from: null,
          to: null,
        },
      });
      setSearchTitle('');
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
      }
      setToggleStatus(false);
    }
    setLoading(false);
  };

  const handleReassignAndDeactivate = async () => {
    if (!selectedUserToReAssign) {
      toast.error(t.people.security_form.select_user);
      return;
    }
    setLoading(true);
    let response = await DeactivatePeople(
      'deactive',
      targetedData?.id,
      selectedUserToReAssign
    );
    if (response && response.status == 200) {
      toast.success(t.people.security_form.user_deactivated);
      setTargetData({
        ...targetedData,
        blocked: true,
      });
      setUpdateModal(false);
      setBlocked(true);
      // setToggleStatus(false);
      // await dispatch(fetchPeople({  role: [
      //   'user',
      //   'supervisor',
      //   'view_only',
      //   'admin',
      //   'requester',
      //   'limited_admin',
      // ],organisationId}))
      setSortData([]);
      setFilterOptions({
        role: [],
        supervisors: [],
        jobTitle: [],
        teams: [],
        status: [],
        joiningDate: {
          value: 'any_day',
          text: 'Any Date',
        },
        rate: {
          from: null,
          to: null,
        },

        contractEnd: {
          from: null,
          to: null,
        },
      });
      setSearchTitle('');
      untoggle();
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
      }
      setToggleStatus(true);
    }
    setLoading(false);
  };

  const updateStatusHandler = async () => {
    if (toggleStatus == true) {
      const response = await DeactivatePeople('active', targetedData?.id, null);
      if (response.status == 200) {
        // toggleUpdateSuccessModal('close');

        // toggleUpdateSuccessModal('close');
        toast.success(t.people.activate_success_statement);
        setRefresh(!refresh);
        untoggle();

        // toast.success(t.people.deactivate_success_statement);

        // toggleUpdateSuccessModal();
      } else if (response.status == 400) {
        // toggleUpdateSuccessModal();
        toast.error(t.people.activate_related_statement);

        setToggleStatus(!targetedData?.blocked);
      } else {
        setToggleStatus(!targetedData?.blocked);
        toast.error('Something went wrong.');
      }
    } else {
      const response = await DeactivatePeople(
        'deactive',
        targetedData?.id,
        null
      );
      if (response.status == 200) {
        // toggleUpdateSuccessModal('close');
        setRefresh(!refresh);
        toast.success(t.people.deactivate_success_statement);
        untoggle();

        // toggleUpdateSuccessModal('close');

        // toggleUpdateSuccessModal();
      } else if (response.status == 400) {
        // toggleUpdateSuccessModal();
        toast.error(t.people.deactivate_related_statement);
        setToggleStatus(!targetedData?.blocked);
      } else {
        setToggleStatus(!targetedData?.blocked);
        toast.error('Something went wrong.');
      }
    }
  };
  useEffect(() => {
    setFetchLoading(true);
    if (targetedData.userProfile) {
      setData({
        ...data,
        profile: targetedData.userProfile,
      });
      setFetchLoading(false);
    } else {
      setFetchLoading(false);
    }
  }, [targetedData]);

  let createdByName = createdByUserData ? (
    <div>
      <span>
        {createdByUserData?.firstName + ' ' + createdByUserData?.lastName}
      </span>
    </div>
  ) : (
    t.common.na
  );

  const educationLevel = educationLevelOptions.find(
    (one) => one.value === data.profile.educationLevel
  );
  const gender = genderOptions.find((one) => one.value === data.profile.gender);
  const martialStatus = martialStatusOptions.find(
    (one) => one.value === data.profile.martialStatus
  );

  const handleAssetStatus = async (targetedData, e) => {
    if (e.target.checked == true) {
      setActiveModal(true);
    } else {
      setUpdateModal(true);
    }
    setToggleStatus(e.target.checked);
    // if (e.target.checked === true) {
    //   let response = await DeactivatePeople('deactive', targetedData?.id, null);
    //   if (response && response.status == 200) {
    //     toast.success(t.people.security_form.user_deactivated);
    //     setToggleStatus(true);
    //     setRefresh(!refresh);
    //     untoggle();
    //   } else if (response.status == 400) {
    //     setToggleStatus(false);
    //     if (response.error && response.error.message) {
    //       toast.error(response.error.message);

    //       // untoggle();
    //     } else {
    //       toast.error(t.people.security_form.something_wrong);
    //     }
    //   }
    // } else {
    //   let response = await DeactivatePeople('active', targetedData?.id, null);
    //   if (response && response.status == 200) {
    //     toast.success(t.people.security_form.user_activated);
    //     setToggleStatus(false);
    //     setRefresh(!refresh);
    //     untoggle();
    //   } else if (response.status == 400) {
    //     setToggleStatus(true);
    //     if (response.error && response.error.message) {
    //       toast.error(response.error.message);
    //       // untoggle();
    //     } else {
    //       toast.error(t.people.security_form.something_wrong);
    //     }
    //   }
    // }
    // setUpdateModal(true);
  };

  const roles= [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,
     
      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,
      
      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,
      
      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,
    
      description: t.people.add_modal.viewer_description,
    },
    {
      key: 2,
      value: 6,
      text: t.people.add_modal.requester,
      description:
       t.people.add_modal.requester_desc
    },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ]

  // const roles = [
  //   {
  //     key: 0,
  //     value: 5,
  //     text: t.people.add_modal.admin,
  //     icon: 'cog',
  //     description: t.people.add_modal.admin_description,
  //   },

  //   {
  //     key: 1,
  //     value: 8,
  //     text: t.people.add_modal.technician,
  //     icon: 'wpforms',
  //     description: t.people.add_modal.user_description,
  //   },
  //   {
  //     key: 2,
  //     value: 9,
  //     text: t.people.add_modal.limited_admin,
  //     icon: 'phone volume',
  //     description: '',
  //   },
  //   {
  //     key: 3,
  //     value: 3,
  //     text: t.people.add_modal.viewer,
  //     icon: 'sitemap',
  //     description: '',
  //   },
  // ];

  let role = roles.find((each) => each.value == targetedData?.role?.id);

  return (
    <div className="preview-details-wrapper">
      {fetchLoading ? (

          <Loader active inline="centered">{t.common.loading}</Loader>

      ) : (
        <>
          {/* <div className="intro">
            <div className="flexbox align-center" style={{ margin: '20px 0' }}>
              <div>
                {attachmentData?.personalPicture?.attributes?.url ? (
                  <div className="d-flex align-items-start">
                    <div className="text-center">
                      <Image
                        circular
                        title={
                          targetedData.firstName + ' ' + targetedData.lastName
                        }
                        src={`${IMAGES_URL}${attachmentData?.personalPicture.attributes.url}`}
                        style={
                          selectedLanguage === 'en'
                            ? { width: '80px', height: '80px', right: '6px' }
                            : { width: '80px', height: '80px', right: '-6px' }
                        }
                        // style={
                        //   index != 0
                        //     ? {
                        //         marginLeft: '-6px',
                        //         width: '34px',
                        //         height: '34px',
                        //       }
                        //     : { width: '34px', height: '34px' }
                        // }
                      />
                    </div>
                    <div
                      className="username-container ml-2"
                      // onClick={() => history.push(`/people?id=${row?.id}`)}
                    >
                      <p className="people-text">
                        {humanize(
                          targetedData.firstName + ' ' + targetedData.lastName
                        )}
                      </p>
                      <p className="people-role text-left">
                        {targetedData?.role?.name
                          ? role?.text || humanize(targetedData?.role?.name)
                          : ''}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-start">
                    <div className="text-center">
                      <UserProfilePlaceholder
                        name={
                          targetedData.firstName + ' ' + targetedData.lastName
                        }
                        width={'80px'}
                        height={'80px'}
                        right={selectedLanguage === 'en' ? '6px' : '-6px'}
                      />
                    </div>
                    <div
                      className="username-container ml-1"
                      // onClick={() => history.push(`/people?id=${row?.id}`)}
                    >
                      <p className="people-text">
                        {humanize(
                          targetedData.firstName + ' ' + targetedData.lastName
                        )}
                      </p>
                      <p
                        className={`people-role ${
                          selectedLanguage == 'en'
                            ? 'text-left mr-2'
                            : 'text-right ml-0'
                        }`}
                      >
                        {targetedData?.role?.name
                          ? role?.text || humanize(targetedData?.role?.name)
                          : ''}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex align-items-start mt-5 mb-7">
              <label className="workorder-intro-label personal-label">
                {t.common.created_by}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {createdByUserData?.firstName
                  ? createdByUserData?.firstName +
                    ' ' +
                    createdByUserData?.lastName
                  : t.common.na}
              </p>
            </div>
          </div>

          <Divider /> */}

          <div className="details-wrapper">
          

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.address}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.address ? data.profile.address : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.gender}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {gender?.text ? gender?.text : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.martial_status}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {martialStatus?.text ? martialStatus?.text : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.number_of_dependents}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.childrenNo
                  ? data.profile.childrenNo
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.education_level}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {educationLevel?.text ? educationLevel?.text : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.filed_of_study}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.fieldOfStudy
                  ? data.profile.fieldOfStudy
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.nationality}
                {':'}
              </label>
              <p className="workorder-intro-value">
              {data?.profile?.nationality
                  ? Country.getCountryByCode(data?.profile?.nationality)?.name
                  : t.common.na}
              </p>
            </div>

            {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label personal-label">
          {t.people.personal_info_form.nationality}
            {':'}
          </label>
          <p className="workorder-intro-value">
          {data.profile.nationality
                        ? Country.getCountryByCode(data.profile.nationality)
                            .name
                        : t.common.na}
          </p>
        </div> */}

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.country_of_birth}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.cob
                  ? Country.getCountryByCode(data?.profile?.cob)?.name
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.date_of_birth}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.dob
                  ? moment(data?.profile?.dob).format('DD.MM.YYYY')
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.passport_no}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.passportNo
                  ? data.profile.passportNo
                  : t.common.na}
              </p>
            </div>
            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.identification_no}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.identificationNo
                  ? data.profile.identificationNo
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.visa_no}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.visaNo ? data.profile.visaNo : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.visa_expire_date}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {}
                {data?.profile?.visaExpireDate
                  ? moment(data?.profile?.visaExpireDate).format('DD.MM.YYYY')
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.emergency_contact}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.emergencyName
                  ? data.profile.emergencyName
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label personal-label">
                {t.people.personal_info_form.emergency_phone}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data?.profile?.emergencyPhone
                  ? data.profile.emergencyPhone
                  : t.common.na}
              </p>
            </div>
{userPermissions?.people?.statusToggle ? 
            <div className="d-flex justify-content-end mt-5">
              <label className="workorder-intro-value people-activated">
                {t.people.status_options.account_activity}
              </label>
              <span className="">
                <Toggle
                  className="people-toggle"
                  checked={toggleStatus}
                  disabled={!targetedData?.confirmed && !targetedData.blocked}
                  onChange={(e) => {
                    setToggleStatus(e.target.checked);
                    handleAssetStatus(targetedData, e);
                  }}
                />
              </span>
            </div> : null}
          </div>

          <Modal
            size={selectedPeopleWorkOrder.length ? '' : 'tiny'}
            className="assets-modal"
            open={updateModal}
            onClose={() => {
              setUpdateModal(false);
              setToggleStatus(true);
            }}
          >
            <div className="header-container">
              {selectedPeopleWorkOrder.length ? (
                <span className="assets-header">{t.people.personal_info_form.assign}</span>
              ) : (
                <span className="assets-header">
                  {t.people.security_form.deactive}
                </span>
              )}
              <div>
                <span
                  className="c-pointer text-right"
                  onClick={() => {
                    setUpdateModal(false);
                    setToggleStatus(true);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            </div>
            <Modal.Content>
              {selectedPeopleWorkOrder.length ? (
                <span className="assets-label ml-1">
                  {targetedData.firstName + '(#' + targetedData.id + ') '}
                  {t.people.security_form.already_has_workorder}
                </span>
              ) : (
                <span className="assets-label ml-1">
                  {t.people.security_form.make_deactive}
                </span>
              )}
              {selectedPeopleWorkOrder.length ? (
                <ul className="deactive_work_order_list">
                  {selectedPeopleWorkOrder.map((one) => {
                    return (
                      <li>
                        <p>
                          {one?.attributes?.title} {` (#${one.id})`}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
              {selectedPeopleWorkOrder.length ? (
                <div className="d-flex mt-7">
                  <p className="status-modal-popup-message mt-3">
                    {t.people.security_form.re_assign_to}:{' '}
                  </p>
                  <Form.Field style={{ minHeight: '60px' }}>
                    <Dropdown
                      style={{ width: '250px' }}
                      placeholder={t.people.security_form.select_one}
                      noResultsMessage={t.common.no_results_found}
                      selection
                      className="ml-4"
                      fluid
                      maxHeight={50}
                      upward
                      options={activeUsersList}
                      value={selectedUserToReAssign}
                      onChange={(e, value) =>
                        setSelectedUserToReAssign(value.value)
                      }
                    />
                  </Form.Field>
                </div>
              ) : null}
            </Modal.Content>
            {selectedPeopleWorkOrder.length ? (
              <div
                className={`modal-button-container mt-3 ${
                  selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
                }`}
              >
                <button
                  onClick={() => {
                    setUpdateModal(false);
                    setToggleStatus(true);
                  }}
                  style={{ color: 'black' }}
                  className="modal-form-action-button"
                >
                  {t.assets.form.cancel}
                </button>

                <button
                  onClick={handleUnassignAndDeactivate}
                  className="modal-form-action-button light-blue"
                >
                  {t.people.security_form.unassign}
                </button>

                <button
                  onClick={handleReassignAndDeactivate}
                  className="modal-form-action-button light-blue ml-1 mr-1  "
                >
                  {t.people.security_form.reassign}
                </button>
              </div>
            ) : (
              // <Modal.Actions>
              //   <Button
              //     color="black"
              //     onClick={() => {
              //       setUpdateModal(false);
              //       setToggleStatus(true);
              //     }}
              //     disabled={loading}
              //   >
              //     {t.common.cancel}
              //   </Button>
              //   <Button
              //     // negative={blocked ? false : true}
              //     // color={blocked ? 'green' : 'red'}
              //     onClick={handleUnassignAndDeactivate}
              //     disabled={loading}
              //   >
              //     {t.people.security_form.unassign}
              //   </Button>
              //   <Button
              //     // negative={blocked ? false : true}
              //     // color={blocked ? 'green' : 'red'}
              //     onClick={handleReassignAndDeactivate}
              //     disabled={loading}
              //   >
              //     {t.people.security_form.reassign}
              //   </Button>
              // </Modal.Actions>

              <div
                className={`modal-button-container mt-3 ${
                  selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
                }`}
              >
                <button
                  onClick={() => {
                    setUpdateModal(false);
                    setToggleStatus(true);
                  }}
                  style={{ color: 'black' }}
                  className="modal-form-action-button"
                >
                  {t.assets.form.cancel}
                </button>

                <button
                  onClick={() => handleUnassignAndDeactivate()}
                  className="modal-form-action-button light-blue"
                >
                  {t.assets.deactivate}
                </button>
              </div>

              // <Modal.Actions>
              //   <Button
              //     color="black"
              //     onClick={() => {
              //       setUpdateModal(false);
              //       setToggleStatus(true);
              //     }}
              //     disabled={loading}
              //   >
              //     {t.common.cancel}
              //   </Button>
              //   <Button
              //     // negative={blocked ? false : true}
              //     color={'red'}
              //     onClick={handleUnassignAndDeactivate}
              //     disabled={loading}
              //   >
              //     {t.people.security_form.deactive}
              //   </Button>
              // </Modal.Actions>
            )}
          </Modal>

          <Modal
            size="tiny"
            className="assets-modal"
            open={activeModal}
            onClose={() => {
              setActiveModal(false);
              setToggleStatus(false);
            }}
          >
            <div className="header-container">
              <span className="assets-header">
                {t.people.security_form.activete}
              </span>
              <div>
                <span
                  className="c-pointer text-right"
                  onClick={() => {
                    setActiveModal(false);
                    setToggleStatus(false);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            </div>

            <Modal.Content>
              <span className="assets-label ml-1">
                {`${t.people.activate_statement} "${targetedData?.firstName} ${targetedData?.lastName}"`}
              </span>
              {/* <p className="status-modal-popup-message">
                {t.people.security_form.make_active}
              </p> */}
            </Modal.Content>
            <div
              className={`modal-button-container mt-3 ${
                selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
              }`}
            >
              <button
                onClick={() => {
                  setActiveModal(false);
                  setToggleStatus(false);
                }}
                style={{ color: 'black' }}
                className="modal-form-action-button"
              >
                {t.assets.form.cancel}
              </button>

              <button
                onClick={() => handleActivate()}
                className="modal-form-action-button light-blue"
              >
                {t.assets.activate}
              </button>
            </div>
            {/* <Modal.Actions>
              <Button
                color="black"
                onClick={() => {
                  setActiveModal(false);
                  setToggleStatus(false);
                }}
                disabled={loading}
              >
                {t.common.cancel}
              </Button>
              <Button
                // negative={blocked ? false : true}
                color={'green'}
                onClick={handleActivate}
                disabled={loading}
              >
                {t.people.security_form.yes_active}
              </Button>
            </Modal.Actions> */}
          </Modal>

          {/* <Modal
            size="tiny"
            open={updateModal}
            className="assets-modal"
            onClose={() => {
              setUpdateModal(false);
              setToggleStatus(!targetedData?.blocked);
            }}
            style={{ minHeight: '191px' }}
          >
            <div className="header-container">
              <span className="assets-header">
                {targetedData?.blocked == false
                  ? t.people.security_form.deactive
                  : t.people.security_form.activete}
              </span>
              <div>
                <span
                  className="c-pointer text-right"
                  onClick={() => {
                    setUpdateModal(false);
                    setToggleStatus(!targetedData?.blocked);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            </div>

            <Modal.Content>
              <span className="assets-label ml-1">
                {targetedData?.blocked == false
                  ? `${t.people.deactivate_statement} "${targetedData?.firstName} ${targetedData?.lastName}"`
                  : `${t.people.activate_statement} "${targetedData?.firstName} ${targetedData?.lastName}"`}
              </span>
            </Modal.Content>
            <div
              className={`modal-button-container mt-3 ${
                selectedLanguage == 'en' ? 'mr-6' : 'ml-6'
              }`}
            >
              <button
                onClick={() => {
                  setUpdateModal(false); // Close the delete modal
                  setToggleStatus(!targetedData?.blocked);
                }}
                style={{ color: 'black' }}
                className="modal-form-action-button"
              >
                {t.assets.form.cancel}
              </button>

              <button
                onClick={() => {
                  setUpdateModal(false);
                  updateStatusHandler();
                }}
                className="modal-form-action-button light-blue"
              >
                {targetedData?.blocked == false
                  ? t.assets.deactivate
                  : t.assets.activate}
              </button>
            </div>
          </Modal> */}
        </>
      )}
    </div>
  );
}

export default Personal;
