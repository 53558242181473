import React, { useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import {
  createNewSet,
  getAllActiveSets,
  getAllParentLocations,
  getAllParts,
  getAllSets,
  updateSet,
} from '../../config/functions';

import { useDispatch, useSelector } from 'react-redux';
import { useCancelModal } from '../../../hooks/Messages/useCancelModal';

import { selectTranslations } from '../../config/i18n/slice';

import { toast } from 'react-toastify';

import { CloseIcon } from '../../../Assets/Icons/svg';
import ToolTip from 'react-power-tooltip';
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import SetForm from './SetForm';
import { fetchSetsInitData } from '../../../redux/reducers/setsReducer';
import { cloneDeep } from 'lodash';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';
import { useEffect } from 'react';
import { Resizable } from 're-resizable';

/*
 * The Edit function in JavaScript is used to handle editing sets and parts data, including adding, updating, and canceling changes.
 */
function Edit({ toggled, untoggle, setRefresh, refresh, targetedData,role,allIds }) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    locations: [],});
  const [partsData, setPartsData] = useState([]);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [allPartsData, setAllPartsData] = useState([]);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [prevSetsData, setPrevSetsData] = useState([]);
  const [allSetsData, setAllSetsData] = useState([]);
  const [selectedTablePartData, setSelectedTablePartData] = useState([]);
  const [selectedTableSetData, setSelectedTableSetData] = useState([]);
  const [addSetModal, setAddSetModal] = useState(false);
  const [initPartsData, setInitPartsData] = useState([]);
  const [initSetsData, setInitSetsData] = useState([]);

  const [setsData, setSetsData] = useState([]);

  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [editSetData, setEditData] = useState();
  const [addPartModal, setAddPartModal] = useState(false);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const fetchLocations = async () => {
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin" || role === "admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setDropdownOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The `addParts` function retrieves all parts data, filters out selected parts, and sets the necessary state variables for further processing
   */
  const addParts = async (selectedPartsData = []) => {
    setInitChange(true);
    const fetchData = await getAllParts(organisationId, '', 1, 99999,[],[],[],[],[],[],[],[],'',[],role,allIds);

    if (fetchData?.response) {
      const allInitData = fetchData.response.map((part) => ({
        id: part.id,
        name: part.attributes.name,
        totalCost: part.attributes.unitCost,
        quantity: 1,
        unitCost: part.attributes.unitCost,
        unitType: part.attributes.measuringUnit,
        selectedPart: false, // Check if part is selected
      }));

      const filteredParts = allInitData
        ?.map((part) => {
          const isSelected = selectedPartsData.some(
            (selectedPart) => selectedPart.id === part.id
          );

          if (isSelected) {
            return null; // Skip this part
          } else {
            return {
              selectedPart: false,
              id: part.id,
              name: part.name,
              totalCost: part.totalCost,
              unitType: part.unitType,
              quantity: 1,
              unitCost: part.unitCost,
            };
          }
        })
        .filter((part) => part !== null);

      // const sortedParts = [
      //   ...filteredParts.filter(part => part.selectedPart),
      //   ...filteredParts.filter(part => !part.selectedPart)
      // ];

      setPartsData(filteredParts);
      setInitPartsData(filteredParts);
      setAllPartsData(allInitData);
    } else {
      toast.error(t.common.something_wrong);
    }
    // setLoading(false);
    setAddPartModal(true);
  };

  /*
   * The function `addSets` retrieves all sets data, filters out selected sets, and updates the state with the filtered sets data.
   */
  const addSets = async (selectedSetsData = []) => {
    setInitChange(true);
    // setLoading(true);
    const fetchData = await getAllActiveSets(organisationId);


    if (fetchData) {
      const allInitData = fetchData.map((set) => ({
        id: set.id,
        name: set.name,
        totalCost: set.totalCost,
        quantity: 1,
        unitCost: set.totalCost,
        selectedSet: false,
        disable: set?.includeSet,
      }));

      const filteredSets = allInitData
        ?.map((part) => {
          const isSelected = selectedSetsData.some(
            (selectedPart) => selectedPart.id === part.id
          );

          if (isSelected) {
            return null; // Skip this part
          } else {
            return {
              selectedSet: false,
              id: part.id,
              name: part.name,
              disable: part?.disable,
              totalCost: part.totalCost,
              quantity: 1,
              unitCost: part.unitCost,
            };
          }
        })
        .filter((part) => part !== null);

      // const filteredSets = fetchData.response.map(set => ({
      //   id: set.id,
      //   name: set.attributes.name,
      //   totalCost: set.attributes.totalCost,
      //   quantity: 1,
      //   unitCost: set.attributes.totalCost,
      //   selectedSet: selectedSetsData.some(el => el?.id === set.id), // Check if set is selected
      // }));
      // const sortedParts = [
      //   ...filteredSets.filter(part => part.selectedSet),
      //   ...filteredSets.filter(part => !part.selectedSet)
      // ];

      setSetsData(filteredSets);
      setInitSetsData(filteredSets);
      setAllSetsData(allInitData);
    } else {
      toast.error(t.common.something_wrong);
    }
    // setLoading(false);
    setAddSetModal(true);
  };

  /* The above code is a React useEffect hook that runs when the `toggled` state changes. It checks if
`toggled` is true and then formats the `targetedData` by mapping over the `parts` and `sets` arrays
within its attributes. */
  useEffect(() => {
    if (toggled) {
      fetchLocations()
      const formattedParts = targetedData?.attributes?.parts?.map((part) => {
        return {
          selectedPart: true,
          compId: part?.id,
          id: part?.part?.data?.id,
          name: part?.part?.data?.attributes?.name,
          totalCost: part?.totalCost,
          quantity: part?.quantity,
          unitType: part?.part?.data?.attributes?.measuringUnit,
          unitCost: part?.part?.data?.attributes?.unitCost,
        };
      });

      const formattedSets = targetedData?.attributes?.sets?.map((part) => {
        // Determine if the part is selected by checking if its id exists in selectedPartsData

        // Constructing the formatted part object
        return {
          selectedPart: true,
          compId: part?.id,
          id: part.set.data.id,
          name: part.set.data.attributes.name,
          totalCost: part.totalCost,
          quantity: part.quantity,
          unitCost: part.totalCost / part.quantity,
        };
      });

      addParts(formattedParts);
      setSelectedTablePartData(formattedParts);
      addSets(formattedSets);
      setSelectedTableSetData(formattedSets);
      setEditData({
        name: targetedData?.attributes?.name,
        locations: targetedData?.attributes?.location?.data?.id,
        parts: formattedParts,
        sets: formattedSets,
      });
    }
  }, [toggled]);

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const { initData } = useSelector((state) => ({
    initData: state.sets.initData,
  }));

  const [initChange, setInitChange] = useState(false);

  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);

  /*
   * The `handleAddSet` function in JavaScript updates a set with new data after performing validation checks and update the sets.
   */
  const handleAddSet = async (values, resetForm) => {
    let addedSetsData = cloneDeep(values);
    const numParts = addedSetsData.parts.length;
    const numSets = addedSetsData.sets.length;

    if ((numParts === 1 && numSets === 1) || numSets >= 2 || numParts >= 2) {
      let partsId = [];
      const transformedArray = addedSetsData?.parts?.map((item) => {
        partsId.push(item.id);
        return {
          id: item?.compId,
          part: item.id,
          quantity: item.quantity,
          totalCost: item.totalCost,
        };
      });

      const transformedSetArray = addedSetsData?.sets?.map((item) => ({
        set: item.id,
        id: item?.compId,
        quantity: item.quantity,
        totalCost: item.totalCost,
      }));

      const response = await updateSet(
        targetedData?.id,
        addedSetsData?.name,
        transformedArray,
        transformedSetArray,
        organisationId,
        partsId,
        addedSetsData?.locations,
      );

      if (response.status == 200) {
        setRefresh(!refresh);
        setButtonStatus(0);
        untoggle();
        toast.success(t.sets.update_success);
        resetForm();
        // toggleSuccessModal(true);
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(t.sets.unique);
          // untoggle();
        } else {
          toast.error(t.sets.unique);
        }
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
      }
    } else {
      toast.error(t.sets.add_valiadtion);
    }
  };

  /*
   * The `cancelHandler` function adjusts modal width, toggles off a modal, and resets certain state
   * variables.
   */
  const cancelHandler = () => {
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <div
        style={{ maxWidth: `${modalWidth}px` }}
        clearing
        className={
          toggled ? 'aside-modal active procedure' : 'aside-modal procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.sets.edit_set} #{targetedData?.attributes?.countId }
              </div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>

          {buttonStatus == 1 ? (
            <Dimmer active inverted>
              <Loader inverted content={t.common.loading} />
            </Dimmer>
          ) : (
            ''
          )}

          <SetForm
           fetchLocations={ fetchLocations}
            initialFormValues={editSetData}
            initChange={initChange}
            allIds={allIds}
            dropdownOptions={dropdownOptions}
            role={role}
            setDropdownOptions={setDropdownOptions}
            setInitChange={setInitChange}
            buttonStatus={buttonStatus}
            cancelHandler={cancelHandler}
            setNewCancelModal={setNewCancelModal}
            handleAddSet={handleAddSet}
            setPartsData={setPartsData}
            partsData={partsData}
            setInitPartsData={setInitPartsData}
            initPartsData={initPartsData}
            setInitSetsData={setInitSetsData}
            initSetsData={initSetsData}
            setSetsData={setSetsData}
            setsData={setsData}
            edit={true}
            setAllSetsData={setAllSetsData}
            allSetsData={allSetsData}
            prevSetsData={prevSetsData}
            prevPartsData={prevPartsData}
            setPrevPartsData={setPrevPartsData}
            setPrevSetsData={setPrevSetsData}
            setSelectedTableSetData={setSelectedTableSetData}
            setSelectedTablePartData={setSelectedTablePartData}
            selectedTableSetData={selectedTableSetData}
            selectedTablePartData={selectedTablePartData}
            setAllPartsData={setAllPartsData}
            allPartsData={allPartsData}
          />
        </Resizable>
      </div>

      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>
      {/* cancel modal */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
               <p style={{lineHeight:"24px"}}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
    </>
  );
}

export default Edit;
