import React, { useEffect, useState } from "react";
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Modal,
  Input,
  DropdownItem,
  DropdownMenu,
  Loader,
} from "semantic-ui-react";
import { useLocation, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import { useDebounce } from "use-debounce";
import Moment from "react-moment";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/CommonPagination";
import _ from "lodash";

import {
  getAllCategories,
  updateCategory,
  deleteCategory,
  IMAGES_URL,
  currencyDecimalPlaces,
} from "../../config/functions";
import { toast } from "react-toastify";
import moment from "moment";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import AddCategoryModal from "../../components/modal/category/CategoryCreateModal";
import AddBulkCategoryModal from "../../components/modal/category/UploadBulkCategoryModal";
import CategoryBulkUploadResult from "../../components/modal/category/CategoryBulkUploadResult";
import useGetCurrentTimeAccZone from "../../../hooks/useGetCurrentTimeAccZone";
import DateConvert from "../../../utilFunctions/DateFormat";
import {
  AssetIcon,
  ProcedurePlusIcon,
  WhiteDownChevron,
  ShareIcon,
  DownloadIcon,
  PdfIcon,
  UploadIcon,
  ArrowLeft,
  ArrowRight,
  Fail,
  CloseIcon,
  CategoryIcon,
  CategoryEditIcon,
  DeleteIcon,
  Close,
  SearchIcon,
  CategoryBlueIcon,
  SortIcon,
  PartsBlueIcon,
  PartIcon,
  ActionBtnIcon,
} from "../../../Assets/Icons/svg";
import DownloadExcelFileWithData from "../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../services/DownloadPdfFileWithData";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import useDeletePermissionModal from "../../../hooks/Messages/useDeletePermissionModal";
import useDeleteResultModal from "../../../hooks/Messages/useDeleteResultModal";
import Asterisk from "../../components/common/Asterisk";
import ToolTip from "react-power-tooltip";
import {
  fetchAllCategoryData,
  fetchCategories,
  fetchCategoriesInitData,
  fetchCategoriesWithFilter,
  setData,
} from "../../../redux/reducers/categoryReducer";
import { Pages } from "@material-ui/icons";
import {
  fetchAllSetsData,
  fetchSetWithFilter,
  fetchSetsInitData,
} from "../../../redux/reducers/setsReducer";
import EmptyFilter from "../Parts/emptyFilter";
import Add from "./add";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Preview from "./preview";
import { ref } from "yup";
import Edit from "./edit";
import Animation from "../../components/common/CustomLoader";

const customStyles = {
  headCells: {
    style: {
      paddingTop: "11px", // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: "16px", // override the cell padding for data cells
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};

/* The `Categories` function is a component that handles displaying and managing categories with features like sorting, filtering, pagination, and modals for editing and deleting categories.*/
export const Sets = () => {
  const { userPermissions } = usePermissionsSimplified();
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const history = useHistory();

  const curentUrl = useLocation();

  const queryParams = new URLSearchParams(curentUrl.search);
  const partId = queryParams.get("id");

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      location.subLocations.forEach((subLocation) => {
        if (!allIds.some((inv) => inv == subLocation?.id)) {
          allIds.push(subLocation?.id);
        }
      });
    });

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return formattedValue;
  };
  const dispatch = useDispatch();
  const [targetedSet, setTargetedSet] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [sortData, setSortData] = useState();
  const [loader, setloader] = useState(false);
  useGetCurrentTimeAccZone();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const [searchTitle, setSearchTitle] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [showActionTooltip, setShowActionTooltip] = useState(false);
  const [debounceValue] = useDebounce(searchTitle, 500);

  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  /* The function `handleSort` sorts data based on a specified column and sort direction */
  const handleSort = async (column, sortDirection) => {
    setloader(true);
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchSetWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        sort: sort,
        locationId: "",
        areaId: "",
        role,
        allIds,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  /* The function `handleRemoveSorting` for remove sorts data */
  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchSetWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        sort: sort,
        locationId: "",
        areaId: "",
        role,
        allIds,
      })
    );

    setloader(false);
  };

  /**
   * Table Fields
   */
  const columns = [
    {
      width: selectedLanguage === "en" ? "115px" : "132px",
      name: t.assets.table.ID,
      sortField: "countNumId",
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedSet(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.countId}
        </span>
      ),
    },
    {
      minWidth: "350px",
      name: t.categories.table.name,
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedSet(row);
            setTogglePreviw(true);
          }}
          className="d-flex align-items-center"
        >
          <div>
            <span className="id-text-style">
              {row?.attributes?.name
                ? DisplayFormattedText(row?.attributes?.name, 25)
                : ""}
            </span>
          </div>
        </div>
      ),
    },
    {
      minWidth: "180px",
      name: t.sets.table.no_of_parts,
      sortField: "totalParts",
      sortable: true,
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedSet(row);
            setTogglePreviw(true);
          }}
          className="d-flex align-items-center"
        >
          <div>
            <span className="id-text-style">
              {row?.attributes?.totalParts ? row?.attributes?.totalParts : 0}{" "}
              {t.sets.table.items}
            </span>
          </div>
        </div>
      ),
    },
    {
      minWidth: "180px",
      name: t.sets.table.total_cost,
      sortField: "totalCost",
      sortable: true,
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedSet(row);
            setTogglePreviw(true);
          }}
          className="d-flex align-items-center"
        >
          <div>
            <span className="id-text-style">
              {row?.attributes?.totalCost
                ? handleDecimalPoints(row?.attributes?.totalCost)
                : 0}{" "}
              {organisationCurrency}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: t.parts.table.created_on,
      sortField: "createdAt",
      sortable: true,
      minWidth: "144px",
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedSet(row);
            setTogglePreviw(true);
          }}
        >
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.createdAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.createdAt} />
          </div>
        </div>
      ),
    },

    {
      name: t.parts.table.updated_on,
      sortField: "updatedAt",
      sortable: true,
      minWidth: selectedLanguage === "en" ? "144px" : "170px",
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedSet(row);
            setTogglePreviw(true);
          }}
        >
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.updatedAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.updatedAt} />
          </div>
        </div>
      ),
    },
  ];

  const [toggleAsideModal, setToggleAsideModal] = useState(false);

  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetching, setFetchLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [targetedId, setTargetedId] = useState(null);
  const [categoryEditModal, setCategoryEditModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [categoryUploadModal, setCategoryUploadModal] = useState(false);
  const [categoryUploadResultModal, setCategoryUploadResultModal] = useState(
    false
  );
  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.sets.data,
    isLoading: state.sets.isLoading,
    error: state.sets.error,
    initData: state.sets.initData,
    page: state.sets.page,
    pageSize: state.sets.pageSize,
    total: state.sets.total,
    initLoading: state.sets.initLoading,
  }));

  const [errorMsg, setErrorMsg] = useState("");
  const [bulkUploadResultData, setBulkUploadResultData] = useState([]);

  /* Excel and Pdf file Headers Data */
  const headers = [
    { label: t.categories.table.ID, key: "id" },
    { label: t.categories.table.name, key: "name" },
    { label: t.sets.table.no_of_parts, key: "no_of_parts" },
    { label: t.sets.table.total_cost, key: "total_cost" },
    { label: t.parts.table.created_on, key: "CreatedAt" },
    { label: t.parts.table.updated_on, key: "UpdatedAt" },
  ];

  /* Fetching initial data for categories based on the `organisationId`. */

  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await dispatch(
        fetchSetsInitData({ organisationId, role, allIds })
      );

      if (fetchData) {
        // setData(fetchData);
        // setInitData(fetchData);
        setFetchLoading(false);
        if (partId) {
          const fetchAllData = await dispatch(
            fetchAllSetsData({ organisationId, role, allIds })
          );

          let selectedPart = fetchAllData.payload.response.find(
            (each) => each.attributes?.countId == partId
          );
          if (selectedPart) {
            setTargetedSet(selectedPart);
            setTogglePreviw(true);
          }
        }
      }
    };

    fetchData();
  }, [partId]);

  /* Fetching Filter data for categories based on the `organisationId`. */
  useEffect(() => {
    (async function() {
      setloader(true);

      await dispatch(
        fetchSetWithFilter({
          organisationId,
          searchName: debounceValue,
          page: "1",
          pageSize: "25",
          sort: sortData,
          locationId: "",
          areaId: "",
          role,
          allIds,
        })
      );

      setloader(false);
    })();
  }, [debounceValue, refresh]);

  /* The function `downloadXLS` fetches sets data, formats it, and downloads it as an Excel file. */
  const downloadXLS = async () => {
    let csvData = [];

    let fileData = data;

    if (debounceValue == "") {
      let mydata = await dispatch(
        fetchAllSetsData({ organisationId, role, allIds })
      );

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }

    for (let i = 0; i < fileData.length; i++) {
      let sets_data = [];

      sets_data.id = fileData[i].id;
      sets_data.name = fileData[i].attributes.name || t.common.na;
      sets_data.no_of_parts = fileData[i].attributes?.totalParts
        ? fileData[i].attributes?.totalParts
        : "0";
      sets_data.total_cost = fileData[i].attributes?.totalCost
        ? fileData[i].attributes?.totalCost
        : "0";
      sets_data.createdAt =
        moment(fileData[i].attributes.createdAt).format("DD-MM-YYYY LT") ||
        t.common.na;
      sets_data.updatedAt =
        moment(fileData[i].attributes.updatedAt).format("DD-MM-YYYY LT") ||
        t.common.na;

      csvData.push([
        sets_data.id,
        sets_data.name,
        sets_data.no_of_parts,
        sets_data.total_cost,
        sets_data.createdAt,
        sets_data.updatedAt,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 25 }, // Title column
      { wch: 15 }, // Description column
      { wch: 15 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
    ];
    DownloadExcelFileWithData(headers, csvData, csvColumnWidths, t.sets.title);
  };

  /*
   * The function `generatePDF` fetches category data, formats it, and then downloads a PDF file with the data.
   */
  const generatePDF = async () => {
    let csvData = [];

    let fileData = data;

    if (debounceValue == "") {
      let mydata = await dispatch(
        fetchAllSetsData({ organisationId, role, allIds })
      );

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    for (let i = 0; i < fileData.length; i++) {
      let sets_data = [];

      sets_data.id = fileData[i].id;
      sets_data.name = fileData[i].attributes.name || t.common.na;
      sets_data.createdBy =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;
      sets_data.createdAt =
        moment(fileData[i].attributes.createdAt).format("DD-MM-YYYY LT") ||
        t.common.na;
      sets_data.updatedAt =
        moment(fileData[i].attributes.updatedAt).format("DD-MM-YYYY LT") ||
        t.common.na;

      csvData.push([
        sets_data.id,
        sets_data.name,
        sets_data.createdBy,
        sets_data.createdAt,
        sets_data.updatedAt,
      ]);
    }
    DownloadPdfFileWithData(headers, csvData, "Categories");
  };

  /*
   * The function `handleCategoryEdit` updates a category with a new name and displays success or error messages accordingly.
   */
  const handleCategoryEdit = async () => {
    if (categoryName && categoryName.trim()) {
      let res = await updateCategory(targetedId, categoryName.trim());
      if (res && res.status === 200) {
        toast.success(t.categories.category_updated_success);
        await dispatch(
          fetchCategoriesWithFilter({
            organisationId,
            searchName: debounceValue,
            page: page,
            pageSize: pageSize,
            sort: sortData,
            locationId: "",
            areaId: "",
            role,
            allIds,
          })
        );
        setCategoryName("");
        setTargetedId(null);
        setCategoryEditModal(false);
      } else if (res && res.status === 400) {
        // toast.error(t.categories.category_already_exist);
        setErrorMsg(t.categories.category_already_exist);
      } else {
        toast.error(t.categories.not_updated);
      }
    } else {
      toast.error(t.categories.add_category_name);
    }
  };

  /*
   * The function `handleDeleteCategory` deletes a category based on the targeted ID.
   */
  const handleDeleteCategory = async () => {
    if (targetedId) {
      let res = await deleteCategory(targetedId, categoryName.trim());

      if (res && res.status === 200) {
        toast.success(t.categories.category_delete_success);
        await dispatch(
          fetchCategoriesWithFilter({
            organisationId,
            searchName: debounceValue,
            page: page,
            pageSize: pageSize,
            sort: sortData,
            locationId: "",
            areaId: "",
            role,
            allIds,
          })
        );
      } else {
        toast.error(t.categories.cannot_delete_category_statement);
      }
    } else {
      setDeleteResultModal(true);
    }
    setTargetedId(null);
    setDeleteModal(false);
  };

  /*
   * The above code is a React component that displays a list of categories with various functionalities such as filtering, sorting, pagination, and adding/editing categories.
   */
  return (
    <>
      <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {" "}
            <PartIcon />
            {t.parts.title_parts}
          </Header>
          <div></div>

          {userPermissions?.part?.create && initData?.length > 0 ? (
            <Button
              icon="add"
              primary
              className={selectedLanguage == "en" ? "mr-7" : "ml-7"}
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.sets.create_set}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
        {initData?.length > 0 ? (
          <div className="new-listing-filter pb-0 mt-8 filters-wrapper mb-0 justify-content-between align-items-start">
            <div className="filters">
              <div className="text-right mb-5 pb-5 d-flex justify-content-between align-items-start views">
                <div className="list-view-option">
                  <p className="single-view mb-0">
                    <NavLink to="/parts">{t.parts.tab.first}</NavLink>
                  </p>
                  <p className="single-view mb-0">
                    <NavLink to="/sets">{t.parts.tab.second}</NavLink>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{ gap: "23px" }}
              className="table-searchbar d-flex justify-content-end"
            >
              <div className="work-order-upload">
                <span className="search-svg">
                  <SearchIcon />
                </span>
                <Input
                  icon={{ name: "search" }}
                  placeholder={t.workOrders.filter.search}
                  value={searchTitle}
                  onChange={(e) => {
                    setSearchTitle(e.target.value);
                  }}
                />
              </div>
              <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2">
                <UncontrolledDropdown className="mr-2" direction="down">
                  <DropdownToggle
                    style={{ position: "relative", cursor: "pointer" }}
                    onMouseOver={() => setShowActionTooltip(true)}
                    onMouseLeave={() => setShowActionTooltip(false)}
                    color="primary"
                    className="option-toggle"
                  >
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={
                        selectedLanguage == "ar" ? "top right" : "top left"
                      }
                      show={showActionTooltip}
                      textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                      arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                      fontSize="12px"
                      fontWeight="400"
                      fontFamily={
                        selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                      }
                      padding="4px 4px 4px 8px"
                      borderRadius="5px"
                      static
                      moveRight="10px"
                      moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
                    >
                      <span className="work-order-tooltip">
                        {t.common.page_action}
                      </span>
                    </ToolTip>
                    <ActionBtnIcon />{" "}
                  </DropdownToggle>
                  <DropdownMenu
                    className={selectedLanguage == "ar" ? "rtl" : ""}
                  >
                    {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon /> {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}
                    <DropdownItem className="divider" />
                    <DropdownItem onClick={() => downloadXLS()}>
                      <DownloadIcon /> {t.common.download_sheet}
                    </DropdownItem>
                    {/* <DropdownItem className="divider" />
                  <DropdownItem onClick={() => generatePDF()}>
                    <PdfIcon /> {t.common.download_pdf}
                  </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="new-listing-container">
        {initLoading || initData.length < 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            <Animation />
            {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
          </div>
        ) : (
          // <div>
          //   <Placeholder fluid>
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //   </Placeholder>
          // </div>
          <div className="new-listing-filter">
            {initData?.length > 0 ? (
              <>
                {error ? (
                  <div className="mt-8 mb-8 ">
                    <Animation />
                  </div>
                ) : (
                  <DataTable
                    className="datatable-div"
                    columns={columns}
                    onSort={handleSort}
                    customStyles={customStyles}
                    data={data}
                    fixedHeader
                    noDataComponent={
                      <div className="no-new-listing-container">
                        <div className="">
                          <p className="no-new-listing-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    }
                    direction={selectedLanguage === "en" ? "ltr" : "rtl"}
                    onRowClicked={(row) => {
                      setTargetedSet(row);
                      setTogglePreviw(true);
                    }}
                    sortIcon={
                      sortData ? (
                        <div>
                          <span>
                            <SortIcon />
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSorting();
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )
                    }
                    persistTableHead={true}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    progressPending={loader}
                    progressComponent={
                      <div className="mt-8 mb-8 ">{t.common.loading}</div>
                    }
                  />
                )}

                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={total}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={page}
                    limit={pageSize}
                    handleNext={async () => {
                      setloader(true);
                      await dispatch(
                        fetchCategoriesWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page + 1,
                          pageSize: pageSize,
                          sort: sortData,
                          locationId: "",
                          areaId: "",
                          role,
                          allIds,
                        })
                      );
                      setloader(false);
                    }}
                    handlePrevious={async () => {
                      setloader(true);
                      await dispatch(
                        fetchCategoriesWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page - 1,
                          pageSize: pageSize,
                          sort: sortData,
                          locationId: "",
                          areaId: "",
                          role,
                          allIds,
                        })
                      );
                      setloader(false);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="">
                <EmptyFilter />
                <div className="no-data-container">
                  <div className="no-work-orders pb-15">
                    <PartsBlueIcon />
                    <p className="no-data-text">
                      {t.common.there_no}{" "}
                      <span
                        className="link-text"
                        style={{ position: "relative", cursor: "pointer" }}
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        <ToolTip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="top left"
                          show={showTooltip}
                          textBoxWidth={
                            selectedLanguage == "ar" ? "110px" : "90px"
                          }
                          arrowAlign="start"
                          fontSize="14px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="0px 0px 0px 8px"
                          borderRadius="5px"
                          static
                          moveRight={selectedLanguage == "ar" ? "90px" : "30px"}
                          moveUp="-1px"
                        >
                          <span className="work-order-tooltip">
                            {t.sets.how_to_add}
                          </span>
                        </ToolTip>
                        {" " + t.sets.sets_small + " "}
                      </span>{" "}
                      {t.common.added}
                    </p>
                    {userPermissions?.part?.create ? (
                      <button
                        className="work-order-button-transparent"
                        onClick={() => {
                          setToggleAsideModal(true);
                        }}
                      >
                        {t.sets.start_add}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* <Add
        toggled={toggleAsideModal}
        untoggle={() => {
          setToggleAsideModal(false);
        }}
        setListSearchTitle={setSearchTitle}
        setSortData={setSortData}
        setRefresh={setRefresh}
        refresh={refresh}
      /> */}
        {toggleAsideModal && (
          <Add
            toggled={toggleAsideModal}
            role={role}
            allIds={allIds}
            untoggle={() => {
              setModalWidth(Math.max(window.innerWidth / 2, 514));
              setToggleAsideModal(false);
            }}
            setListSearchTitle={setSearchTitle}
            setSortData={setSortData}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        )}
        {toggleEditModal && (
          <Edit
            role={role}
            allIds={allIds}
            targetedData={targetedSet}
            toggled={toggleEditModal}
            untoggle={() => {
              setTargetedSet();
              setModalWidth(Math.max(window.innerWidth / 2, 514));
              setToggleEditModal(false);
            }}
            setListSearchTitle={setSearchTitle}
            setSortData={setSortData}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        )}

        <Preview
          targetedData={targetedSet}
          toggled={togglePreview}
          setModalWidth={setModalWidth}
          modalWidth={modalWidth}
          setRefresh={setRefresh}
          refresh={refresh}
          // approveOrder={approveOrder}
          edit={() => {
            setTogglePreviw(false);
            setToggleEditModal(true);
          }}
          // setFilterOptions={setFilterOptions}
          // setSearchTitle={setSearchTitle}
          // setSelectedDates={setSelectedDates}
          // declineOrder={declineOrder}
          // approveLoading={buttonsLoading.approved}
          // declineLoading={buttonsLoading.declined}
          untoggle={() => {
            setTargetedSet();
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            setTogglePreviw(false);
            history.push("/sets");
          }}
        />

        {/* Modal component for editing category. */}
        {/* <Modal
        size="tiny"
        open={categoryEditModal}
        className="assets-modal"
        onClose={() => {
          setCategoryEditModal(false);
          setErrorMsg('');
        }}
      >
        <div className="header-container">
          <span className="assets-header">{t.categories.edit_category}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setErrorMsg('');
                setCategoryEditModal(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="p-2 mt-2">
          <label className="assets-label" style={{ lineHeight: '22px' }}>
            {t.categories.category_name}
          </label>
          <span className="label-item d-inline">
            {' '}
            <Asterisk color="#172B4D" />
          </span>
          <div className="category-input">
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.categories.add_category_name}
              value={categoryName}
              onChange={(e) => {
                setCategoryName(e.target.value);
                setErrorMsg('');
              }}
            />
            {errorMsg ? <p className="mt-2 text-danger">{errorMsg}</p> : null}
          </div>
        </Modal.Content>

        <div
          className={`modal-button-container mt-4 ${
            selectedLanguage == 'en' ? 'mr-8' : 'ml-10'
          }`}
        >
          <button
            className="modal-form-action-button black"
            onClick={() => {
              setErrorMsg('');
              setCategoryEditModal(false);
            }}
          >
            {t.assets.form.cancel}
          </button>

          <button
            onClick={() => handleCategoryEdit()}
            className="modal-form-action-button blue"
          >
            {t.categories.save}
          </button>
        </div>
      </Modal> */}

        {/* Modal component for add category. */}
        {/* <AddCategoryModal
        isOpen={toggleAsideModal}
        setModalOpenState={setToggleAsideModal}
        handleSuccess={() =>
           dispatch(
            fetchCategoriesWithFilter({
              organisationId,
              searchName: debounceValue,
              page: page,
              pageSize: pageSize,
              sort: sortData,
            })
          )
        }
      /> */}

        {/* Modal component for upload category. */}
        {/* <AddBulkCategoryModal
        isOpen={categoryUploadModal}
        setModalOpenState={setCategoryUploadModal}
        handleSuccess={(res) => {
          setBulkUploadResultData(res.data);
          setCategoryUploadResultModal(true);
          setCategoryUploadModal(false);
           dispatch(
            fetchCategoriesWithFilter({
              organisationId,
              searchName: debounceValue,
              page: page,
              pageSize: pageSize,
              sort: sortData,
            })
          );
        }}
      /> */}

        {/* Modal component for upload category result. */}
        {/* <CategoryBulkUploadResult
        isOpen={categoryUploadResultModal}
        setModalOpenState={setCategoryUploadResultModal}
        data={bulkUploadResultData}
      /> */}

        {/* Modal component for delete category premission. */}
        {/* <DeletePermissionModal
        yesCallback={() => {
          handleDeleteCategory();
        }}
        title={t.categories.add}
      >
        <span>{t.categories.delete_category_statement}</span>
      </DeletePermissionModal> */}

        {/* Modal component for delete category result. */}
        {/* <DeleteResultModal
        closeCallback={() => {
          setDeleteInfoModal(false);
        }}
        deleteErrorMessage={'delete'}
        btnName={t.common.ok}
      >
        <div className="mb-2">
          <Close />
        </div>
        <p
          style={{
            paddingInline: '62px',
            fontSize: '16px',
            fontWeight: '400',
          }}
          className="status-modal-popup-message mt-5"
        >
          {t.categories.cannot_delete_category_statement}
        </p>
      </DeleteResultModal> */}
      </div>
    </>
  );
};
