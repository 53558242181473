import React, { useEffect, useState, createRef, useRef } from "react";
import {
  Header,
  Button,
  Table,
  Image,
  Placeholder,
  Icon,
  Label,
  Loader,
} from "semantic-ui-react";
import * as auth from "../../modules/Auth/_redux/authRedux";

import Tooltip from "react-power-tooltip";
import { useDebounce } from "use-debounce";

import md5 from "md5";

import Moment from "react-moment";

import moment from "moment";

import { NavLink } from "react-router-dom";

import { useLocation } from "react-router";
import { toAbsoluteUrl, checkIsActive } from "../../../_metronic/_helpers";

import {
  getUserProfileByID,
  getUsersByRole,
  humanize,
  getUserData,
  IMAGES_URL,
} from "../../config/functions";

import Preview from "./preview/index";
import Add from "./add";
import Edit from "./edit";
import { useHistory } from "react-router";

import Filters from "./filters";
import { connect, useDispatch, useSelector } from "react-redux";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../components/common/userProfilePlaceholder";
import { selectTranslations } from "../../config/i18n/slice";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/CommonPagination";
import {
  ArrowLeft,
  ArrowRight,
  AssetsIconBlue,
  CloseIcon,
  PeopleIcon,
  PeopleIconBlue,
  ProcedurePlusIcon,
  SortIcon,
} from "../../../Assets/Icons/svg";
// import ReactTooltip from 'react-tooltip';
import useGetCurrentTimeAccZone from "../../../hooks/useGetCurrentTimeAccZone";
import DateConvert from "../../../utilFunctions/DateFormat";
import {
  fetchAllPeopleData,
  fetchPeopleInitData,
  fetchPeopleWithFilter,
} from "../../../redux/reducers/peopleReducer";
import EmptyFilter from "./emptyFilter";
import Animation from "../../components/common/CustomLoader";

const customStyles = {
  // rows: {
  //   style: {
  //     height: '72px',
  //     // override the row height
  //   },
  // },
  headCells: {
    style: {
      paddingTop: "11px", // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: "16px", // override the cell padding for data cells
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};

const People = (props) => {
  const { userPermissions } = usePermissionsSimplified();
  const [sortData, setSortData] = useState();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [loader, setloader] = useState(false);
  const t = useSelector(selectTranslations);
  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const peopleId = queryParams.get("id");
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const dispatch = useDispatch();
  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      location.subLocations.forEach((subLocation) => {
        if (!allIds.some((inv) => inv == subLocation?.id)) {
          allIds.push(subLocation?.id);
        }
      });
    });
  const history = useHistory();

  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,
      icon: "cog",
      description: t.people.add_modal.admin_description,
    },
    {
      key: 5,
      value: 4,
      text: t.people.add_modal.super_admin,
      icon: "cog",
      description: t.people.add_modal.admin_description,
    },

    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,
      icon: "wpforms",
      description: t.people.add_modal.user_description,
    },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,
      icon: "phone volume",
      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,
      icon: "sitemap",
      description: t.people.add_modal.viewer_description,
    },
    {
      key: 4,
      value: 6,
      text: t.people.add_modal.requester,
      icon: "cog",
      description: t.people.add_modal.requester_desc,
    },
  ];

  const [filterOptions, setFilterOptions] = useState({
    role: [],
    supervisors: [],
    jobTitle: [],
    teams: [],
    status: [],
    joiningDate: {
      value: "any_day",
      text: "Any Date",
    },
    rate: {
      from: null,
      to: null,
    },

    contractEnd: {
      from: null,
      to: null,
    },
  });

  const [loadUserData, setLoadUserData] = useState(false);

  const {
    data,
    allPeopleData,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.people.peopleData,
    allPeopleData: state.people.allPeopleData,
    isLoading: state.people.isLoading,
    error: state.people.error,
    initData: state.people.peopleInitData,
    page: state.people.page,
    pageSize: state.people.pageSize,
    total: state.people.total,
    initLoading: state.people.initLoading,
  }));

  // console.warn(t, 'hii');

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();

  const [searchTitle, setSearchTitle] = useState("");
  const [debounceValue] = useDebounce(searchTitle, 500);

  const [debounceRate] = useDebounce(filterOptions.rate, 500);

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(25);
  const [showTooltip, setShowTooltip] = useState(false);
  // const [data, setData] = useState([]);
  const lang = useSelector((state) => state.i18n.lang);
  const [dataTrigger, updateData] = useState(false);
  // const [initData, setInitData] = useState([]);

  const [targetedData, setTargetedData] = useState();
  const [targetedUserProfile, setTargetedUserProfile] = useState({});
  const [targetedUserProfileID, setTargetedUserProfileID] = useState(null);

  const [togglePreview, setTogglePreviw] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  useEffect(() => {
    setFetchLoading(true);

    const fetchData = async () => {
      setLoadUserData(true);
      if (!(roleData === "super_admin" || roleData === "admin")) {
        const userData = await getUserData(user.id);

        dispatch(props.fulfillUser(userData));
      }
      const fetchData = await dispatch(
        fetchPeopleInitData({
          role: [
            "user",
            "supervisor",
            "view_only",
            "admin",
            "requester",
            "limited_admin",
            "super_admin",
          ],
          organisationId,
          roleData,
          allIds,
        })
      );
      setLoadUserData(false);
      // console.log('FETCHY', fetchData);
      if (fetchData) {
        // setData(fetchData);
        // setInitData(fetchData);

        setFetchLoading(false);

        if (peopleId) {
          let fetchAllData = await dispatch(
            fetchAllPeopleData({
              role: [
                "user",
                "super_admin",
                "supervisor",
                "view_only",
                "admin",
                "requester",
                "limited_admin",
              ],
              organisationId,
              roleData,
              allIds,
            })
          );
          let selectedProcedure = fetchAllData.payload.response.find(
            (each) => each?.countId == peopleId
          );
          if (selectedProcedure) {
            setTargetedData(selectedProcedure);
            setTogglePreviw(true);
          }
        }
      }
      setFetchLoading(false);
    };

    fetchData();
  }, [peopleId]);

  const columns = [
    {
      width: selectedLanguage === "en" ? "106px" : "123px",
      name: t.assets.table.ID,
      sortField: "countNumId",
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.countId}
        </span>
      ),
    },
    {
      name: t.people.table.name,
      sortField: "firstName",
      sortable: true,
      minWidth: "180px",
      cell: (row) => {
        if (row?.profileImage?.url) {
          let role = roles.find((each) => each.value == row.role.id);

          // return <span>{role?.text || humanize(row.role.type)}</span>;
          return (
            <div
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              className="d-flex align-items-center"
            >
              <div className="text-center">
                <Image
                  avatar
                  title={row.firstName + " " + row.lastName}
                  src={`${IMAGES_URL}${row?.profileImage?.url}`}
                  style={
                    selectedLanguage === "en"
                      ? { width: "34px", height: "34px", right: "6px" }
                      : { width: "34px", height: "34px", right: "-6px" }
                  }

                  // style={
                  //   index != 0
                  //     ? {
                  //         marginLeft: '-6px',
                  //         width: '34px',
                  //         height: '34px',
                  //       }
                  //     : { width: '34px', height: '34px' }
                  // }
                />
              </div>
              <div
                className="username-container ml-1"
                // onClick={() => history.push(`/people?id=${row?.id}`)}
              >
                <p className="user-text">
                  {DisplayFormattedText(
                    humanize(row.firstName + " " + row.lastName),
                    8
                  )}
                </p>
                <p
                  className={`user-role ${
                    selectedLanguage == "en"
                      ? "text-left mr-2"
                      : "text-right ml-2"
                  } `}
                >
                  {row?.role?.name
                    ? DisplayFormattedText(role?.text, 8) ||
                      humanize(row.role.type)
                    : ""}
                </p>
              </div>
            </div>
          );
        } else {
          let role = roles.find((each) => each.value == row.role.id);

          return (
            <div
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              className="d-flex align-items-center"
            >
              <div className="text-center">
                <UserProfilePlaceholder
                  name={row.firstName + " " + row.lastName}
                  width={"34px"}
                  height={"34px"}
                  right={selectedLanguage === "en" ? "6px" : "-6px"}
                />
              </div>
              <div
                className="username-container ml-1"
                // onClick={() => history.push(`/people?id=${row?.id}`)}
              >
                <p className="user-text">
                  {DisplayFormattedText(
                    humanize(row.firstName + " " + row.lastName),
                    8
                  )}
                </p>
                <p
                  className={`user-role ${
                    selectedLanguage == "en"
                      ? "text-left mr-2"
                      : "text-right ml-2"
                  } `}
                >
                  {row?.role?.name
                    ? DisplayFormattedText(role?.text, 8) ||
                      humanize(row.role.type)
                    : ""}
                </p>
              </div>
            </div>
          );
        }
      },
    },

    {
      minWidth: "160px",
      name: t.customers.table.phone,
      sortField: "userProfile.phone",

      sortable: true,
      cell: (row) =>
        row.userProfile?.phone
          ? `${selectedLanguage == "en" ? "+" : ""}${row.userProfile?.phone}${
              selectedLanguage == "ar" ? "+" : ""
            }`
          : t.common.na,
    },
    {
      width: selectedLanguage === "en" ? "160px" : "170px",
      name: t.jobTitle.add,
      sortField: "userProfile.job.title",
      sortable: true,
      cell: (row) =>
        row?.userProfile?.job ? (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            {DisplayFormattedText(row.userProfile?.job.title, 12)}
          </span>
        ) : (
          t.common.na
        ),
    },

    {
      width: selectedLanguage === "en" ? "150px" : "165px",
      name: t.people.table.role,
      sortField: "role.name",
      // selector: (row) => row?.role.id,
      sortable: true,
      cell: (row) => {
        let role = roles.find((each) => each.value == row.role.id);

        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            {role?.text || humanize(row.role.type)}
          </span>
        );
      },
      // row?.email ? row.email.substring(0, 5) + '...' : t.common.na,
    },

    {
      name: t.people.table.teams,
      selector: (row) => (row?.teams ? row?.teams.length : 0),

      cell: (row) => {
        let assigneePeople = row?.teams;
        let totalLength = row?.teams ? row?.teams.length : 0;

        if (assigneePeople?.length) {
          if (assigneePeople?.length < 3) {
            assigneePeople = assigneePeople.map((people, index) => {
              {
                return (
                  <UserProfilePlaceholder
                    name={`${people?.name ? people?.name : ""}`}
                    width={"34px"}
                    height={"34px"}
                    right={index !== 0 ? "7px" : ""}
                    className={index == 0 ? "img-first" : ""}
                  />
                );
              }
            });
          } else {
            assigneePeople = assigneePeople.slice(0, 2).map((people, index) => {
              {
                return (
                  <UserProfilePlaceholder
                    name={`${people?.name ? people?.name : ""} `}
                    width={"34px"}
                    height={"34px"}
                    right={
                      selectedLanguage === "ar" && index !== 0
                        ? "0px"
                        : index === 0 && selectedLanguage === "en"
                        ? "-15px"
                        : "-9px"
                    }
                  />
                );
              }
            });
            assigneePeople.push(
              <Image
                avatar
                title={`${totalLength - 2}`}
                src={`https://ui-avatars.com/api/?name=${totalLength -
                  2}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                style={{
                  width: "34px",
                  height: "34px",
                  right: "-3px",
                }}
              />
            );
          }
          return (
            <div
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              className={`multi-profile-container ${
                lang == "en" ? "" : "rtl-body"
              }`}
            >
              {assigneePeople}
            </div>
          );
        } else {
          return t.common.na;
        }
      },
    },

    {
      name: t.people.table.supervisor,
      minWidth: "150px",
      // selector: (row) =>
      //   row?.supervisor?.firstName ? row?.supervisor?.firstName : '',
      sortable: true,
      sortField: "supervisor.firstName",
      cell: (row) => {
        if (row.supervisor) {
          return (
            <>
              {row.supervisor?.profileImage?.url ? (
                <>
                  <div className="d-flex ">
                    <Image
                      right={selectedLanguage === "en" ? "6px" : "-6px"}
                      avatar
                      width={"34px"}
                      height={"34px"}
                      title={
                        row.supervisor?.firstName +
                        " " +
                        row.supervisor?.lastName
                      }
                      src={IMAGES_URL + row.supervisor?.profileImage?.url}
                      onClick={() => {
                        window.open(
                          IMAGES_URL + row.supervisor?.profileImage?.url,
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </>
              ) : (
                <span
                  onClick={() => {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }}
                >
                  <UserProfilePlaceholder
                    name={
                      row.supervisor?.firstName + " " + row.supervisor?.lastName
                    }
                    width={"34px"}
                    height={"34px"}
                    right={selectedLanguage === "en" ? "6px" : "-6px"}
                  />
                </span>
              )}
            </>
          );
        } else {
          return t.common.na;
        }
      },
    },

    {
      minWidth: selectedLanguage === "en" ? "120px" : "192px",
      name: t.people.table.hourly_rate,
      // selector: (row) => row?.userProfile?.hourRate,
      sortField: "userProfile.hourRate",
      sortable: true,

      cell: (row) =>
        row?.userProfile?.hourRate
          ? `${row.userProfile?.hourRate} ${organisationCurrency}`
          : t.common.na,
      // row?.email ? row.email.substring(0, 5) + '...' : t.common.na,
    },
    {
      minWidth: "120px",
      name: t.people.table.status,
      // selector: (row) => row?.confirmed && !row.blocked,
      sortField: "confirmed",
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className={`pill ${
            row?.confirmed && !row.blocked
              ? "active-green"
              : row.blocked
              ? "inactive-grey"
              : "pending-yellow"
          }`}
        >
          {row?.confirmed && !row.blocked
            ? t.assets.active
            : row.blocked
            ? t.assets.inActive
            : t.people.status_options.status_pending}
        </span>
      ),
      // row?.email ? row.email.substring(0, 5) + '...' : t.common.na,
    },

    {
      name: t.people.table.contract_expiry_date,
      sortField: "userProfile.contractEnd",
      minWidth: "230px",
      selector: (row) => {
        const contractEnd = row.userProfile?.contractEnd || ""; // Ensure contractEnd is not undefined
        if (contractEnd === "") {
          // For empty contractEnd, return a value that comes after non-empty values
          return "\uffff"; // A character that comes after letters and numbers in Unicode
        } else {
          // For non-empty contractEnd, return the actual date value
          return contractEnd;
        }
      },
      sortable: true,
      cell: (row) => {
        if (row.userProfile?.contractEnd) {
          return (
            <div
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
            >
              <div>
                <Moment
                  format="DD-MM-YYYY"
                  date={row.userProfile?.contractEnd}
                />
              </div>
              <div className="time-text-style text-left">
                <Moment format="h a" date={row.userProfile?.contractEnd} />
              </div>
            </div>
          );
        } else {
          return t.common.na;
        }
      },
      // row?.email ? row.email.substring(0, 5) + '...' : t.common.na,
    },
  ];

  const handleSort = async (column, sortDirection) => {
    setloader(true);

    // Assuming the selector represents the field to sort
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchPeopleWithFilter({
        role: [
          "user",
          "supervisor",
          "view_only",
          "admin",
          "requester",
          "limited_admin",
          "super_admin",
        ],
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        role_type: filterOptions.role,
        supervisor: filterOptions.supervisors,
        team: filterOptions.teams,
        status: filterOptions.status,
        job: filterOptions.jobTitle,
        rate: filterOptions.rate,
        sort,
        roleData,
        allIds,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchPeopleWithFilter({
        role: [
          "user",
          "supervisor",
          "view_only",
          "admin",
          "super_admin",
          "requester",
          "limited_admin",
        ],
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        role_type: filterOptions.role,
        supervisor: filterOptions.supervisors,
        team: filterOptions.teams,
        status: filterOptions.status,
        job: filterOptions.jobTitle,
        rate: filterOptions.rate,
        sort,
        roleData,
        allIds,
      })
    );

    setloader(false);
  };

  const rowStyle = { cursor: "pointer" };

  const editModalHandler = async (userProfile) => {
    // console.log("userProfile", userProfile);
    const response = await getUserProfileByID(userProfile);
    // console.log("response", response);

    if (response.status == 200) {
      const mapped_response = response.data.data.attributes;

      setTargetedUserProfile(mapped_response);
      setTargetedUserProfileID(userProfile);
      setTogglePreviw(false);
      setToggleEditModal(true);
    }
  };

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const statusOptions = [
    {
      value: "pending",
      color: "yellow",
    },
    {
      value: "confirmed",
      color: "green",
    },
    {
      value: "declined",
      color: "red",
    },
  ];
  const totalFilteredPages = Math.ceil(data.length / pageSize);

  return (
    <>
      <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {" "}
            <PeopleIcon />
            {t.aside.peopleTeams}
          </Header>
          <div></div>

          {userPermissions?.people?.create && initData.length > 0 ? (
            <Button
              icon="add"
              primary
              className={selectedLanguage == "en" ? "mr-7" : "ml-7"}
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.people.add_person}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
        {initData?.length > 0 ? (
          <div className="new-listing-filter wo-filter sticky-filter mt-8">
            <Filters
              roleData={roleData}
              allIds={allIds}
              setloader={setloader}
              setSortData={setSortData}
              sortData={sortData}
              loader={loader}
              debounceRate={debounceRate}
              data={initData}
              fileData={data}
              setDataByFilter={handleSetDataByFilter}
              hasStatus={false}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              debounceValue={debounceValue}
              setSearchTitle={setSearchTitle}
              searchTitle={searchTitle}
            />
          </div>
        ) : null}
      </div>
      <div className="new-listing-container">
        {initLoading || loadUserData ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            <Animation />
            {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
          </div>
        ) : (
          // <div>
          //   <Placeholder fluid>
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //   </Placeholder>
          // </div>
          <div className="new-listing-filter">
            {initData.length > 0 ? (
              <>
                {error ? (
                  <div className="mt-8 mb-8 ">
                    <Animation />
                  </div>
                ) : (
                  <DataTable
                    className="datatable-div"
                    columns={columns}
                    fixedHeader
                    onSort={handleSort}
                    data={data}
                    customStyles={customStyles}
                    noDataComponent={
                      initData.length > 0 ? (
                        <div className="no-data-container">
                          <div className="">
                            <p className="no-data-text">
                              {t.common.no_result_found}
                            </p>
                          </div>
                        </div>
                      ) : null
                    }
                    sortIcon={
                      sortData ? (
                        <div>
                          <span>
                            <SortIcon />
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSorting();
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )
                    }
                    direction={selectedLanguage === "en" ? "ltr" : "rtl"}
                    onRowClicked={(row) => {
                      setTargetedData(row);
                      setTogglePreviw(true);
                    }}
                    persistTableHead={true}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    progressPending={loader}
                    progressComponent={
                      <div className="mt-8 mb-8 ">{t.common.loading}</div>
                    }
                  />
                )}

                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={total}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={page}
                    limit={pageSize}
                    handleNext={async () => {
                      setloader(true);
                      await dispatch(
                        fetchPeopleWithFilter({
                          role: [
                            "user",
                            "supervisor",
                            "super_admin",
                            "view_only",
                            "admin",
                            "requester",
                            "limited_admin",
                          ],
                          organisationId,
                          searchName: debounceValue,
                          page: page + 1,
                          pageSize: pageSize,
                          role_type: filterOptions.role,
                          supervisor: filterOptions.supervisors,
                          team: filterOptions.teams,
                          status: filterOptions.status,
                          job: filterOptions.jobTitle,
                          rate: filterOptions.rate,
                          sort: sortData,
                          roleData,
                          allIds,
                        })
                      );
                      setloader(false);
                    }}
                    handlePrevious={async () => {
                      setloader(true);
                      await dispatch(
                        fetchPeopleWithFilter({
                          role: [
                            "user",
                            "supervisor",
                            "super_admin",
                            "view_only",
                            "admin",
                            "requester",
                            "limited_admin",
                          ],
                          organisationId,
                          searchName: debounceValue,
                          page: page - 1,
                          pageSize: pageSize,
                          role_type: filterOptions.role,
                          supervisor: filterOptions.supervisors,
                          team: filterOptions.teams,
                          status: filterOptions.status,
                          job: filterOptions.jobTitle,
                          rate: filterOptions.rate,
                          sort: sortData,
                          roleData,
                          allIds,
                        })
                      );
                      setloader(false);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="">
                <EmptyFilter />
                {/* <Filters
               setSortData={setSortData}
               sortData={sortData}
              debounceRate={debounceRate}
                data={initData}
                fileData={data}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
                debounceValue={debounceValue}
                setSearchTitle={setSearchTitle}
                searchTitle={searchTitle}
                setloader={setloader}
                loader={loader}
              /> */}

                <div className="no-data-container">
                  <div className="no-work-orders pb-15">
                    <PeopleIconBlue />
                    <p className="no-data-text">
                      {t.common.there_no}{" "}
                      <span
                        className="link-text"
                        style={{ position: "relative", cursor: "pointer" }}
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        {/* <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth="58px"
                        arrowAlign="start"
                        fontSize="13px"
                        fontWeight="400"
                        fontFamily="Roboto"
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight="65px"
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.people.how_to_add_people}
                        </span>
                      </Tooltip> */}

                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="top left"
                          show={showTooltip}
                          textBoxWidth={
                            selectedLanguage == "ar" ? "80px" : "60px"
                          }
                          arrowAlign="start"
                          fontSize="13px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="0px 0px 0px 8px"
                          borderRadius="5px"
                          static
                          moveRight={selectedLanguage == "ar" ? "52px" : "65px"}
                          moveUp="-1px"
                        >
                          <span className="work-order-tooltip">
                            {t.people.how_to_add_people}
                          </span>
                        </Tooltip>
                        {" " + t.people.small_title + " "}
                      </span>{" "}
                      {t.common.added}
                    </p>
                    {userPermissions?.people?.create ? (
                      <button
                        className="work-order-button-transparent"
                        onClick={() => {
                          setToggleAsideModal(true);
                        }}
                      >
                        {t.people.add_people}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <Add
          toggled={toggleAsideModal}
          roleData={roleData}
          allIds={allIds}
          untoggle={() => {
            setToggleAsideModal(false);
            updateData(true);
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            setRefresh(!refresh);
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          debounceValue={debounceValue}
          setSearchTitle={setSearchTitle}
          setSortData={setSortData}
        />

        <Edit
          toggled={toggleEditModal}
          roleData={roleData}
          allIds={allIds}
          targetedData={targetedData}
          targetedUserProfile={targetedUserProfile}
          targetedUserProfileID={targetedUserProfileID}
          untoggle={() => {
            setTargetedUserProfile();
            setTargetedData();
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            setToggleEditModal(false);
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          debounceValue={debounceValue}
          setSearchTitle={setSearchTitle}
          setSortData={setSortData}
        />

        <Preview
          targetedData={targetedData}
          allIds={allIds}
          roleData={roleData}
          toggled={togglePreview}
          setModalWidth={setModalWidth}
          modalWidth={modalWidth}
          edit={editModalHandler}
          fileData={data}
          untoggle={() => {
            setTargetedData();
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            setTogglePreviw(false);

            // history.push('/people');
            // fetchData();
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          setFilterOptions={setFilterOptions}
          setTogglePreviw={setTogglePreviw}
          filterOptions={filterOptions}
          debounceValue={debounceValue}
          setSearchTitle={setSearchTitle}
          setSortData={setSortData}
        />
      </div>
    </>
  );
};

export default connect(null, auth.actions)(People);
