import React, { useEffect, useState } from "react";
import {
  Dimmer,
  Loader,
  List,
  Label,
  Grid,
  Image,
  Divider,
} from "semantic-ui-react";

import md5 from "md5";

import {
  humanize,
  getUserProfileByID,
  IMAGES_URL,
  getAllParentLocations,
} from "../../../config/functions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";

import moment from "moment";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../../components/common/userProfilePlaceholder";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import {
  LinkBlueIcon,
  LocationBlueNoBackground,
  LocationDropDownIcon,
  LocationDropDownIconBlue,
  SubLocationDropDownBlueIcon,
} from "../../../../Assets/Icons/svg";
import Moment from "react-moment";

function Work({
  targetedData,
  supervisorData,
  attachmentData,
  createdByUserData,
  updatedUserData,
}) {
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });
  const [fetchLoading, setFetchLoading] = useState(false);
  const [data, setData] = useState({
    profile: {},
    attendence: {},
  });

  const [locOption, setLocOption] = useState([]);

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  // const roles = [
  //   {
  //     key: 0,
  //     value: 5,
  //     text: t.people.add_modal.admin,
  //     icon: 'cog',
  //     description: t.people.add_modal.admin_description,
  //   },

  //   {
  //     key: 1,
  //     value: 8,
  //     text: t.people.add_modal.technician,
  //     icon: 'wpforms',
  //     description: t.people.add_modal.user_description,
  //   },
  //   {
  //     key: 2,
  //     value: 9,
  //     text: t.people.add_modal.limited_admin,
  //     icon: 'phone volume',
  //     description: '',
  //   },
  //   {
  //     key: 3,
  //     value: 3,
  //     text: t.people.add_modal.viewer,
  //     icon: 'sitemap',
  //     description: '',
  //   },
  // ];

  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,

      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,

      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,

      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,

      description: t.people.add_modal.viewer_description,
    },
    {
      key: 2,
      value: 6,
      text: t.people.add_modal.requester,
      description: t.people.add_modal.requester_desc,
    },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ];

  let role = roles.find((each) => each.value == targetedData?.role?.id);
  const shiftOptions = [
    {
      key: "shiftA",
      value: "shiftA",
      text: t.people.work_info_form.shift_A,
    },
    {
      key: "shiftB",
      value: "shiftB",
      text: t.people.work_info_form.shift_B,
    },
    {
      key: "shiftC",
      value: "shiftC",
      text: t.people.work_info_form.shift_C,
    },
  ];

  const DayOptions = [
    {
      key: "sun",
      value: "sun",
      text: t.people.work_info_form.working_day_options.sun,
    },
    {
      key: "mon",
      value: "mon",
      text: t.people.work_info_form.working_day_options.mon,
    },
    {
      key: "tue",
      value: "tue",
      text: t.people.work_info_form.working_day_options.tue,
    },
    {
      key: "wed",
      value: "wed",
      text: t.people.work_info_form.working_day_options.wed,
    },
    {
      key: "thu",
      value: "thu",
      text: t.people.work_info_form.working_day_options.thu,
    },
    {
      key: "fri",
      value: "fri",
      text: t.people.work_info_form.working_day_options.fri,
    },
    {
      key: "sat",
      value: "sat",
      text: t.people.work_info_form.working_day_options.sat,
    },
  ];
  useEffect(() => {
    setFetchLoading(true);

    const fetchLocOption = async () => {
      if (targetedData?.locations && targetedData?.locations?.length) {
        const fetchData = await getAllParentLocations(
          organisationId,
          "",
          1,
          99999,
          [],
          [],
          [],
          ["name:asc"]
        );
        let the_options = [];
        let parentOptions = [];
        let subOptions = [];

        const allIds = targetedData?.locations
          ? targetedData.locations.map((each) => each?.id)
          : [];

        if (fetchData?.response?.length > 0 && allIds.length > 0) {
          fetchData.response.forEach((el) => {
            const subLocationCount =
              el?.attributes?.subLocations?.data?.filter((subEl) =>
                allIds.includes(subEl.id)
              ).length || 0;
            // Check if the element's ID is included in allIds
            if (allIds?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                countId: el.attributes.countId,
                subLocationCount: subLocationCount,
                // image: { src: location_icon },
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      countId: subEl.attributes.countId,
                      // image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => allIds?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  subLocationCount,
                  text: el.attributes.name,
                  countId: el.attributes.countId,
                  // image: { src: location_icon },
                  disabled: !(
                    roleData === "super_admin" || roleData === "admin"
                  )
                    ? true
                    : false, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (allIds?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        countId: subEl.attributes.countId,
                        // image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }

            // else {
            //   // Add parent option
            //   parentOptions.push({
            //     key: el.id,
            //     value: el.id,
            //     text: el.attributes.name,
            //     image: { src: location_icon },
            //   });

            //   // Collect sub-options
            //   if (el?.attributes?.subLocations?.data?.length > 0) {
            //     el.attributes.subLocations.data.forEach((subEl) => {
            //       subOptions.push({
            //         key: subEl.id,
            //         value: subEl.id,
            //         text: subEl.attributes.name,
            //         image: { src: sub_location_icon },
            //         className: "sub-location-dropdown-option",
            //         parentId: el.id, // Keep track of the parent
            //       });
            //     });
            //   }
            // }
          });

          // Sort sub-options alphabetically
          subOptions.sort((a, b) => a.text.localeCompare(b.text));

          // Merge parent options with sorted sub-options
          parentOptions.forEach((parentOption) => {
            the_options.push(parentOption);
            // Add sorted sub-options belonging to this parent
            subOptions.forEach((subOption) => {
              if (subOption.parentId === parentOption.key) {
                the_options.push(subOption);
              }
            });
          });

          // Update the options state
          setLocOption(the_options);

          return the_options;
        }
      }
    };

    const fetchData = async () => {
      if (targetedData.userProfile) {
        const fetchData = await getUserProfileByID(targetedData.userProfile.id);
        if (fetchData) {
          setData({
            ...data,
            profile: fetchData.data.data.attributes,
          });
          setFetchLoading(false);
        }
      } else {
        setFetchLoading(false);
      }
    };

    const loadData = async () => {
      await fetchLocOption(); // Wait for fetchLocOption to complete
      await fetchData(); // Then call fetchData
      setFetchLoading(false); // Set loading state to false after both calls
    };
    loadData();
  }, [targetedData]);

  const shifts = data.profile?.shifts;
  let formattedShifts;
  if (shifts) {
    const matchShift = Object?.keys(shifts).filter(
      (key) => shifts[key] === true
    );

    const selectedShiftNames = matchShift?.map((selectedShift) => {
      const correspondingOption = shiftOptions?.find(
        (option) => option?.value === selectedShift
      );
      return correspondingOption ? correspondingOption?.text : null;
    });

    formattedShifts = selectedShiftNames?.filter(Boolean).join(", ");
  } else {
    formattedShifts = null;
  }

  const workingDay = data.profile?.days;
  let formattedDays;

  if (workingDay) {
    const matchDay = Object?.keys(workingDay).filter(
      (key) => workingDay[key] === true
    );

    const selectedDayNames = matchDay?.map((selectedShift) => {
      const correspondingOption = DayOptions?.find(
        (option) => option?.value === selectedShift
      );
      return correspondingOption ? correspondingOption?.text : null;
    });

    formattedDays = selectedDayNames?.filter(Boolean).join(", ");
  } else {
    formattedDays = null;
  }

  let this_shifts = [];

  if (data.profile?.shifts?.shiftA) {
    this_shifts.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"blue"}
        content={"A"}
      />
    );
  }

  if (data.profile?.shifts?.shiftB) {
    this_shifts.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"orange"}
        content={"B"}
      />
    );
  }

  if (data.profile?.shifts?.shiftC) {
    this_shifts.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"green"}
        content={"C"}
      />
    );
  }

  let this_days = [];
  if (data.profile?.days?.sat) {
    this_days.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"green"}
        content={t.workOrders.details_view.sat}
      />
    );
  }

  if (data.profile?.days?.sun) {
    this_days.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"green"}
        content={t.workOrders.details_view.sun}
      />
    );
  }

  if (data.profile?.days?.mon) {
    this_days.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"green"}
        content={t.workOrders.details_view.mon}
      />
    );
  }

  if (data.profile?.days?.thu) {
    this_days.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"green"}
        content={t.workOrders.details_view.thu}
      />
    );
  }

  if (data.profile?.days?.wed) {
    this_days.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"green"}
        content={t.workOrders.details_view.wed}
      />
    );
  }

  if (data.profile?.days?.tue) {
    this_days.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"green"}
        content={t.workOrders.details_view.tue}
      />
    );
  }

  if (data.profile?.days?.fri) {
    this_days.push(
      <Label
        style={{ height: "auto", borderRadius: 5 }}
        size="medium"
        color={"green"}
        content={t.workOrders.details_view.fri}
      />
    );
  }
  return (
    <div className="preview-details-wrapper">
      {/* {console.log(targetedData, 'TargettedData')} */}
      {fetchLoading ? (
        <Loader active inline="centered">
          {t.common.loading}
        </Loader>
      ) : (
        <>
          <div className="intro">
            <div className="flexbox align-center" style={{ margin: "20px 0" }}>
              <div>
                {targetedData?.profileImage?.url ? (
                  <div className="d-flex align-items-start">
                    <div className="text-center">
                      <Image
                        circular
                        title={
                          targetedData.firstName + " " + targetedData.lastName
                        }
                        src={`${IMAGES_URL}${targetedData?.profileImage?.url}`}
                        style={
                          selectedLanguage === "en"
                            ? { width: "80px", height: "80px", right: "6px" }
                            : { width: "80px", height: "80px", right: "-6px" }
                        }
                        // style={
                        //   index != 0
                        //     ? {
                        //         marginLeft: '-6px',
                        //         width: '34px',
                        //         height: '34px',
                        //       }
                        //     : { width: '34px', height: '34px' }
                        // }
                      />
                    </div>
                    <div
                      className="username-container ml-2"
                      // onClick={() => history.push(`/people?id=${row?.id}`)}
                    >
                      <p className="people-text">
                        {humanize(
                          targetedData.firstName + " " + targetedData.lastName
                        )}
                      </p>
                      <p className="people-role text-left">
                        {targetedData?.role?.name
                          ? role?.text || humanize(targetedData?.role?.name)
                          : ""}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-start">
                    <div className="text-center">
                      <UserProfilePlaceholder
                        name={
                          targetedData.firstName + " " + targetedData.lastName
                        }
                        width={"80px"}
                        height={"80px"}
                        right={selectedLanguage === "en" ? "6px" : "-6px"}
                      />
                    </div>
                    <div
                      className="username-container ml-1"
                      // onClick={() => history.push(`/people?id=${row?.id}`)}
                    >
                      <p className="people-text text-left">
                        {humanize(
                          targetedData.firstName + " " + targetedData.lastName
                        )}
                      </p>
                      <p
                        className={`people-role ${
                          selectedLanguage == "en"
                            ? "text-left mr-2"
                            : "text-right ml-0"
                        }`}
                      >
                        {targetedData?.role?.name
                          ? role?.text || humanize(targetedData?.role?.name)
                          : ""}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="d-flex align-items-start mt-5 mb-7">
              <label className="workorder-intro-label people-label">
                {t.common.created_by}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {createdByUserData?.firstName
                  ? createdByUserData?.firstName +
                    ' ' +
                    createdByUserData?.lastName
                  : t.common.na}
              </p>
            </div> */}
          </div>

          <Divider />

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.personal_info_form.email}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {targetedData?.email ? targetedData.email : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.personal_info_form.phone}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile.phone
                ? `${selectedLanguage == "en" ? "+" : ""}${data.profile.phone}${
                    selectedLanguage == "ar" ? "+" : ""
                  }`
                : t.common.na}
            </p>
          </div>

          {/* <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label people-label">
              {t.people.personal_info_form.email}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.email ? targetedData.email : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label people-label">
              {t.people.personal_info_form.phone}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {data.profile.phone ? `+${data.profile.phone}` : t.common.na}
            </p>
          </div> */}

          <div className="d-flex align-items-start mt-5  ">
            <label className="label-item">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.job_title}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mb-1">
              {data?.profile?.job?.data
                ? data.profile?.job?.data?.attributes?.title
                : t.common.na}
            </p>
          </div>
          {targetedData?.role?.id == 8 || targetedData?.role?.id == 9 ? (
            <div className="d-flex align-items-start mt-5">
              <label className="label-item ">
                {""}
                <label className="workorder-intro-label people-label">
                  {t.people.work_info_form.supervisor}
                  {":"}
                </label>
              </label>

              <p className="workorder-intro-value">
                {targetedData.supervisor ? (
                  <div>
                    <Image
                      avatar
                      title={
                        targetedData.supervisor.firstName +
                        " " +
                        targetedData.supervisor.lastName
                      }
                      src={
                        supervisorData?.profileImage?.url
                          ? IMAGES_URL + supervisorData?.profileImage?.url
                          : LetteredAvatarImageUrl(
                              targetedData.supervisor.firstName +
                                " " +
                                targetedData.supervisor.lastName
                            )
                      }
                      style={
                        selectedLanguage == "en"
                          ? { width: "34px", height: "34px", right: "6px" }
                          : { width: "34px", height: "34px", left: "6px" }
                      }
                    />
                    <span>{targetedData.supervisor.firstName}</span>
                  </div>
                ) : (
                  t.common.na
                )}
              </p>
            </div>
          ) : null}

          <div className="d-flex align-items-start mt-5">
            <label className="label-item ">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.teams}
                {":"}
              </label>
            </label>

            <p
              style={
                selectedLanguage == "en"
                  ? { marginLeft: "12px" }
                  : { marginRight: "12px" }
              }
              className="workorder-intro-value"
            >
              {targetedData?.teams.length > 0
                ? targetedData?.teams?.map((el) => (
                    <List.Item className="d-flex mb-2">
                      <div className="d-flex align-items-start">
                        <Image
                          avatar
                          title={el?.name}
                          src={LetteredAvatarImageUrl(el?.name)}
                          height="34px"
                          width="34px"
                        />
                      </div>

                      <List.Header className="mt-1 ml-2 mr-2">
                        {el.name}
                      </List.Header>
                    </List.Item>
                  ))
                : t.common.na}
            </p>
          </div>

          <div className="d-flex aalign-items-start mt-5">
            <label className="label-item ">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.locations}
                {":"}
              </label>
            </label>

            <p
              style={{ gap: "10px" }}
              className="workorder-intro-value d-flex text-left flex-column"
            >
              {locOption?.length > 0
                ? locOption.map((each) => {
                    return (
                      <span
                        className={`${
                          selectedLanguage == "en" ? "text-left" : "text-right"
                        } `}
                        basic
                        onClick={() => {
                          if (!each?.disabled) {
                            history.push(`/locations?id=${each?.countId}`);
                          }
                        }}
                      >
                        <span className={each?.parentId ? selectedLanguage == "en" ? "ml-5" : "mr-5" : ""}>
                          {each?.parentId ? (
                            <SubLocationDropDownBlueIcon />
                          ) : each?.disabled ? (
                            <LocationDropDownIcon />
                          ) : (
                            <LocationDropDownIconBlue />
                          )}
                        </span>
                        <span
                          className={`${
                            selectedLanguage == "en" ? "ml-2" : "mr-2"
                          } ${each?.disabled ? "" : "work-order-link"}`}
                        >
                          {each?.text}
                        </span>{" "}
                        <span
                          style={{ color: "#0C66E4" }}
                          className="ml-2 mr-2 link-span"
                        >
                          {each?.subLocationCount &&
                          each?.subLocationCount > 0 ? (
                            <LinkBlueIcon />
                          ) : null}
                          <span
                            style={{ textDecoration: "none" }}
                            className="ml-1"
                          >
                            {each?.subLocationCount &&
                            each?.subLocationCount > 1
                              ? each?.subLocationCount
                              : ""}
                          </span>
                        </span>
                      </span>
                    );
                  })
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.department}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data?.profile?.department
                ? data.profile?.department
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.hour_rate}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data.profile?.hourRate
                ? `${data.profile.hourRate} ${organisationCurrency}`
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.working_hours_day}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data.profile?.whpd ? data.profile.whpd : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.working_hours_week}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data.profile?.whpw ? data.profile.whpw : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.contract_start_date}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data.profile?.contractStart
                ? moment(data.profile?.contractStart).format("DD.MM.YYYY")
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.contract_expiry_date}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data.profile?.contractEnd
                ? moment(data.profile?.contractEnd).format("DD.MM.YYYY")
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.registration_no}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data.profile?.registrationNo
                ? data.profile?.registrationNo
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.badge_ID}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data.profile?.badgeId ? data.profile?.badgeId : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label ">
                {t.people.work_info_form.working_days}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {formattedDays ? formattedDays : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.shifts}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {formattedShifts ? formattedShifts : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start">
            <label className="label-item mt-5">
              {""}
              <label className="workorder-intro-label people-label">
                {t.people.work_info_form.work_permit_no}
                {":"}
              </label>
            </label>
            <p className="workorder-intro-value mt-5">
              {data.profile?.workPermitNo
                ? data.profile?.workPermitNo
                : t.common.na}
            </p>
          </div>
          <Divider />

          <div className="d-flex align-items-start mt-5">
            <label
              className="workorder-intro-label people-label grey"
              style={{ minWidth: "77px" }}
            >
              {t.workOrders.table.createdByUser}
              {":"}
            </label>
            <p className="workorder-intro-value grey">
              {createdByUserData
                ? createdByUserData?.firstName +
                  " " +
                  createdByUserData?.lastName +
                  ", " +
                  moment(data?.profile?.createdAt).format("DD-MM-YYYY LT")
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start mt-3">
            <label className="workorder-intro-label people-label grey">
              {t.common.update_by}
              {":"}
            </label>
            <p className="workorder-intro-value grey">
              {updatedUserData
                ? updatedUserData?.firstName +
                  " " +
                  updatedUserData?.lastName +
                  ", " +
                  moment(data?.profile?.updatedAt).format("DD-MM-YYYY LT")
                : t.common.na}

              {/* {updatedUserData?.firstName && updatedUserData?.lastName
                ? updatedUserData?.firstName + ' ' + updatedUserData?.lastName
                : t.common.na}
              {', '} */}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default Work;
